.header {
    position: sticky;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    padding:5px 0 15px 0;
    box-sizing: border-box;
    transition: all 0.3s ease;
    background: #f3f3f3;

    &__footer {
        display: flex;
        justify-content: space-between;
    }

    &-left {
        position: relative;
        z-index: 2;
    }

    &-right {
        position: relative;
        z-index: 2;
        display: flex;

        .global-search {
            margin-left: 1.5rem;
            width: 19.2rem;

            .form-control-sm {
                width: 100%;
            }
        }
    }

    &-rules {
        line-height: 2.3rem;
        vertical-align: middle;
        white-space: nowrap;
        display: flex;
        align-items: center;
        .btn.btn-sm {
            position: relative;
            height: 30px;

            span {
                position: absolute;
                top: 0;
                right: 4px;
                min-width: 17px;
                line-height: 17px;
                border-radius: 17px;
                padding: 0 2px;
                background: #ea3c3c;
                color: #fff;
            }

            [class*=" fa-"] {
                color: #449aff;
                font-size: 18px;

                &.color-white {
                    color: #fff;
                }
            }
        }
    }
}
.global-search-wrapper {
    display: flex;
    justify-content: flex-end;
}
.sticky-custom-header {
    position: sticky;
    top: 64px;
    background: #ffffff;
    z-index: 2;
}

.filter-item {
    display: inline-block;
    vertical-align: top;
    line-height: 2.5rem;

    + .filter-item {
        margin-left: 1.5rem;
    }

    label {
        font-size: 1.1rem;
        line-height: 2.5rem;
        font-weight: bold;
        color: #21201a;
        margin-right: 0.4rem;
        margin-bottom: 0;
        display: inline-block;
        vertical-align: top;
    }

    select, input {
        padding: 0 1.1rem;
        height: 32px;
        border-radius: 3px;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        background: #fff;
        min-width: 10.75rem;
    }

    select {
        padding-right: 2rem;
        cursor: pointer;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        user-select: none;
        -moz-user-select: none;
        background: #fff url('../../pic/icon-arrow-down.svg') no-repeat calc(100% - 15px) center;

        &::-ms-expand {
            display: none;
        }
    }

    input {
        min-width: 15.4rem;
        padding-right: 3.7rem;

        &::placeholder {
            font-style: italic;
            color: #b8b8b8;
            font-size: 0.9rem;
        }

        &:active {
            background: #fff;
        }
    }
}