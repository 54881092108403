.extend-axes-btn {
    color: #4b4b4b;
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}

.dark .extend-axes-btn {
    color: white;
}