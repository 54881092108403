.toolbar-image-block img.thumbnail-img {
  max-height: 255px;
}

.toolbar-image-block .assign-photo-item {
  max-height: 265px;
}

.btn-margin-t-10 {
   margin-top: 10px;
}
.align-btn-block {
  text-align: center;
}
.assign-photo-list-wrapper {
  max-height: max-content;
}