.date-picker-block {
    display: none;
    position: absolute;
    bottom: 3.5rem;
    left: 10rem;
    width: auto;
    padding: 1rem;
    background: white;
    box-sizing: border-box;
    border-radius: 0.5rem;
}

.show-date-picker-block {
    display: block !important;
}

.dark .date-picker-block{
    background: #726d6d !important;
}

.selected-date-range {
    width: 100%;
    margin-bottom: 10px;
    .react-datepicker-wrapper {
        width: 100%;
    }
}
.selected-input-date-range {
    text-align: center;
}
.choosing-dates {
    height: 220px;
}