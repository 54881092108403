.modal-img-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    img {
        width: 50%;

        &.modal-flag-img {
            width: 50px;
        }
    }
}

.modal-control-btns {
    button {
        width: 100%;
        &.btn-white {
            color: black;
            background: white;
            border: 1px solid black;
        }
        &.btn-black {
            color: white;
            background: black;
        }
        + button:not(.mt-0) {
            margin-top: 10px;
        }
    }
}