.equipmentNavigation{
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px;
    svg{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    path{
        fill: #626161
    }
    &:disabled{
        opacity: 0.4;
        cursor: default;
    }
}

.equipmentNavigationDark{
    path{
        fill: #949ccc
    }
}

.rotate{
    transform: rotate(-180deg);
    margin-left: 15px;
    margin-right: 10px;
}