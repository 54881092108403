.location-aside {
    position: fixed;
    width: 340px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 2;
    background: #21201a;
    opacity: 1;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);

    .title {
        margin: 50px auto;
        font-style: normal;
        font-weight: normal;
        text-align: center;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
    }

    .rst__rowContentsDragDisabled {
        padding-left: 10px !important;
    }
}

.remove-block {
    color: #c4c4c4;
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    &:hover {
        color: #000;
    }
}

.k-sortable-row-add {
    .rst__rowContents {
        position: relative;
        border: none;
        border-left: none;
        padding: 0;
        border-radius: 0;
        min-width: auto;
        flex: auto;
        background-color: transparent;
    }
    .rst__rowLabel {
        color: #fff;
        font-size: 1.5rem;
    }
}

.location-tree {
    padding: 0 25px;

    > div {
        ::-webkit-scrollbar{width:6px;height:6px;}
        ::-webkit-scrollbar-button{width:6px;height:6px;}
    }

    .ReactVirtualized__Grid {
        width: 300px !important;
    }
    .ReactVirtualized__Grid__innerScrollContainer {
        width: 300px !important;
    }
    .rst__nodeContent {
        width: 240px;
        box-shadow: none;
        .rst__lineChildren {
            &::after {
                background-color: #9e9e9e;
            }
        }
    }
    .rst__rowWrapper {
        padding: 5px 0px;
    }
    .rst__rowContents {
        min-width: 0;
        background: none;
        color: #c4c4c4;
        box-shadow: none;
        padding-left: 0;
        width: calc(100% - 25px);
    }
    .k-sortable-row {
        border: 1px dashed #c4c4c4;
        border-radius: 2px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        box-shadow: none;
    }
    .rst__lineChildren {
        &::after {
            display: none;
        }
    }
    .rst__moveHandle {
        width: 30px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAQCAYAAAAiYZ4HAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEcSURBVHgBfVHbTcNAEPSdKMAlJB3QAekAUwHYliz5L1TApQLg3w/oACqAEuggV0J+bdkyM8mdtbk8VrI1tzc7O3urIhF1XRut9d00TSuelVK/wB9Zln16juKvbdsYFz+At9GZYFGe5ymxdrnXS2TX6QmiL3sMsIDCVtxbfAYkdl0DL1x+h9zyBuBeqg3D8FAUxZ+z+i3EYnyP2oE5PJmRpqkN3MUs2MlMVVWJx3i1dXQ8vFXuhbayE7x+OUIiC7quW2q05TBvgVISknE2ZVla/6zvobUgLBZKzmEP7AKF5ysFhpy9XZltmobbXgVW5i3PHXxgB2EX2/f9RiaOCrgDPICR6hz0YoELDkeShZVNeHlSwOHGcaRnc0Ys+gca04j1eGkIcgAAAABJRU5ErkJggg==')
            no-repeat center center;
    }
    .rst__rowLabel {
        padding-right: 5px;
        width: calc(100% - 30px);
        .rst__rowTitle {
            width: 100%;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .rst__lineBlock {
        +  {
            .rst__lineBlock {
                +  {
                    .rst__nodeContent {
                        width: 210px;
                    }
                    .rst__lineBlock {
                        +  {
                            .rst__nodeContent {
                                width: 180px;
                            }
                        }
                    }
                }
            }
        }
    }
    .rst__lineBlock.rst__lineHalfHorizontalRight {
        &::before {
            background-color: #9e9e9e;
        }
    }
    .rst__lineBlock.rst__lineFullVertical {
        &::after {
            background-color: #9e9e9e;
        }
    }
    .rst__lineBlock.rst__lineHalfVerticalTop {
        &::after {
            background-color: #9e9e9e;
        }
    }
    .rst__lineBlock.rst__lineHalfVerticalBottom {
        &::after {
            background-color: #9e9e9e;
        }
    }
}