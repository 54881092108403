.sensors-location {
    font-size: 14px;
    color: #4b4b4b;
    display: block;
    line-height: 18px;
    margin-bottom: 18px;
    line-height: 30px;
    border: 2px dashed transparent;
    cursor: grab;

    &:hover {
        border: 2px dashed #8e95a0;
    }

    i {
        margin-right: 10px;
        font-size: 18px;
    }

    span {
        line-height: 18px;
    }

    .point-name {
        overflow: hidden;
        display: block;
        line-height: 30px;
        span {
            display: inline-block;
            vertical-align: middle;
            line-height: 15px;
        }
    }

    .cursor-pointer {
        line-height: 30px;

        i {
            vertical-align: middle;
        }
    }

    > div.d-flex {
        align-items: center;
    }

    label {
        width: 18px;
        height: 18px;
        margin: 0;
    }
}

.sensors-marker-list {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    .sensors-location {
        &:first-child {
            border-top: 1px solid #ccc;
            padding-top: 10px;
        }
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
    }
}

.assign-points-modal {
    .modal-body {
        max-height: calc(100vh - 150px);
    }
}

.popover {
    .sensors-location {
        border: none;
        &:hover {
            border: none;
            cursor: auto;
        }
    }
}

.medium-zoom-image {
    z-index: 1;
    position: relative;
}

.medium-zoom-overlay {
    z-index: 9999;

    + img {
        z-index: 10000;
    }
}

.img--zoomed {
    z-index: 10000;
}

.img-hidden {
    display: none !important;
}

.ask-again-btn-wrapper {
    flex: 1 1 100%;
}