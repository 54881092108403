.metric-label {
    min-width: 90px;
    text-align: left !important;
}

.metric-label-profile {
    text-align: left !important;
    min-width: 90px;
    +  {
        label {
            display: inline-block !important;
            margin-left: 10px;
            min-width: 70px;
            +  {
                label {
                    display: inline-block !important;
                    margin-left: 10px;
                    min-width: 70px;
                }
            }
        }
    }
}