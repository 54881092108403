@import "src/assets/scss/config/variables";

.dark {
    &.app-page {
        background: map-get($theme-colors-dark-bg, default);
    }

    a:not(.btn) {
        color: map-get($theme-colors-dark, dark-blue);

        &:hover {
            color: map-get($theme-colors-dark-hover, white-hover);
        }
    }

    .react-select__control {
        //color: #ddd;
        border-color: #999;
        background-color: rgb(56, 56, 46);
        .react-select__input{
            color: #fff !important;
        }
    }

    .react-select__placeholder,
    .react-select__single-value {
        color: #ddd;
    }

    .react-select__menu {
        border-color: #999;
        background-color: rgb(56, 56, 46);
    }

    .react-select__option {
        background-color: rgb(56, 56, 46);
        color: #ddd;
        &:hover {
            background: #424242 !important;
        }
    }

    .react-select__option--is-selected {
        background: #278ebb;
    }

    .react-select__control--is-disabled {
        background-color: #424242 !important;
        cursor: not-allowed;
    }

    .react-select__option--is-disabled {
        background-color: #505049 !important;
        color: #9e9e9e;
        cursor: not-allowed;
    }

    .app-grid,
    .header,
    .subheader-title,
    .subheader-controls {
        background: map-get($theme-colors-dark-bg, default);
        color: map-get($theme-colors-dark, light-gray);
    }

    .subheader--no-after {
        .subheader-title {
            color: #aaa;
        }
    }

    .modal-content {
        background: map-get($theme-colors-dark-bg, default);
        color: map-get($theme-colors-dark, light-gray);
        border-color: map-get($theme-colors-dark-bg, border-colors);

        .bg-white {
            background-color: map-get($theme-colors-dark-bg, default) !important;
        }
    }

    .equipment-table {
        background: map-get($theme-colors-dark-bg, default);
        color: map-get($theme-colors-dark, light-gray);

        thead {
            th {
                background: map-get($theme-colors-dark-bg, default);
                color: map-get($theme-colors-dark, light-gray);
                border-bottom-color: rgba(100, 100, 100, 0.9);
            }
        }

        .info-row {
            background-color: map-get($theme-colors-dark, table-color);

            &.collapse.show {
                border-left: 3px solid rgba(150, 150, 150, 0.8);
            }
        }

        .head-row[aria-expanded="true"] {
            border-left: 3px solid rgba(150, 150, 150, 0.8);
        }
    }

    .sweet-alert {
        background: map-get($theme-colors-dark-bg, form-fields) !important;
    }

    .text-muted {
        color: #a7a6bd !important;
    }

    hr {
        border-top: 1px solid map-get($theme-colors-dark, white-opacity);
    }

    .filter-item {
        input {
            background: map-get($theme-colors-dark-bg, form-fields);
        }
        select {
            background: map-get($theme-colors-dark-bg, form-fields) url("../pic/icon-arrow-down.svg") no-repeat 98% center;
        }
        label {
            color: map-get($theme-colors-dark, light-gray);
        }
    }

    input {
        &:-internal-autofill-selected {
            background: map-get($theme-colors-dark-bg, form-fields) !important;
        }
        &:checked {
            + {
                .slider {
                    border: 1px solid #3b87dd;
                }
            }
        }
    }

    select {
        option {
            color: inherit;
        }
    }

    .block {
        .block-title {
            color: map-get($theme-colors-dark, light-gray);

            small {
                color: map-get($theme-colors-dark, light-gray);
            }
        }
    }

    .sweet-alert {
        color: #ccc;
    }

    .btn.btn-default {
        background-color: #555;
        color: #ccc;

        &:hover {
            background-color: #777;
            color: #fff;
        }
    }

    .dropdown-menu.notification-dropdown {
        .dropdown-item {
            color: rgb(202, 202, 202);
            &:hover {
                color: rgb(255, 255, 255);
                background: rgb(51, 92, 173);
            }
        }
        box-shadow: rgb(61, 61, 61) 0 0 5px;
    }

    .dropdown-header {
        color: #ddd;
    }

    .right-dropdown-btn-notes {
        i {
            color: #387fad;
        }
    }

    .equipment-modal {
        .modal-footer {
            background: map-get($theme-colors-dark-bg, default);
        }
    }

    .show {
        > .btn-secondary.dropdown-toggle {
            border-color: #7e7e7e !important;
            background-color: #7e7e7e !important;
            color: #fff;
        }
    }

    .btn-secondary {
        &:focus {
            border-color: #7e7e7e !important;
            background-color: #7e7e7e !important;
            color: #fff;
        }
        &:active {
            border-color: #7e7e7e !important;
            background-color: #7e7e7e !important;
            color: #fff;
        }
        &:hover {
            border-color: #7e7e7e !important;
            background-color: #7e7e7e !important;
            color: #fff;
        }

        &.active {
            border-color: #7e7e7e !important;
            background-color: #7e7e7e !important;
            color: #fff;
        }
    }

    .alert.alert-outline-danger {
        border-color: rgba(250, 69, 37, 0.937);
        color: rgba(250, 69, 37, 0.937);
        .alert-icon {
            i {
                border-color: rgba(250, 69, 37, 0.937);
                color: rgba(250, 69, 37, 0.937);
            }
        }
        .alert-text {
            border-color: rgba(250, 69, 37, 0.937);
            color: rgba(250, 69, 37, 0.937);
        }
    }

    a.badge-danger {
        color: #b7bddd;
    }

    .badge.badge-info {
        background: #278ebb;
    }

    .badge-secondary {
        background-color: #858481;
        color: #eee;
    }

    .badge.badge-warning {
        background: rgb(197, 164, 43);
        opacity: 0.9;
    }

    .badge-danger {
        background-color: #c73d3d;
        opacity: 0.9;
    }

    .in-blue-circle {
        background: #2765ac;
    }

    .dropzone {
        background: rgb(56, 56, 46);
        border-color: #777;
    }

    .dropzone-icon {
        color: #2765ac;
    }

    .input-group-text {
        background-color: #555;
        border-color: #999999;
        color: #ccc;
        i {
            color: #ccc;
        }
    }

    .request-row {
        &:hover {
            background: #2b2923;
        }
    }

    .nav-tabs.nav-tabs-line.nav.nav-tabs {
        .nav-link {
            color: map-get($theme-colors-dark, light-gray);

            &:hover {
                > i {
                    color: #31aee3;
                }
            }
        }
        .nav-link.active {
            > i {
                color: #31aee3;
            }
        }
    }

    .nav-tabs.nav-tabs-line {
        a.nav-link {
            &:hover {
                > i {
                    color: #31aee3;
                }
            }
            color: map-get($theme-colors-dark, light-gray);
        }
        a.nav-link.active {
            > i {
                color: #31aee3;
            }
        }
    }

    .datepicker {
        thead {
            th.prev {
                &:hover {
                    background-color: #4e4e4e;
                    color: #fff;
                }
            }
            th.datepicker-switch {
                &:hover {
                    background-color: #4e4e4e;
                    color: #fff;
                }
            }
            th.next {
                &:hover {
                    background-color: #4e4e4e;
                    color: #fff;
                }
            }
        }
        tbody {
            tr {
                > td.day {
                    color: #ccc;
                    &:hover {
                        background: map-get($theme-colors-dark, light-gray);
                        color: #fff;
                    }
                }
                > td.day.old {
                    color: #777;
                    &:hover {
                        color: #ccc;
                    }
                }
                > td.day.new {
                    color: #777;
                    &:hover {
                        color: #ccc;
                    }
                }
                > td {
                    span.month {
                        color: #fff;
                        &:hover {
                            background: #4e4e4e !important;
                        }
                    }
                    span.month.active {
                        &:hover {
                            background: #4e4e4e !important;
                            color: #fff !important;
                        }
                        background: #4e4e4e !important;
                        color: #fff !important;
                    }
                    span.month.focused {
                        background: #4e4e4e !important;
                    }
                }
                > td.day.selected {
                    background: #4e4e4e !important;
                    &:hover {
                        background: #4e4e4e !important;
                    }
                }
                > td.day.active {
                    background: #4e4e4e !important;
                    &:hover {
                        background: #4e4e4e !important;
                    }
                }
            }
        }
        table {
            tr {
                td {
                    span {
                        &:hover {
                            background: #4e4e4e !important;
                            color: #fff !important;
                        }
                    }
                    span.focused {
                        background: #4e4e4e !important;
                        color: #fff !important;
                    }
                    span.active {
                        &:hover {
                            background: #424242 !important;
                            &:hover {
                                background: #424242 !important;
                            }
                        }
                    }
                    span.active.disabled {
                        &:hover {
                            background: #424242 !important;
                            &:hover {
                                background: #424242 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .bootstrap-timepicker-widget {
        .bootstrap-timepicker-hour {
            color: #ccc;
        }
        .bootstrap-timepicker-minute {
            color: #ccc;
        }
        .bootstrap-timepicker-meridian {
            color: #ccc;
        }
        .bootstrap-timepicker-second {
            color: #ccc;
        }
        table {
            td {
                a {
                    color: #ccc;
                }
                > a {
                    &:hover {
                        background: map-get($theme-colors-dark, light-gray);
                        color: #fff;
                    }
                }
            }
        }
    }

    .dropdown-equipment {
        > button {
            background: #2765ac !important;
        }
        > .btn-secondary.dropdown-toggle {
            background: #2765ac !important;
        }
        > .btn-secondary {
            &:focus {
                background: #2765ac !important;
            }
        }
        .dropdown-menu {
            border: 1px solid rgba(204, 204, 204, 0.301) !important;
            button {
                background-color: #383835;
                color: #ccc;
            }
        }
    }

    .alert-groups {
        .dropdown-equipment {
            > button {
                background: none !important;
                color: #eee;
            }
            > .btn-secondary.dropdown-toggle {
                background: none !important;
                color: #eee;
            }
            > .btn-secondary {
                &:hover {
                    background: none !important;
                    color: #eee;
                }
                &:focus {
                    background: none !important;
                    color: #eee;
                }
            }
        }
        thead {
            tr {
                background: map-get($theme-colors-dark-bg, form-fields);
            }
        }
    }

    .sticky-custom-header {
        background-color: map-get($theme-colors-dark-bg, form-fields);
    }

    .card {
        background-color: #272724;
        border: 1px solid rgba(204, 204, 204, 0.301);
    }

    .modal-footer {
        border-top-color: map-get($theme-colors-dark, light-gray);
    }

    .close {
        color: #ccc;
        &:not(:disabled) {
            &:not(.disabled) {
                &:hover {
                    color: #fff;
                }
                &:focus {
                    color: #fff;
                }
            }
        }
    }

    .chart-title {
        color: #ccc;
    }

    .modal-header {
        .modal-title {
            color: #ccc;
        }
    }

    .modal {
        .modal-content {
            .modal-header {
                .modal-title {
                    color: #ccc;
                }
            }
        }
        .k-portlet {
            background-color: map-get($theme-colors-dark-bg, default) !important;
        }
        .k-timeline {
            .k-timeline__item {
                .k-timeline__item-section {
                    .k-timeline__item-section-border {
                        background: map-get($theme-colors-dark-bg, default);
                    }
                }
            }
        }
    }

    .demand-history {
        color: #ccc;
    }

    .user-dropdown {
        background-color: map-get($theme-colors-dark-bg, form-fields) !important;
    }

    .left-user-link {
        .menu-item.fake-link {
            color: map-get($theme-colors-dark, dark-blue);
            &:hover {
                color: #eee;
            }
        }
    }

    .form-control {
        color: #ddd;
        border-color: #999;
        background-color: rgb(56, 56, 46);
        &:focus {
            color: #ccc;
        }
        &:disabled {
            background: rgb(56, 56, 46);
            color: #777;
            background: #424242 !important;
            + {
                .input-group-append {
                    > span {
                        background: #424242 !important;
                    }
                }
            }
        }
    }

    select.form-select {
        background: rgb(56, 56, 46) url("../pic/icon-arrow-down.svg") no-repeat 98% center !important;
    }

    .chart-block {
        background: #413f39;

        .alert-link {
            color: #3b87dd;
        }
    }

    .slider {
        background-color: rgb(56, 56, 46);
    }

    .collapse-tree-button {
        background: #db9f1d;
        color: #000;
    }

    .collapse-tree-button[aria-expanded="true"] {
        background: #c23a3a;
    }

    .table-bordered {
        th {
            border-color: rgba(100, 100, 100, 0.9);
        }
        td {
            border-color: rgba(100, 100, 100, 0.9);
        }
    }

    .table {
        thead {
            th {
                border-bottom-color: rgba(100, 100, 100, 0.9);
            }
        }
        th {
            border-top-color: rgba(100, 100, 100, 0.9);
        }
        td {
            border-top-color: rgba(100, 100, 100, 0.9);
        }
    }

    .text-info {
        color: map-get($theme-colors-dark, light-gray) !important;
    }

    .bg-gray-block {
        background: #20201e;
    }

    .dropdown-menu {
        background-color: #383835;
        color: #ccc;
    }

    .dropdown-item {
        background-color: #383835;
        color: #ccc;
    }

    .popover {
        background-color: rgb(61, 61, 53);
        color: #ccc;
        border-color: rgba(0, 0, 0, 0.05);

        .popover-header {
            background: #2e2e2a;
            color: #ccc;
            border-bottom: none;
        }

        .bg-white {
            background-color: rgb(61, 61, 53) !important;
        }
    }

    //k-list
    .popover-body {
        .k-list {
            .k-list__item {
                .k-list__text {
                    color: map-get($theme-colors-dark, light-gray);
                }
            }
        }
    }

    .bs-popover-bottom {
        .arrow {
            &::before {
                border-bottom-color: #444440;
            }
            &::after {
                border-bottom-color: #444440;
            }
        }
    }

    .bs-popover-auto[x-placement^="bottom"] {
        .arrow {
            &::before {
                border-bottom-color: #444440;
            }
            &::after {
                border-bottom-color: #444440;
            }
        }
    }

    .table-image-icon.mail-icon {
        svg {
            fill: map-get($theme-colors-dark, light-gray);
        }
    }

    .autocomplete {
        > div {
            background-color: rgb(56, 56, 46) !important;
            color: #eee;
        }
    }

    .custom-scroll {
        &::-webkit-scrollbar {
            background: #777;
        }
    }

    .custom-scroll-tree {
        .ReactVirtualized__Grid {
            &::-webkit-scrollbar {
                background: #777;
            }
        }
    }

    .bs-popover-right {
        .arrow {
            &::after {
                border-right-color: #777;
            }
            &::before {
                border-right-color: #777;
            }
        }
    }

    .bs-popover-auto[x-placement^="right"] {
        .arrow {
            &::after {
                border-right-color: #777;
            }
            &::before {
                border-right-color: #777;
            }
        }
    }

    .whats-new-popover-title {
        color: #eee;
    }

    .whats-new-popover-item {
        border-color: map-get($theme-colors-dark, light-gray);
    }

    .whats-new-item-title {
        color: #ddd;
    }

    .whats-new-item-description {
        color: map-get($theme-colors-dark, light-gray);
    }

    .eq-right {
        background: #272724;
    }

    .eq-name {
        background: #20201e;
        color: #ccc;
    }

    .eq-line {
        color: map-get($theme-colors-dark, light-gray);
    }

    .inst-point_alert-points {
        color: rgb(248, 80, 80);
        margin: 0 3px;
    }

    .eq-item-wrapp {
        .badge._badge.badge-warning {
            opacity: 0.8;
        }
    }

    .eq-item {
        .eq-photo {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .table-hover {
        tbody {
            tr {
                &:hover {
                    background-color: #555;
                }
            }
        }
    }

    .installation-point-block {
        background: #3a3934;
    }

    .inst-point-title {
        color: map-get($theme-colors-dark, light-gray);
    }

    .equipment-tree-container {
        border-left: 1px solid map-get($theme-colors-dark, light-gray);
    }

    .equipment-content {
        color: #ccc;
    }

    .checkbox-drop {
        .btn {
            color: map-get($theme-colors-dark, light-gray);
        }

        &:hover {
            background-color: #4d4a3c;

            .btn {
                color: #ddd;
            }
        }
    }

    //klist
    .k-sortable-row {
        background: #20201e;
        border: 3px solid #999;
    }

    .rst__rowContents {
        background: #383835;
    }

    .rst__moveHandle {
        background: #555
            url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
            no-repeat center;
    }

    .rst__loadingHandle {
        background: #555
            url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
            no-repeat center;
    }

    .tree-facility-icon {
        background-color: #555;
    }

    .axis-block {
        color: map-get($theme-colors-dark, light-gray);

        &.active {
            color: #3b87dd;
            border-bottom: 3px solid #3b87dd;
        }
    }

    .modal-dialog.custom-modal {
        .form-group {
            label {
                &:not(.modal-checkbox) {
                    color: map-get($theme-colors-dark, light-gray);
                }
            }
        }
    }

    .aside-content {
        .k-checkbox {
            > span {
                background: rgb(56, 56, 46);
            }
        }
    }

    .point-settings-form-container {
        .form-group {
            label {
                color: map-get($theme-colors-dark, light-gray);
            }
            input {
                background: rgb(56, 56, 46);
                color: #ddd;
            }
            select {
                background: rgb(56, 56, 46);
                color: #ddd;
            }
            .form-control {
                &:disabled {
                    background: rgb(56, 56, 46);
                    color: #777;
                    background: #424242 !important;
                }
            }
            .form-control[readonly] {
                background: rgb(56, 56, 46);
                color: #777;
                background: #424242 !important;
            }
        }
    }

    .form-control[readonly] {
        background: rgb(56, 56, 46);
        color: #777;
        background: #424242 !important;
    }

    .k-iconbox {
        background-color: rgb(56, 56, 46);
    }

    .create-equipment-container {
        background: #2b2923;
        .sub-title {
            span {
                background: transparent;
                color: #2765ac;
            }
        }
        .add-new-link {
            color: #449aff;
        }
    }

    .tab-content-title {
        color: #ccc;
    }

    .chart-block-header {
        margin-bottom: 10px;
    }

    .chart-title-text {
        color: #ddd;
    }

    .react-contextmenu {
        background-color: #383835;
    }

    .frequency-types-link {
        color: #ccc;
    }

    .react-contextmenu-item {
        color: #ccc;
    }

    .speed-settings-form {
        .sub-title {
            span {
                background: transparent;
                color: #2765ac;
            }
        }

        .add-new-link {
            color: #2765ac;
        }
    }

    .nameplate-modal-form {
        .sub-title {
            span {
                background: transparent;
                color: #2765ac;
            }
        }
    }

    .manage-alerts-modal {
        .add-new-link {
            color: #2765ac;
        }
    }

    .bg-white {
        background-color: #2b2923 !important;
    }

    .highcharts-tooltip {
        > span {
            background: rgba(10, 10, 10, 0.15);
            padding: 4px;
            border: none;
            box-shadow: none;
        }
        span,
        li {
            color: map-get($theme-colors-dark, light-gray);
        }
    }

    .highcharts-reset-zoom {
        rect {
            fill: #555;
        }
        text {
            color: map-get($theme-colors-dark, light-gray) !important;
            fill: map-get($theme-colors-dark, light-gray) !important;
        }
    }

    .aside-tabs-item {
        color: map-get($theme-colors-dark, light-gray);
    }

    .installation-point-settings-list {
        .card {
            background-color: map-get($theme-colors-dark-bg, form-fields);
        }

        .card-title {
            span {
                color: #ccc;
            }
        }
    }

    .settings-preview-list {
        .group-title {
            color: #ccc;
        }
    }

    .sensors-location {
        color: map-get($theme-colors-dark, light-gray);
    }

    .divider {
        &::before {
            border-bottom-color: #ccc;
        }
        &::after {
            border-bottom-color: #ccc;
        }
    }

    .bearing-table {
        thead {
            background-color: rgba(54, 54, 50, 0.3);
        }
    }

    .info-row {
        background-color: rgba(54, 54, 50, 0.3);
    }

    .highcharts-button {
        text {
            color: #ccc !important;
            fill: #ccc !important;
            stroke: #ccc !important;
            stroke-width: 1;
        }

        path {
            color: #ccc !important;
            fill: #ccc !important;
            stroke: #ccc !important;
            stroke-width: 1;
        }

        rect {
            color: #555 !important;
            fill: #555 !important;
            stroke-width: 1;
        }
    }

    .help-page {
        background: map-get($theme-colors-dark-bg, default);
        color: #ccc;

        .title {
            color: #ccc;
        }
    }

    .info-plate-button {
        background-color: rgb(56, 56, 46);
    }

    .information-plates-container {
        .header-info-plates {
            background: map-get($theme-colors-dark-bg, form-fields);
        }
        .information-plates-table-container {
            background: map-get($theme-colors-dark-bg, form-fields);
        }
    }

    .nameplate-component-block {
        background: #2b2923;
    }

    .info-plate-state {
        color: #555;

        &.active {
            color: #fff;
        }
    }

    .aside-bottom-settings {
        background: map-get($theme-colors-dark-bg, form-fields);
        color: #ddd;
    }

    .show-band-trend-label {
        background: rgba(65, 63, 57, 1);
        padding-right: 6px;
    }

    .envelope-calculation-settings {
        padding-right: 6px;
    }

    .global-notes-table {
        .note-textarea {
            color: #fff !important;
            background: #555 !important;
            border-color: #999 !important;
        }
    }

    .alert-level-block {
        background: #3a3833;
    }

    .alert-groups-select {
        .custom-scroll {
            background: #555;
        }
    }

    .device-alert-groups-select {
        .custom-scroll {
            background: #555;
        }
    }

    .interval-block {
        .other-inst-points {
            background: #b59831;
            color: #544109;
        }
    }

    .interval-content {
        .other-inst-points {
            a {
                color: #0049a4;
            }
        }
    }

    .noUi-base {
        background: #555;
    }

    .noUi-connects {
        background: #555;
    }

    .noUi-handle {
        background: #555;
        border-color: map-get($theme-colors-dark, light-gray);
        box-shadow: inset 0 0 1px #000, inset 0 1px 7px #555, 0 3px 6px -3px #555;
    }

    .noUi-horizontal {
        .noUi-tooltip {
            background-color: #555;
            color: #ddd;
        }
    }

    .trend3d-bg {
        background: #383730;
        padding: 0 5px;
    }

    .chart-label {
        background: #393731;
        border-color: rgb(227, 187, 49);
        box-shadow: rgb(227, 187, 49) 1px 1px 3px;
    }

    .chart-mark-label {
        background: #393731;
        border-color: rgb(227, 187, 49);
        box-shadow: rgb(227, 187, 49) 1px 1px 3px;
    }

    .fault-frequency-label {
        background: #393731;
        border-color: rgb(227, 187, 49);
        box-shadow: rgb(227, 187, 49) 1px 1px 3px;
    }

    .chart-alert-label-text {
        color: #fff;
    }

    .chart-mark-label-text {
        color: #fff;
    }

    .fault-frequency-label-text {
        color: #fff;
    }

    .highcharts-axis-labels {
        text {
            color: map-get($theme-colors-dark, light-gray) !important;
            fill: map-get($theme-colors-dark, light-gray) !important;
        }
    }

    optgroup[label] {
        color: #cacaca;
    }

    .view-action-item {
        .ai-title-second {
            background: #413f39;
        }

        .sensor-block {
            background: #413f39;
        }

        .comment.status {
            .date {
                color: #fff;
            }
            .status {
                color: #fff;
            }
            .description {
                color: #fff;
            }
        }

        .comment {
            &:not(.status) {
                &:hover {
                    background: #413f39;
                }
            }
        }
    }

    .rdw-editor-toolbar.ai-editor-toolbar {
        background: #615f5c;
    }

    .swal2-popup {
        background: map-get($theme-colors-dark-bg, default);
        color: #fff;

        .swal2-styled {
            &:focus {
                border: none;
                box-shadow: none;
            }
        }

        .swal2-content {
            color: #fff !important;
        }
    }

    .rdw-option-wrapper {
        border: 1px solid #0e0d0d;
        background: #615f5c;
    }

    .rdw-dropdown-wrapper {
        border: 1px solid #21201a;
        background: #615f5c;
    }

    .rdw-dropdown-optionwrapper {
        background: #615f5c;
        color: #000;
        &:hover {
            color: #000;
        }
    }

    .rdw-link-modal {
        background: #615f5c;
        color: #fff;
        input {
            background: #615f5c;
            color: #fff;
        }
    }

    .rdw-image-modal {
        background: #615f5c;
        color: #fff;
    }

    .rdw-image-modal-upload-option {
        background-color: map-get($theme-colors-dark-bg, form-fields);
        color: #fff;
    }

    .rdw-image-modal-size {
        input {
            background: #615f5c;
            color: #fff;
        }
    }

    .rdw-image-modal-url-input {
        background: #615f5c;
        color: #fff;
    }

    .rdw-link-modal-target-option {
        margin-bottom: 3px;
    }

    .swal2-success-circular-line-left {
        background: map-get($theme-colors-dark-bg, default) !important;
    }

    .swal2-success-circular-line-right {
        background: map-get($theme-colors-dark-bg, default) !important;
    }

    .swal2-success-fix {
        background: map-get($theme-colors-dark-bg, default) !important;
    }

    .swal2-popup.swal2-styled.swal2-confirm {
        outline: 0;
        border: none !important;
        box-shadow: none;
    }

    .action-item-list {
        .sticky-table-header {
            th {
                background: map-get($theme-colors-dark-bg, form-fields);
                color: #fff;
            }
        }
    }

    .dark-title {
        color: #fff;
    }

    .ai-info-title {
        color: #fff;
    }

    .ai-info-description {
        color: #fff;
    }

    .ai-title {
        color: #fff;
    }

    .req-label-size {
        color: #fff;
    }

    .snooze-title {
        color: #fff;
    }

    .modebar-group {
        span {
            color: #cccccc;
            opacity: 0.6;
        }
    }

    .v2-btn.blue-border {
        &:hover {
            background: map-get($theme-colors-dark-bg, form-fields);
        }
    }

    .k-error404-v1 {
        .k-error404-v1__content {
            .k-error404-v1__new-desc {
                color: #fff;
            }
            .k-error404-v1__sub-desc {
                color: #fff;
            }
        }
    }

    .react-datepicker {
        background-color: #413f37;

        &__header {
            background-color: #413f37;
        }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name,
    .react-datepicker__current-month {
        color: #fff;
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        color: #737373;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        border-bottom-color: #413f37;
    }

    .rdtPicker {
        background: #413f37;
    }

    .rdtBtn {
        &:hover {
            background: #38382e;
        }
    }

    .profile-type.profile-list-block {
        background: #413f39;
    }

    .step.done .triangle.left .inner {
        background: #36342a;
    }

    .profile-type.profile-list-block .type {
        color: #fff;
    }

    .profile-configurator-header .title {
        color: #fff;
    }

    .step-top .step-title,
    .step-top .step-description,
    .general-step,
    .general-step label {
        color: #fff;
    }

    .profile-configurator select.form-control.valid,
    .profile-configurator .form-control.valid,
    .profile-alerts-modal .form-control.valid,
    .valid .form-control-select > div > div:first-child,
    .valid.form-control-select > div > div:first-child {
        background: #413f39 url("/assets/pic/icon-check.svg") no-repeat 96% center !important;
    }

    .profile-configurator .steps .step.disabled {
        background: #36342a;
    }

    .step.disabled .triangle.left .inner,
    .step.disabled .triangle.right .inner {
        background: #36342a;
    }

    .profile-configurator .add-new-block {
        border: 1px dashed #fff;
    }

    .installation-points-step .step-title {
        color: #fff;
    }

    .step.active .triangle.left .inner {
        background: #36342a;
    }

    .profile-configurator .form-group label {
        color: #fff;
    }

    .bearings-step .step-title {
        color: #fff;
    }

    .bearings-block span {
        color: #fff;
    }

    .installation-points-step .remove .fas {
        color: #fff;
    }

    .profile-alerts-modal .title {
        color: #fff;
    }

    .profile-modal-body {
        color: #fff;
    }

    .alerts-step {
        color: #fff;
    }

    .trigger-type {
        color: #fff !important;
    }

    .form-control.valid {
        background: red;
    }

    .profile-select__option {
        color: #fff;
    }

    .type-icon svg {
        fill: white !important;
    }

    .profile-configurator .type-icon {
        background: none;
    }

    .profile-type .description {
        color: #fff;
    }

    .create-equipment-container .sub-title span,
    .speed-settings-form .sub-title span,
    .create-equipment .sub-title span {
        background: transparent;
        color: #fff;
    }

    .dark-title {
        color: #000;
    }

    .profile-configurator .add-new-block:hover .dark-title {
        color: #000;
    }

    .profile-configurator .alerts-table th {
        border: none;
        background: #36342a;
    }

    .alerts-table {
        border-color: #5f5f5e;
    }

    .dark-color {
        &:hover {
            color: #000;
        }
    }

    .profile-actions .setting-icon-wrapper {
        &:hover {
            svg {
                stroke: #000 !important;
            }
        }
    }

    .bearing-select__option,
    .bearing-select__placeholder {
        color: #fff;
    }

    .highcharts-button-custom {
        color: #fff;
        background: #555;
    }

    .highcharts-reset-zoom-custom {
        border: 1px solid #fff;
        background: #555555;

        &:hover {
            border-color: #7e7e7e;
            background-color: #7e7e7e;
            color: #fff;
        }
    }

    .wrapper-equipment-compare {
        .inst-point-block {
            background: #404040;
        }

        .block {
            .subheader-title {
                background: #36342a;
            }
        }

        .bearing-block {
            background: #413f38;
        }

        .will-create {
            background: #328032;
        }

        .will-change {
            background: #27677b;
        }

        .will-delete {
            background: #633939;
        }
    }

    .whats-overlay {
        background: rgba(0, 0, 0, 0.5);
    }

    .drag-tooltip-btn {
        background: #505050 !important;
        color: #f0f0f0 !important;
    }

    .wrapper-dnd {
        &.allow-container {
            &::before {
                background: rgba(154, 237, 213, 0.14);
            }
        }

        &.not-allow-container {
            &::before {
                background: rgba(251, 152, 152, 0.14);
            }
        }
    }
    .loader-relative_solid {
        background: #2c2b24;
    }
}
