.chart-block-header {
    display: flex;
    flex-wrap: wrap;
}

.chart-block-title {
    flex-shrink: 0;
}

.chart-block-actions {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //flex-wrap: wrap;

    .chart-actions-wrapper {
        margin-right: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -5px;

        .btn-group-sm {
            margin-top: 5px !important;
        }
    }

    .highcharts-reset-zoom-custom {
        margin-top: 5px;
        background: #f0f0f0;

        &:hover {
            background: #ddd;
            color: #505050;
        }
    }
}
