.equipment-info-row {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
    &:last-child {
        border-bottom: none;
    }
}
.equipment-image-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
}
.equipment-image-item {
    width: 30%;
    margin-top: 5px;
    position: relative;
    overflow: hidden;
    padding-top: 28%;
    background: #fff;
    border: none;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 150%;
        max-height: 150%;
    }
}

.header.equipment-page {
    .header-left {
        line-height: 3rem;
    }
}

.equipment-header {
    line-height: 1rem;
    display: flex;
    align-items: center;
    > div {
        margin-right: 15px;
    }
    .equipment-name {
        padding: 5px 0;
        font-size: 1.1rem;
        font-weight: bold;
        display: block;
        margin-right: 20px;
    }
    .equipment-info-btn {
        color: #449aff;
        margin-left: 5px;
    }
}
.equipment-modal {
    .link {
        color: #449aff;
    }
    .link.color-danger {
        color: rgb(217, 83, 79);
    }
    .modal-footer {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: white;
    }
    .modal-body {
        padding-bottom: 60px !important;
    }
}
.equipment-modal-header {
    > div {
        margin-right: 15px;
    }
}
.equipment-block {
    .card {
        height: 100%;
    }
    height: inherit;
    margin-bottom: 1rem;
    .card-body {
        box-shadow: 0px 0px 3px 2px #cccccc;
    }
    > .eq-item-dnd {
        border: 2px dashed #ccc;
    }

    .eq-item {
        @media screen and (min-width: 2048px) {
            padding-top: 50%;
        }
        @media screen and (min-width: 2560px) {
            padding-top: 46%;
        }
    }
    @media screen and (min-width: 2048px) {
        width: 20%;
        flex: 0 0 20%;
    }
    @media screen and (min-width: 2560px) {
        width: 12%;
        flex: 0 0 12%;
    }
    @media screen and (min-width: 3840px) {
        width: 10%;
        flex: 0 0 10%;
    }
}
.equipment-body {
    display: flex;
    flex-direction: row;
}
.equipment-name {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 12px;
}
.equipment-info {
    padding: 10px 15px 0;
}
.round-badge {
    border-radius: 1.2em;
    margin-right: 5px;
    &:last-child {
        margin-right: 0;
    }
}
.header-equipments-block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.middle-line {
    width: 100%;
    height: 1px;
    background-color: #d6d6d6;
    margin: 0 10px;
    display: flex;
    flex-wrap: nowrap;
}
.right-equipment-block {
    display: flex;
    flex-wrap: nowrap;
    line-height: 3.1rem;
    display: inline-block;
    vertical-align: middle;
}
.change-list-view {
    > i {
        margin: 5px;
    }
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #cccccc;
    i {
        &:hover {
            cursor: pointer;
        }
    }
    i.active {
        color: #8b8b8b;
    }
}
.equipments-main-block {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.relative-block {
    position: relative;
}
.info-img-block {
    > div {
        margin-top: 15px;
    }
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 30px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
}
.alert-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding: 0;
    text-align: center;
    font-size: 9px;
    transform: translate(50%, -50%);
}
.equipment-list-link {
    color: #1291e0;
}

a.equipment-card-link {
    color: #333333 !important;
    &:hover {
        color: #333333 !important;
    }
}

.edit-equipment-button {
    cursor: pointer;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
    .fa {
        font-weight: 300;
    }
}

.edit-equipment-button-close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 18px;
    color: #fff;
}

//equipment profile

.empty-profile-page {
    min-height: calc(100vh - 12rem);
    padding-bottom: 10rem;
    display: flex;
}
.empty-profile-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 800px;
    width: 90%;
    align-items: center;
    color: #4b4b4b;
    .profile-image {
        max-width: 600px;
        min-width: 550px;
    }
    .title {
        font-size: 20px;
        font-weight: bold;
    }
    .title-second {
        font-size: 14px;
        margin-top: 1rem;
    }
    .image-block {
        position: relative;
        padding: 0 65px;
        width: 100%;
        text-align: center;
        .profile-image {
            margin-left: 50px;
        }
    }
    .description-block {
        position: absolute;
        bottom: -70px;
        width: 100%;
        left: 0px;
        text-align: center;
    }
}

.add-profile-btn {
    padding: 12px 45px 8px !important;
    > img {
        height: 17px;
        margin-bottom: 3px;
        margin-right: 3px;
    }
}

.ip-online-label {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: lightgrey;
    display: block;

    &.online {
        background-color: green;
    }

    &.offline {
        background-color: red;
    }
}
