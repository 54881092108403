.whats-new-popover-container .popover-body {
    padding: 0;
}

.whats-new-popover-container {
    width: 700px;
}

.whats-new-popover-container.popover {
    max-width: 700px;
    z-index: 1040;
}

.whats-new-filter {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.whats-new-marked {
    position: absolute;
    left: 0;
    top: 20px;
}

.whats-new-point-icon {
    font-size: 7px;
}

.whats-new-popover-item {
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    padding: 17px 21px 15px 21px;
}

.whats-new-popover-item:last-child {
    border-bottom: none;
}

.whats-new-item-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #4b4b4b;
    margin-bottom: 7px;
    text-decoration: underline;
}

.whats-new-menu-popover.hidden {
    display: none !important;
}

.whats-new-description-modal-block {
    text-align: justify;
}

.whats-new-item-description ol,
.whats-new-description-modal-block ol {
    padding-left: 14px;
}

.whats-new-item-description ul,
.whats-new-description-modal-block ul {
    margin-left: 16px;
    margin-bottom: 5px;
}

.whats-new-item-description p {
    margin-bottom: 0;
}

.whats-new-item-description {
    display: inline-block;
    width: 100%;
    max-height: 143px;
    overflow-y: hidden;
    font-size: 13px;
    line-height: 18px;
    color: #747474;
}

.whats-new-icon {
    width: 14px;
}

.whats-new-item-date {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #9e9e9e;
    margin-top: 5px;
}

.whats-new-popover-title {
    font-size: 16px;
    line-height: 19px;
    color: #4b4b4b;
    font-weight: bold;
}
