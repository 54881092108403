@media (max-width: 1650px) {
    .chart-block {
        padding: 15px;
    }

    .chart-block-title {
        line-height: 26px !important;
    }

    .chart-title-text {
        font-size: 12px;
        letter-spacing: normal;
    }
}

@media (max-width: 1440px) {
    .btn-sm,
    .btn-group-sm > .btn {
        padding: 5px 8px;
        font-size: 10px;
        line-height: 14px;
    }

    .chart-block .btn-group-sm {
        margin-right: 6px;
    }

    .nav-link.dropdown-toggle:after,
    .btn.dropdown-toggle:after {
        font-size: 8px;
        margin-left: 4px;
        margin-right: 0;
    }

    .chart-block .alert-link {
        line-height: 24px;
        margin-right: 8px;
    }

    .k-aside-secondary {
        width: 17rem;
    }

    .slide-item {
        width: 17rem;
        height: 17rem;
        position: relative;
        text-align: center;
        line-height: 17rem;
        overflow: hidden;
    }

    .chart-block {
        padding: 10px;
    }

    .chart-title-equipment,
    .chart-title-text {
        font-size: 12px !important;
        margin-right: 10px;
    }

    .modal-open .modal .chart-block-title,
    .modal-open .modal .chart-title-equipment {
        line-height: 13px !important;
    }

    .header-right .global-search {
        width: 17rem;
    }

    .table td, .table th {
        padding: 5px;
    }

    .bg-gray-block {
        background-color: #e5e5e5;

        .action-icon-tbl {
            top: 4px !important;
        }
    }

    .profile-configurator .step {
        margin-right: 2.5%;
        font-size: 13px;
    }
}

@media (max-width: 1366px) {
    .chart-title-text {
        font-size: 10px !important;
        margin-right: 5px;
    }

    .profile-configurator .header-right {
        padding: 0 0 0 10px;
    }
}

@media (max-width: 1280px) {
    .chart-title-equipment,
    .chart-title-text {
        font-size: 10px !important;
        margin-right: 5px;
    }

    .profile-configurator .step {
        margin-right: 2.7%;
        font-size: 12px;
    }

    .profile-configurator .header-right {
        padding: 0 0 0 5px;
    }
}