.sliderWrapper.equipment-slider {
    .sliderPrevBtn {
        z-index: 3;
    }
    .sliderNextBtn {
        z-index: 3;
    }
}

.equipment-photo-container {
    .click-text {
        display: none;
    }
    &:hover {
        .click-text {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 2;
            color: white;
            cursor: pointer;
        }
    }
}