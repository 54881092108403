.alert-groups-list {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 0 -10px;

    &--device-group {
        .alert-groups-select {
            width: calc(20% - 20px);
            margin: 0 10px;
        }
    }
}

.alert-groups-select {
    width: calc(25% - 20px);
    margin: 0 10px;
}

.alert-groups-select .custom-scroll,
.device-alert-groups-select .custom-scroll {
    height: 211px;
    background: #fff;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    width: auto;
    padding: 0;
}

.alert-groups-select .custom-search-input,
.device-alert-groups-select .custom-search-input {
    border: none !important;
    background: #f0f0f0;
}

.alert-groups-select .alert-no-results,
.device-alert-groups-select .device-alert-no-results {
    height: 210px;
    position: relative;
}

.alert-groups-select .alert-no-results span,
.device-alert-groups-select .device-alert-no-results span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.alert-groups .btn-visible-tree,
.alert-groups-add .btn-visible-tree {
    cursor: pointer;
    padding-right: 10px;
}

.alert-groups-add .modal-checkbox span {
    border: 1px solid #c4c4c4 !important;
}

.alert-groups-add .invalid-facilities-feedback {
    margin: 0;
}

.alert-groups-add .invalid-facilities-feedback .invalid-feedback {
    display: block;
    text-align: left;
    bottom: -22px;
    left: 25px;
}

.alert-groups .k-margin-b-20-tablet-and-mobile {
    margin-right: 20px !important;
}

.alert-groups .k-form__control {
    width: 240px;
}

.alert-groups .k-aside-secondary__content-body {
    padding: 0;
}

.alert-groups table {
    margin-bottom: 0;
}

.alert-groups thead tr {
    background: #f1f1f1;
}

.alert-groups th {
    border-bottom: 1px solid #c4c4c4 !important;
}

.alert-groups tr:last-child td {
    border-bottom: 1px solid #c4c4c4;
}

.alert-groups td,
.alert-groups th {
    border-top: 1px solid #c4c4c4;
}

.alert-groups td:first-child,
.alert-groups th:first-child {
    padding-left: 35px;
}

.alert-groups td:last-child,
.alert-groups th:last-child {
    padding-right: 35px;
}

.alert-groups .dropdown-equipment > button,
.alert-groups .dropdown-equipment > .btn-secondary.dropdown-toggle,
.alert-groups .dropdown-equipment > .btn-secondary:hover,
.alert-groups .dropdown-equipment > .btn-secondary:focus {
    background: none !important;
    color: initial;
}