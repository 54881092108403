.loader {
    position: relative;
    z-index: 999;
    top: calc(50% - 65px);
    left: calc(50% - 40px);
    width: 80px;
    svg {
        max-width: 100%;
    }
}

.loader-fullscreen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 998;
}

.loader-relative {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(248, 248, 248, 0.6);
    z-index: 80;
    &_solid {
        background: white;
    }
}

.loader-fixed {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    z-index: 998;
}

.loader-fixed .loader {
    position: fixed;
    top: calc(50% - 65px);
    left: calc(50% - 40px);
    width: 80px;
    z-index: 999;
}

.loader-relative .loader {
    position: relative;
    z-index: 81;
    top: calc(50% - 65px);
    left: calc(50% - 40px);
    width: 80px;
}

.content .loader-fullscreen .loader {
    top: calc(50% - 65px);
    left: calc(50% - 40px - 9rem);
    width: 80px;
}

.loader-relative .loader.loader-sm {
    top: calc(50% - 65px);
    left: calc(50% - 40px);
    width: 80px;
}
