.frequency-types-link {
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #676767;
  cursor: pointer;
  margin-right: 15px;
  white-space: nowrap;
}

a.link {
  &--wrap {
    white-space: normal;
  }
}

.link {
  &--label {
    margin-left: 0 !important;
    font-weight: 400;
  }
}