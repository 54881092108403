.help-modal {
    .modal-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .new-window {
            margin-right: 25px;
            font-size: 16px;
            color: #747474;
            position: absolute;
            right: 35px;
            top: 23px;
            cursor: pointer;
            &:hover {
                color: #4b4b4b;
            }
        }
    }
}