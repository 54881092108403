.global-notes-modal {
    .header {
        display: none;
    }
    .subheader-controls {
        background: #fff;
    }
    .subheader-controls {
        background: #fff;
    }
}

.global-notes-table {
    .note-textarea {
        height: 100%;
        width: 100%;
        padding: 10px;
        border: 1px solid #e2e3e6;
        border-radius: 5px;
    }
    .is-invalid {
        margin-bottom: 20px;
        .note-textarea {
            border: 1px solid red;
        }
        .invalid-feedback {
            display: block;
        }
    }
}

.notes-emails-block {
    max-height: 170px;
    overflow-y: scroll;
}
