@import "src/assets/scss/config/variables";

.nodes-ports {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 10px -10px 0;

    &-item {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &-badge {
        min-width: 1.5rem;
        border-radius: 0px;
        display: inline-block;
        line-height: 1.6rem;
        height: 1.4rem;
        margin-right: .4rem;
    }

    &-label {
        color: #212529;
        font-size: 11px;
        text-transform: uppercase;
        margin-right: 15px;
        line-height: 1.6rem;

        .dark & {
            color: map-get($theme-colors-dark, light-gray);
        }
    }
}
