.img-tooltip-wrapper {
    .img-tooltip {
        width: 22px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -11px;
        border: 1px solid #fff;
        font-size: 12px;
        &:before {
            content: "";
            position: absolute;
            top: 100%;
            margin-left: -3px;
            border-width: 3px;
            border-style: solid;
            border-color: black transparent transparent transparent;
            border-color: #fff transparent transparent transparent;
            border-width: 5px;
            left: 8px;
        }
    }
}
.img-tooltip-arrow {
    content: "";
    position: absolute;
    top: 100%;
    margin-left: -3px;
    border-width: 3px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    z-index: 1;
    top: -1px;
}

.horizontal-img .img-text-number {
    display: inline-block;
}

.img-tooltip-wrapper {
    transform: inherit;
}
