.nav-tabs.nav-tabs-line.nav.nav-tabs {
    .nav-link {
        &:hover {
            border-color: #000;
        }
    }
    .nav-link.active {
        border-color: #000;
    }
}

.nav-tabs.nav-tabs-line {
    a.nav-link {
        &:hover {
            border-color: #000;
        }
    }
    a.nav-link.active {
        border-color: #000;
    }
}

.accordion.accordion-outline {
    .card {
        .card-header {
            .card-title {
                font-size: 1.4rem;
                padding: 1.7rem;
                border-color: #2b2a35;
                border-top-width: 2px;
                border-right-width: 2px;
                border-bottom-width: 0;
                padding-bottom: calc(1.5rem + 2px);
                border-left-width: 2px;
                border-style: solid;
                border-top-left-radius: 0.65rem !important;
                border-top-right-radius: 0.65rem !important;
                color: #000;
                &:after {
                    position: absolute;
                    right: 1.25rem;
                    font-family: "LineAwesome";
                    text-decoration: inherit;
                    text-rendering: optimizeLegibility;
                    text-transform: none;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    font-smoothing: antialiased;
                    content: "";
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: #2b2a35;
                }
            }
            .card-title.collapsed {
                color: #464457;
                border-color: #efeff0;
                border-bottom-width: 2px;
                padding-bottom: 1.5rem;
                border-bottom-left-radius: 0.65rem !important;
                border-bottom-right-radius: 0.65rem !important;
                &:after {
                    content: "";
                    color: #efeff0;
                }
            }
        }
        .card-body-wrapper {
            > .card-body {
                padding: 0 2.7rem 1.7rem 1.7rem;
                font-size: 1.1rem;
                font-weight: 400;
                border-color: #2b2a35;
                border-top-width: 0;
                border-right-width: 2px;
                border-bottom-width: 2px;
                border-left-width: 2px;
                border-style: solid;
                border-bottom-left-radius: 0.65rem !important;
                border-bottom-right-radius: 0.65rem !important;
                color: #8d8c9b;
                > p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.nav-link {
    cursor: pointer !important;
}

.nav-tabs.custom-search-tabs {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    .nav-item {
        width: 20%;
        text-align: center;
        margin-right: 0;
    }

    .badge-pill {
        padding: 4px 6px;
    }
}
.nav-tabs.nav-tabs-line.nav.nav-tabs.custom-search-tabs {
    .nav-link.active {
        border-color: #31aee3;
        border-width: 2px;
    }
}

.nav-arrow {
    position: absolute;
    top: 0;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition-duration: .5s;
    z-index: 2;

    &:hover {
        cursor: pointer;
        background-color: #cccccc;
        transition-duration: .5s;
    }

    &.to-next {
        right: 0;
    }
}

.nav-tabs {
    &-profile {
        margin-bottom: 0;
        border: none;

        .nav-item {
            margin-right: 4rem;
        }

        .nav-link {
            padding: 2rem 0;
            font-weight: 600;
            font-size: 1.1rem;
            border: none;
            border-bottom: 1px solid transparent;
            transition: all 0.3s ease;
            color: #adabc1 !important;

            .dark & {
                color: #aaa !important;
            }

            &:hover,
            &.active {
                color: #23211d !important;
                border-bottom: 2px solid #000;

                .dark & {
                    color: #3b87dd !important;
                    background: transparent;
                    border-bottom: 2px solid #3b87dd;
                }
            }
        }
    }
}
