.information-plates-container {
    border-radius: 4px;
    margin: 10px 0;
    width: calc(100% + 60px);
    position: relative;
    left: -30px;

    .add-new-link {
        margin-bottom: 0 !important;
        margin-left: 10px;
        color: #449aff;
        cursor: pointer;
        text-decoration: underline;
        i.fa {
            margin-right: 5px;
        }
    }
    .plate-name {
        height: 32px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
    .buttons-row {
        border-top: 1px solid #d9d9d9;
    }
}

.create-equipment-content {
    width: 70%;
    margin: 0 auto;

    .information-plates-container {
        width: 100%;
        position: relative;
        left: 0;
        margin: 0;
    }
}

.header-info-plates {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    padding: 10px 30px;
    .title-info-plates {
        font-size: 14px;
        font-weight: bold;
    }
}

.information-plates-table-container {
    margin-top: 10px;
    padding: 10px 30px;
    background: #f3f3f3;
    .params-table {
        border: 1px solid #d9d9d9;
        border-bottom: none;
        > div {
            padding: 7px;
            width: 50%;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
    }
    .params-row {
        &:last-child {
            .params-table {
                border-bottom: 1px solid #d9d9d9;
            }
        }
    }
}
.info-plate-table-title {
    font-weight: bold;
}
.info-plate-state {
    background: #d9d9d9;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.info-plate-state.active {
    background: #449aff;
    color: white;
}
.info-plate-state.select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.info-plate-state.new {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.edit-installation-point-modal {
    .information-plates-container {
        .add-new-link {
            position: unset;
        }
    }
    .add-new-link {
        color: #449aff;
        text-decoration-line: underline;
        font-size: 12px;
        cursor: pointer;
        line-height: 14px;
        margin-bottom: 1rem;
        position: relative;
        bottom: 10px;
        &:hover {
            text-decoration-line: none;
        }
    }
    .form-group {
        margin-bottom: 1rem;
    }
}
.info-plate-button {
    border: 1px solid #c4c4c4;
    height: 32px;
    font-size: 17px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    color: #449aff;
    border-radius: 4px;
    background: #f3f3f3;
}