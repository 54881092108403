.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: calc(100vh - 140px);
}

.error-page-image.error-page-new-image {
    width: 100%;
}
.error-page .error-page-content .error-page-new-title {
    font-weight: 900;
    font-size: 144px;
    line-height: 144px;
    color: #eacc64;
    text-align: center;
    margin: 8px 0;
}

.error-page .error-page-content .error-page-new-desc {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #676767;
    margin: 8px 0;
    text-align: center;
}

.error-page .error-page-content .error-page-sub-desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #4b4b4b;
    text-align: center;
    margin: 8px 0;
}

.buttons-block {
    .btn {
        max-width: 156px;
        width: 100%;
    }
}

.error-page-content .error-page-title {
    font-size: 12rem;
    color: #fb2f73;
    font-weight: 700;
}

.error-page-content .error-page-desc {
    font-size: 1.5rem;
}

.error-page-image {
    width: 50%;
    position: relative;
}
.error-page-btns {
    text-align: center;
    margin-top: 30px;
}
.switch-customer-btn {
    margin-bottom: 15px;
}
.error-page-content .not-permission-title {
    font-size: 8rem;
 }