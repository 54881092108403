.clickableLink {
    color: #449aff;
    cursor: pointer;
}

.alert {
    width: 600px !important;
}

.img {
    width: 600px;
    height: 330px;
}
.label {
    display: inline-block;
    margin: 0 4px;
}
