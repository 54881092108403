.react-contextmenu {
    background: #ffffff;
    z-index: 999;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
}
.react-contextmenu-item {
    margin: 10px 15px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #4b4b4b;

    &--divider {
        border-top: 1px solid #ccc;
    }
    &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &:hover {
        color: #5c4de5;
    }
}
