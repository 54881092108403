%btn-small {
    padding: 4px 8px;
}

.asset-collapse-item {
    width: 100%;
    padding: 6px 10px;
    border: rgb(196, 196, 196) 1px solid;
    margin-bottom: 15px;
    border-radius: 4px;
    background: white;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    &__body {
        width: 100%;
        background: hsl(0, 0%, 90%);
        border-radius: 4px;
        padding: 6px;
        margin-top: 10px;
    }

    &__controls {
        .btn {
            @extend %btn-small;
        }
    }
}

.equipment-collapse {
    padding: 4px;
    border-radius: 4px;
    background: white;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__controls {
        .btn {
            @extend %btn-small;
        }
    }

    &__select-wrapper {
        width: 100%;
    }

    &__select {
        width: 100%;
        //height: 20px;
    }
}
