.checkbox {
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #4b4b4b;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    display: inline;
    min-height: 18px;
    margin-bottom: 0;

    input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
        left: 0;
        &:checked {
            ~ span::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -2px;
                margin-top: -7px;
                width: 5px;
                height: 10px;
                border-width: 0 2px 2px 0 !important;
                border: solid #fff;
                transform: rotate(45deg);
            }
        }
    }

    > span {
        border: 2px solid #939393;
        background: none;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
    }
}