.date-picker {
    position: relative;
    width: 100%;
    z-index: 2;
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        top: 0px;
        left: 0;
        z-index: 1;
        height: 1px;
        background: #c4c4c4;
    }
}

.date-picker-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 2px;
    padding: 0.8rem 0.4rem 0.3rem;
    position: relative;
    color: #9e9e9e;
    font-size: 0.9rem;
    line-height: 1.1rem;
    cursor: pointer;
    z-index: 2;
    &:hover {
        color: #31aee3;
    }
    &.current {
        font-weight: bold;
        color: #31aee3;
        &:after {
            position: absolute;
            content: '';
            top: 0px;
            left: 0;
            width: 100%;
            height: 3px;
            background: #31aee3;
        }
    }
}

.datetimepicker {
    .switch {
        display: table-cell;
    }
}

.datepicker {
    tbody {
        tr {
            > td.day.disabled {
                opacity: 0.2;
            }
        }
    }

    &.datepicker-inline {
        width: 100%;
    }
}

.react-datepicker {
    font-family: "Roboto";
}
.react-datepicker-popper {
    z-index: 100 !important;
}

.modal-open .react-datepicker-popper {
    z-index: 9999 !important;
}

.rdtPicker {
    position: fixed !important;
    min-width: 215px !important;
    border-radius: 0.3rem;
}

.rdtTime {
    .rdtBtn {
        display: inline-flex;
        font: normal normal normal 16px/1 "LineAwesome";
        font-size: inherit;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        width: 40px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        &:first-child {
            font-size: 0;

            &::before {
                font-size: 20px;
                content: "\f113";
            }
        }
        &:last-child {
            font-size: 0;
            &::before {
                font-size: 20px;
                content: "\f110";
            }
        }
    }
    .rdtCounter {
        width: 54px;
    }
}

.react-datepicker-popper[data-placement^=bottom] {
    .react-datepicker__triangle {
        top: 2px !important;
        margin-left: -16px !important;
        margin-top: -8px !important;
    }
}

.react-datepicker__month {
    font-weight: 500;
}
