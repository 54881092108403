.sliderWrapper {
    position: relative;
    margin-bottom: 10px;

    .sliderPrevBtn {
        position: absolute;
        height: 100%;
        width: auto;
        color: #fff;
        background: #21201994;
        padding: 106px 7px 0;
        cursor: pointer;
        z-index: 2;
        left: 0;
    }

    .sliderNextBtn {
        position: absolute;
        height: 100%;
        width: auto;
        color: #fff;
        background: #21201994;
        padding: 106px 7px 0;
        cursor: pointer;
        z-index: 2;
        right: 0;
    }

    .previews-photos {
        height: 187px;
    }

    .slide-item {
        line-height: 0;
        max-height: 187px;
        max-width: 100%;
        text-align: center;
    }

    .divider {
        margin-top: 10px;
    }
}