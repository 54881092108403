.harmonic-dots-container {
    max-height: 125px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0.25rem;
        border-radius: 0.125rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.125rem;
        background-color: grey;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0.125rem;
        background-color: #d3d3d3;
    }
}
