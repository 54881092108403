.subheader {
    position: sticky;
    top: 83px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #f3f3f3;
    padding-bottom: 1.5rem;
    padding-top: .4rem;
    z-index: 10;
}

.subheaderDark{
    background: #413f38;
}