.modal {
    &-header {
        padding: 1rem;
        overflow: hidden;
        position: relative;
        padding: 1.5rem 2.2rem;
        padding: 2rem 2.2rem 1.4rem;
        border-bottom: none;

        .close {
            position: absolute;
            top: -10px;
            right: -5px;
            margin: 0;
            top: -2px;
            right: -5px;
            padding: 1rem;
            right: 1rem;
            top: 0.55rem;
        }

        .modal-title {
            font-weight: bold;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.1em;
            color: #4b4b4b;
            text-transform: uppercase;
        }
    }

    &-body {
        padding: 1rem;
        position: relative;
        max-height: calc(100vh - 100px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1rem 2.2rem;
    }

    &-footer {
        padding: 1rem;
        position: relative;
        justify-content: space-between;
        padding: 1rem 2.2rem;
        justify-content: flex-end;
    }

    &-open {
        .modal {
            .chart-block {
                padding: 10px;
            }
            .chart-block-header {
                margin-bottom: 10px;
                &_in-detection {
                    margin-bottom: 0;
                }
            }
            .chart-block-title {
                line-height: 15px;
            }
            .chart-title-equipment {
                line-height: 15px;
            }
        }
    }

    .action-item-modal {
        .view-action-item.loader {
            min-height: calc(100vh - 6.5rem);
            position: relative;
        }
    }

    .modal-content {
        .modal-header {
            .modal-title {
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 0.1em;
                color: #4b4b4b;
                text-transform: uppercase;
                margin-right: 25px;
            }
            .close {
                &:before {
                    font-size: 1.8rem;
                }
            }
        }
        min-height: 300px;
    }

    .user_guide-modal {
        .modal-content {
            min-height: auto;
        }
    }

    .faq-modal {
        .modal-content {
            max-height: 70vh;
        }
    }

    .modal-dialog.custom-modal {
        .form-group {
            label {
                &:not(.modal-checkbox) {
                    font-weight: bold;
                    font-size: 12px;
                    letter-spacing: 0.02em;
                    color: #4b4b4b;
                }
            }
            label.modal-checkbox {
                font-weight: normal;
                font-size: 12px;
            }
            label.rms-od-checkbox {
                font-weight: normal;
            }
            label.modal-checkbox.gray-text {
                color: #9e9e9e;
            }
        }
        .modal-header {
            .modal-title {
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 0.1em;
                color: #4b4b4b;
                text-transform: uppercase;
            }
            padding-top: 43px;
            border-bottom: none;
            padding-left: 48px;
            padding-right: 30px;
            padding: 1.5rem 2.2rem;
            padding: 2rem 2.2rem 1.4rem;
            border-bottom: none;
        }
        .modal-body {
            padding-left: 48px;
            padding-right: 30px;
            padding: 1rem 2.2rem;
            padding-top: 0;

            .secondary-text {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.02em;
                color: #9e9e9e;
            }
        }
        .modal-footer {
            padding-left: 48px;
            padding-right: 30px;
            display: flex;
            justify-content: flex-end;
            padding: 1rem 2.2rem;
        }
    }
}

//modal size
.modal-xl {
    max-width: 1350px;
    width: 95%;
}

.modal-full-screen {
    max-width: 98%;

    .modal-body {
        max-height: calc(100vh - 100px);
    }
}

.full-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
}

//overlay

.modal-overlay-progress {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #6b686857;
    z-index: 1000;
}

.modal-overlay-progress-content {
    display: block;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 40%;

    .modal-overlay-loading-text {
        display: block;
        font-size: 21px;
    }
}

.custom-close-btn {
    font-family: "LineAwesome";
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    transition: all 0.3s;
    outline: none !important;

    &:before {
        content: "";
        font-size: 2rem;
    }
}

.overlay-modal {
    .custom-scroll.dropdown-menu {
        overflow-y: auto;
        max-height: 220px;
        min-width: 100%;
    }
}

.pseudo-modal-header {
    flex-wrap: nowrap;
    align-items: center;
    justify-items: center;
    margin: 25px 0;

    .right-modal-title-block {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-items: center;
        justify-content: space-between;

        .search-field {
            width: 90%;
        }
    }
}

.modal-dialog.bearing {
    .search-field {
        .k-input-icon__icon {
            height: 30px;
            margin: 1px;
        }
    }
    .selected-bearings-title {
        margin: 5px 0 10px 10px;
    }
}

.modal {
    &-manage-alert {
        label.form-checkbox {
            font-weight: 400 !important;
            padding-left: 22px;
            margin-right: 10px;
            margin-bottom: 2px;
        }

        .title-group-delta {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 700;
            color: #ccc;
            text-transform: uppercase;
        }
    }
}

.sweet-alert-over-modal {
    div {
        div {
            overflow: unset !important;
        }
    }
}

.confirm-message {
    &.single-line {
        white-space: normal;
    }

    &.breaking-line {
        white-space: pre-line;
    }
}

.edit-installation-point-modal .modal-content {
    min-height: 370px;
}
