.custom-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 6rem);
    padding-right: 5px;
    scrollbar-color: #c4c5c4 #dadada;
    scrollbar-width: thin;
    padding-left: 5px;

    &.modal-body {
        height: auto;
    }

    &.custom-scroll-equipment {
        height: calc(100vh - 7rem);
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
    }
}

.custom-scroll-tree {
    .ReactVirtualized__Grid {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 6rem);
        padding-right: 5px;
        scrollbar-color: #c4c5c4 #dadada;
        scrollbar-width: thin;
        padding-left: 5px;
        
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #c4c4c4;
        }
    }
}

.custom-scroll-aside {
    height: calc(100vh - 26rem);
}
