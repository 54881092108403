html {
    overflow: visible !important;
}

body {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #efefef;
    overflow-x: hidden;
    background: #f3f3f3;
}

hr {
    width: 100%;
}

img {
    max-width: 100%;
}

.img-fluid {
    margin-bottom: 10px;
}

mark {
    padding: 0.2em 0px;
    background: #ff0;
}

ul {
    padding-left: 0;
}

select option {
    padding: 0;
}

.row {
    margin-right: -5px;
    margin-left: -5px;

    &-row {
        padding: 5px 0;
        border-bottom: 1px solid #ececec;
        transition: all 0.2s ease;

        &:hover {
            background: #f6f6f6;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.col,
[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.fa-spinner {
    animation: spin 1s infinite linear;
    padding-right: 0 !important;
    margin-right: 0.35rem;
}

.app {

    &-grid {
        display: block;
        z-index: 1;
    }

    &-content {
        position: relative;
        width: 100%;
    }

    &-main {
        padding-left: 18.3rem;
        padding-right: 1.5rem;
        padding-bottom: 1rem;
        box-sizing: border-box;
        transition: all 0.3s ease;

        .loader-fullscreen {
            left: 17rem;
            top: 0;
            width: calc(100% - 17rem);

            .hide-side & {
                left: 5rem;
                width: calc(100% - 5rem);
            }
        }
    }
}

#root-portal {
    z-index: 9999;
}

.wrapper-logo-sidebar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .logo-main {
        vertical-align: middle;
    }

    .ukr-link {
        display: inline-block;
        width: 23px;
        height: 20px;
    }
}

.hide-side {
    .ukr-link {
        display: none;
    }
}