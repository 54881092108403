.profiles-page.loading {
  min-height: calc(100vh - 10rem);
  position: relative;
  display: flex;
  align-items: center;
}

.profile-list {
  min-height: calc(100vh - 12rem);
}

.profile-types-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.profile-modal-body {
  padding: 0.5rem 2.2rem 0.5rem;
}

.profile-actions {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 16px;
  cursor: pointer;

  img {
    width: 16px !important;
  }

  .link {
    display: flex;
    margin-bottom: 5px;

    span {
      font-size: 14px;
      text-decoration: none;
      margin-left: 6px;
      line-height: 16px;
    }
  }

  .setting-icon-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 50%;

    &:hover {
      background: #f3f3f3;
    }

    img {
      display: flex;
      margin: auto;
    }

    svg {
      display: flex;
      margin: auto;
      stroke: #666;

      .dark & {
        stroke: #fff !important;
      }
    }
  }

  .dropdown-menu {
    border: 1px solid #cecece !important;
    top: -35px !important;
    box-shadow: 5px 11px 16px 3px rgba(0, 0, 0, 0.31);
  }
}

.profile-type {
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  margin: 0.5%;
  max-width: 19%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  color: #4b4b4b;
  position: relative;

  .description {
    font-size: 14px;
  }

  img {
    width: 72px;
  }

  > .profile-type-modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    color: #4b4b4b;
    padding: 25px;
    word-break: break-word;
  }

  &:not(.active) {
    &:hover {
      background: #fff;
      cursor: pointer;
      -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.4);
      box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.4);
    }
  }

  .type {
    font-weight: bold;
    font-size: 20px;
    word-break: break-word;
  }
}

.profile-type.active {
  background: #449aff;
  color: #fff;
  cursor: pointer;
  box-shadow: inset 3px 3px 21px -3px rgba(0, 0, 0, 0.43);
}

.profile-type.profile-list-block {
  background: #fff;

  .dark & {
    background: #413f39;
    color: #fff;
  }

  .draft {
    border-left: 5px solid #b1b1b1;
  }

}

.profile-type-modal {
  border-left: 5px solid #6ac180;
}

.profile-type-modal.draft {
  border-left: 5px solid #b1b1b1;
}

.profile-configurator-header {
  display: flex;
}

.profile-configurator.loading {
  position: relative;

  .loader {
    position: absolute;
  }
}

.cust-btn {
  padding: 12px 10px 10px;
  background: #4aa261;
  color: white;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    background: #6ac180;
    color: white;
  }
}

.cust-btn.disabled {
  background: #a4a4ad !important;
  color: white;
  pointer-events: none;
}

.profile-types-modal {
  background: white;
  margin: 0 50px;
  border-radius: 4px;
  -webkit-box-shadow: 5px 11px 16px 3px rgba(0, 0, 0, 0.31);
  box-shadow: 5px 11px 16px 3px rgba(0, 0, 0, 0.31);

  .profile-modal-body {
    min-height: calc(100vh - 32rem);
  }
}

.profile-modal-body {
  .dropzone-thumb-item {
    width: 50%;
  }

  .dropzone-thumb-img {
    background-size: 100% !important;
  }

  .controls-button-alerts-level {
    margin-top: 10px;
  }
}

.profile-alerts-modal {
  color: #4b4b4b;

  .form-group {
    margin-bottom: 1rem;
  }

  label {
    font-weight: bold;
  }

  .profile-modal-bottom {
    justify-content: space-between;
  }

  .alert-level {
    border-radius: 1.2em;
    min-height: 24px;
    min-width: 54px;
    padding: 0.6em 0.75em 0.5em;
    text-align: center;
    margin: 0 0.25rem;
    font-size: 85%;
    line-height: 1;
    color: #fff;
  }

  .form-control.valid {
    background: #fff url("../../../pic/icon-check.svg") no-repeat 98% center !important;
    border: 1px solid #4aa261;

    &:focus {
      background: none !important;
      border: 1px solid #c4c4c4;
    }
  }

  .clear-alert {
    cursor: pointer;
    color: #b1b1b1;
  }

  .input-group-text {
    background: #f3f3f3;
  }
}

.profile-alerts-modal.create-equipment {
  max-width: 370px;
}

.create-equipment {
  &--associated {

    .modal-content {
      min-height: inherit !important;
    }


  }
}

.profile-modal-bottom {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2.2rem 1.5rem;

  .btn {
    // min-width: 192px;
    // max-width: 192px;
    width: 100%;
  }

  // .cust-btn {
  //     min-width: calc(50% - 10px);
  // }
}

.profile-modal-header {
  padding: 2rem 2.2rem 0.5rem;
  color: #4b4b4b;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
}

.profile-configurator {
  .close {
    font-size: 2.2rem;
    font-weight: normal;
  }

  padding: 20px;
  color: #4b4b4b;
  min-height: calc(100vh - 200px);

  .header-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    align-items: stretch;
    justify-content: space-between;
    margin-left: 0;
  }

  .steps {
    display: flex;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }

  .type-icon {
    background: #f3f3f3;
    padding: 18px;

    svg {
      fill: #000;

      .dark & {
        fill: #fff !important;
      }
    }
  }

  .step {
    border: 1px solid #878787;
    color: #878787;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    width: 23.5%;
    margin-right: 2%;
    border-radius: 5px;
    padding-left: 15px;
    cursor: pointer;

    &:hover {
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .step.disabled {
    pointer-events: none;
  }

  .step.active {
    border: 1px solid #449aff;
    color: #449aff;
    background: #ecf5ff;
  }

  .step.active.done {
    color: #fff;
  }

  .step.done {
    border: 1px solid #449aff;
    color: #005fce;
    background: #449aff;
  }

  .step.first {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .step.middle {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .step.last {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .step-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .column-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  hr {
    margin: 20px 0;
  }

  .form-group {
    label {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.02em;
      color: #4b4b4b;
    }

    margin-bottom: 0;
    margin-top: 1rem;
  }

  .add-new-block {
    text-align: center;
    padding: 6rem 0;
    color: #878787;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    width: 31%;
    margin-bottom: 20px;
    border-radius: 5px;

    i {
      margin-right: 5px;
      color: #449aff;
    }

    &:hover {
      cursor: pointer;
      color: #4b4b4b;
      background: #ecf5ff;
    }

    img {
      margin-right: 10px;
    }
  }

  .add-new-block.bearing {
    min-height: 400px;
    display: flex;

    > div {
      display: flex;
      margin: auto;
    }

    img {
      margin-right: 0;
      width: 24px;
      height: 24px;
    }
  }

  .step-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .input-validation-wrap.valid {
    position: relative;

    .form-control {
      border: 1px solid #4aa261;

      &:focus {
        border: 1px solid #c4c4c4;

        ~ {
          .fa-check {
            display: none;
          }
        }
      }
    }

    .fa-check {
      display: block;
      position: absolute;
      top: 35px;
      right: 0;
      line-height: 14px;
      color: #4aa261;
      font-size: 14px;
    }
  }

  .input-validation-wrap {
    .fa-check {
      display: none;
    }
  }

  .form-control.valid {
    background: #fff url("../../../pic/icon-check.svg") no-repeat 98% center !important;
    border: 1px solid #4aa261;

    &:before {
      content: '';
      display: block;
      width: 3px;
      height: 6px;
      border: solid #4aa261;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:focus {
      background: none !important;
      border: 1px solid #c4c4c4;
    }
  }

  .alert-level {
    border-radius: 1.2em;
    min-height: 24px;
    min-width: 54px;
    padding: 0.6em 0.75em 0.5em;
    text-align: center;
    margin: 0 0.25rem;
    font-size: 85%;
    line-height: 1;
    color: #fff;
  }

  select.form-control.valid {
    background: #fff url("../../../pic/icon-check.svg") no-repeat 95% center !important;
  }

  .alert-warning {
    background-color: #ea3c3c;
    color: #fff;
  }

  .alert-caution {
    background: #e3bb31;
    color: #fff;
  }

  .alert-caution.empty {
    background: #fcf8ea;
    color: #e3bb31;
    border: 1px solid #e3bb31;
  }

  .alert-warning.empty {
    background: #ea3c3c;
    color: #ea3c3c;
    border: 1px solid #ea3c3c;
  }

  .alert-axis {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }

  .config-alert {
    display: inline-block;
    color: #449aff;
    margin-bottom: 5px;
    font-size: 13px;
    cursor: pointer;

    > span {
      margin-left: 5px;
      text-decoration: underline;
    }
  }

  .axis-name {
    font-size: 14px;
    font-weight: bold;
  }

  .alerts-table {
    th {
      border: none;
      background: #fafafa;
    }
  }

  table.alerts-table {
    td {
      vertical-align: top;
    }
  }
}

.step {
  .triangle.right {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;

    .inner {
      transform: rotate(45deg);
      width: 31px;
      height: 32px;
      top: 4px;
      left: -16px;
      position: relative;
      border-radius: 5px;
      border: 1px solid #878787;
    }

    .outer {
      position: absolute;
      top: -1px;
      left: -2px;
      width: 30px;
      height: 41px;
      overflow: hidden;
    }
  }

  &:hover {
    .triangle.right {
      .inner {
        border: 1px solid #4b4b4b;
      }
    }

    .triangle.left {
      .inner {
        border: 1px solid #4b4b4b;
      }
    }
  }

  .triangle.left {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    .inner {
      transform: rotate(45deg);
      width: 30px;
      height: 30px;
      top: 4px;
      left: -16px;
      position: relative;
      border-radius: 5px;
      border: 1px solid #878787;
    }

    .outer {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 30px;
      height: 41px;
      overflow: hidden;
    }
  }
}

.step.active {
  .triangle.right {
    .inner {
      border: 1px solid #449aff;
      background: #ecf5ff;
    }
  }

  &:hover {
    .triangle.right {
      .inner {
        border: 1px solid #449aff;
      }
    }

    .triangle.left {
      .inner {
        border: 1px solid #449aff;
      }
    }
  }

  .triangle.left {
    .inner {
      border: 1px solid #449aff;
      background: white;
    }
  }
}

.step.done {
  .triangle.right {
    .inner {
      border: 1px solid #449aff;
      background: #449aff;
    }
  }

  &:hover {
    .triangle.right {
      .inner {
        border: 1px solid #449aff;
      }
    }

    .triangle.left {
      .inner {
        border: 1px solid #449aff;
      }
    }
  }

  .triangle.left {
    .inner {
      border: 1px solid #449aff;
      background: white;
    }
  }
}

.is-invalid {
  .form-control-select {
    > div {
      border-color: #ea3c3c;
    }
  }
}

.is-invalid.form-control-select {
  > div {
    border-color: #ea3c3c;
  }
}

.valid {
  .form-control-select {
    > div {
      border-color: #4aa261;

      > div {
        &:first-child {
          background: #fff url("../../../pic/icon-check.svg") no-repeat 98% center !important;
          padding: 0 8px !important;
        }
      }
    }
  }
}

.valid.form-control-select {
  > div {
    border-color: #4aa261;

    > div {
      &:first-child {
        background: #fff url("../../../pic/icon-check.svg") no-repeat 98% center !important;
        padding: 0 8px !important;
      }
    }
  }
}

.installation-point-wrapper {
  margin-bottom: 20px;
  padding: 1rem 2rem 2rem;
  border: 1px solid #c1c1c1;
  position: relative;
  border-radius: 5px;
  width: 31%;
  margin-right: 3.5%;

  .remove {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .form-group {
      width: 100% !important;
    }
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.bearing-wrapper {
  margin-bottom: 20px;
  padding: 1rem 2rem 2rem;
  border: 1px solid #c1c1c1;
  position: relative;
  border-radius: 5px;
  width: 31%;
  margin-right: 3.5%;

  .remove {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.general-step {
  > div {
    padding: 0 2rem;

    &:first-child {
      padding-left: 5px;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.inst-points-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.bearings-block {
  display: flex;
  flex-wrap: wrap;
}

.profile-inst-point-type.form-group {
  margin-top: 0;
}

.profile-inst-point-type {
  label {
    display: none;
  }
}

.create-profile-page {
  .k-portlet {
    margin-bottom: 20px;
  }
}

.blocks-chart-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  > span {
    max-width: calc(50% - 6px);
    min-width: calc(50% - 6px);
  }

  .chart-actions-wrapper {
    clear: both;
    margin-top: 0 !important;
  }

  .chart-block {
    height: 100%;
  }

  .chart-tooltip {
    display: flex;
    flex-direction: column;
    font-size: 11px;

    br {
      display: none;
    }
  }

  .chart-block-title {
    width: 100%;
  }

  .chart-block .btn-group-sm {
    vertical-align: middle;
    margin-right: 5px;
  }

  .chart-title-text {
    margin-top: 0;
  }

  .chart-block-actions {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .chart-actions-right {
    width: 100%;
    margin-top: 5px;
  }

  .chart-block .alert-link {
    margin-right: 10px;
  }
}

.wrapper-equipment {
  position: relative;
  padding-bottom: 40px;
  max-height: calc(100% - 40px);
  overflow-y: scroll;
}

.controls-btns-equipment {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 20px 0 20px 0;
  padding-right: calc(1.5rem + 15px);
  background: #fff;
  z-index: 2;

  &.sm-buttons {
    height: 72px;
  }

  .dark & {
    background: #35342a;
  }
}

.full-view-modal {
  .profile-list-block {
    max-width: 100%;
    border: none;
    background: none;

    &:not(.active) {
      &:hover {
        box-shadow: none;
        cursor: auto;
        border-left: none;
      }
    }
  }

  .profile-modal-body {
    padding-bottom: 2.2rem;
  }

  .subheader-title {
    background: #fff;
  }

  .modal-installation-point {
    border-bottom: 1px solid #c1c1c1;
    padding: 10px;
    position: relative;
  }

  .modal-installation-dropdown {
    cursor: pointer;

    &:after {
      text-align: center;
      display: inline;
      border: 0;
      font-family: "LineAwesome";
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      content: "";
      font-size: 1.5rem;
      line-height: 0;
      vertical-align: middle;
      position: absolute;
      top: 26px;
      right: 10px;
    }

    &.top {
      &:after {
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}

.wrapper-equipment-compare {
  .alert-level {
    border-radius: 1.2em;
    min-height: 24px;
    min-width: 85px;
    padding: 0.6em 0.75em 0.5em;
    text-align: center;
    margin: 0 0.25rem;
    font-size: 85%;
    line-height: 1;
    color: #fff;
    display: inline-block;
  }

  .inst-point-block {
    width: 49%;
    border: 1px solid #d2d2d2;
    background: #f9f9f9;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;

    .profile-modal-body & {
      width: 98%;
    }
  }

  .bearing-block {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    background: white;
    margin-bottom: 10px;
    min-height: 37px;

    & > div {
      margin: 0 10px;
    }
  }

  .alert-type-block {
    width: 45%;
    margin-bottom: 20px;
  }

  .will-create {
    background: #e4ffe4;
  }

  .will-change {
    background: #def7ff;
  }

  .will-delete {
    background: #ffe1e1;
  }

  .nodes-ports {
    margin: 0 20px 0;

    .nodes-ports-item {
      width: auto;
    }
  }
}

.profile-modal-body {
  .general-block {
    display: flex;
    justify-content: space-between;

    .part {
      &:nth-child(2) {
        width: 33%;
      }
    }
  }
}

.custom-compare {
  padding-bottom: 50px;

  .new {
    .basic-value {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 5px;
        right: -15px;
        width: 10px;
        height: 10px;
        background: #69c46d;
        border-radius: 50%;
      }
    }

    &.profile {
      position: relative;

      &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f060";
        font-size: 15px;
        position: absolute;
        color: #69c46d;
        top: 3px;
        left: -27px;
        width: 10px;
        height: 10px;
        font-weight: 900;
      }
    }

  }

  .subheader-title {
    background: #fff;
  }
}

.expl-box {
  width: 25px;
  height: 25px;
  border: 1px solid #000;
  background: #69c46d;

  &.custom-caution {
    background: rgb(252, 248, 234);
    color: rgb(240, 173, 78);
    border: 1px solid rgb(240, 173, 78);
  }

  &.custom-warning {
    background: rgb(253, 236, 236);
    color: rgb(217, 83, 79);
    border: 1px solid rgb(217, 83, 79);
  }

}

.custom-btn-pad {
  padding-bottom: 70px;
}

.install-point-value.new {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    right: -8px;
    width: 7px;
    height: 7px;
    background: #69c46d;
    border-radius: 50%;
  }
}

.alert-level.new {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    right: -8px;
    width: 7px;
    height: 7px;
    background: #69c46d;
    border-radius: 50%;
  }
}

@media (max-width: 1600px) {
  .blocks-chart-wrapper {
    .chart-tooltip {
      max-height: 150px;
      max-width: 300px;
      overflow: auto;
      scrollbar-color: #c4c5c4 #dadada;
      scrollbar-width: thin;
      padding-left: 5px;
    }
  }
}