.eq-item {
    padding-top: 54%;
    position: relative;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    .eq-photo {
        background-color: #fff;
    }
}
.eq-item-wrapp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .badge._badge.badge-warning {
        cursor: pointer;
    }
}
.eq-left {
    position: relative;
    width: 44%;
    float: left;
    line-height: 0;
    height: 100%;
    a {
        height: 100%;
        display: block;
        line-height: 0;
        overflow: hidden;
    }
}
.eq-photo {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    img {
        min-width: 100%;
        height: 100%;
        max-width: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.eq-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 22%;
    background: rgba(33, 32, 26, 0.55);
    z-index: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 1.2rem 0px;
    text-align: center;
    height: 100%;
}
.eq-messages {
    display: inline-block;
    position: relative;
    margin-bottom: 1.5rem;
}
.eq-info {
    display: inline-block;
    position: relative;
    margin-bottom: 1.5rem;
}
.eq-messages-count {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 1rem;
    width: 1rem;
    text-align: center;
    line-height: 1rem;
    background: #ea3c3c;
    color: #fff;
    border-radius: 1rem;
    font-size: 0.6rem;
}
.eq-info-count {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 1rem;
    width: 1rem;
    text-align: center;
    line-height: 1rem;
    background: #ea3c3c;
    color: #fff;
    border-radius: 1rem;
    font-size: 0.6rem;
}
.eq-right {
    position: relative;
    width: 56%;
    float: right;
    background: #fff;
    height: 100%;
}
.eq-name {
    padding: 0 0.9rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #f0f0f0;
    color: #333;
    font-size: 0.9rem;
    line-height: 2.5rem;
    font-weight: bold;
    span.eq-name-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 85%;
        display: block;
    }

}
.eq-icon {
    float: right;
}
.eq-information {
    padding: 0.8rem 0.9rem;
}
.eq-line {
    font-size: 0.9rem;
    line-height: 1.1rem;
    margin-bottom: 1rem;
    color: #333;
    img {
        margin-right: 0.5rem;
    }
}
.equipment-table {
    color: #6a6a6a;
    background: #fff;
    thead {
        th {
            background: #fafafa;
            color: #21201a;
            border-bottom: 1px solid #c4c4c4;
        }
    }
    td {
        border-bottom: 1px solid #c4c4c4;
    }
    tr.info-row {
        td {
            border-top: none;
            position: relative;
        }
    }
    tr.head-row {
        td {
            border-bottom: none;
        }
        td.equipment-name {
            cursor: pointer;
        }
    }
    tr.info-row.collapse.show {
        border-left: 3px solid #c4c4c4;
    }
    tr.head-row[aria-expanded=true] {
        border-left: 3px solid #c4c4c4;
    }
    .dropdown-toggle {
        height: auto;
        border: none;
    }
    .level-point {
        margin-right: 0.5rem;
    }

    .color-gray {
        .dark & {
            color: #ccc;
        }
    }
}
.toggle-equipment-row {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
    cursor: pointer;
}
.eq-name-aside {
    text-transform: uppercase;
}
.equipment-content {
    font-size: 1.1rem;
    line-height: 1.8rem;
    color: #4b4b4b;
}

