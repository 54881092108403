.breadcrumbs-wrapper {
}

.breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__item {
        display: flex;
        align-items: center;
        vertical-align: middle;
        color: #626161;
    }
    &__item,
    &__arrow {
        margin-right: 5px;
    }

    a,
    path {
        color: #626161;
        fill: #626161;
        &:hover {
            text-decoration: underline !important;
        }
    }

    &__delimiter {
        width: 100%;
        height: 1px;
        background: #f3f3f3;
        &_dark {
            background: #363636;
        }
    }

}
