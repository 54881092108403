.dot{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fdc526;
}

.invalid{
    display: block;
}

.formGroupMargin{
    margin-bottom: 34px;
}