.dropdown-item {
    cursor: pointer;
    align-items: center;
    gap: 5px;
    &:disabled {
        color: #5c4de5;
        cursor: default;
    }
    &:hover {
        background: none;
    }
    &:focus {
        background: none;
    }
    &.disabled {
        color: #5c4de5;
        cursor: default;
        background: #ccc;
        span{
            color: #fff !important;
        }
    }
}

.dropdown-menu {
    padding: 0;
    > li {
        > a {
            padding: 0 1rem;
            line-height: 2rem;
        }
    }
    > .dropdown-item {
        &:not(.bearing-dropdown-item) {
            padding: 0 1rem;
            line-height: 2rem;
        }

        &:hover {
            color: map-get($theme-colors, primary) !important;

            i {
                color: map-get($theme-colors, primary) !important;
            }
        }
    }
    .k-checkbox {
        margin-bottom: 6px;
    }
}

.dropdown-menu.show {
    padding: 1rem 0;
}

.dropdown-menu.notification-dropdown {
    box-shadow: rgb(136, 136, 136) 3px 3px 10px;
    padding: 5px 0px;
    border: 1px solid rgb(153, 153, 153) !important;
    min-width: auto;
    .dropdown-item {
        cursor: pointer;
        padding: 0.5em 1em;
        color: rgb(51, 51, 51);
        background: none;
        transition: background 250ms ease 0s, color 250ms ease 0s;
        &:hover {
            color: rgb(255, 255, 255);
            background: rgb(51, 92, 173);
        }
    }
}

.series-menu.dropdown {
    .dropdown-divider {
        margin: 1px 0 6px 0px;
    }
}

.dropdown-equipment {
    > button {
        display: block;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 1.5rem !important;
        background: #31aee3 !important;
        padding: 0 7px !important;
        line-height: 1.5rem !important;
    }
    > .btn-secondary.dropdown-toggle {
        display: block;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 1.5rem !important;
        background: #31aee3 !important;
        padding: 0 7px !important;
        line-height: 1.5rem !important;
    }
    > .btn-secondary {
        &:focus {
            display: block;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 1.5rem !important;
            background: #31aee3 !important;
            padding: 0 7px !important;
            line-height: 1.5rem !important;
        }
    }
    .dropdown-menu {
        min-width: auto;
        padding: 5px 0;
        box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
        button {
            width: 100%;
            margin: 5px 0;
            background: #ffffff;
            color: #212529;
            line-height: 0.9rem !important;
            .fa {
                font-size: 0.9rem !important;
            }
        }
    }
    &:hover {
        > button {
            background: #31aee3 !important;
            opacity: 0.8;
        }
    }
    &.show {
        > button {
            background: #31aee3 !important;
            opacity: 0.8;
        }
    }
}

table {
    .dropdown-equipment {
        > button {
            padding: 0 !important;
        }
        > .btn-secondary {
            &:focus {
                padding: 0 !important;
            }
        }
    }
}