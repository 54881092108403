.tree-icon {
    height: 13px;
    width: 13px;
    fill: #ccc;

    &.active {
        fill: #8b8b8b;
    }

    svg {
        vertical-align: top;
    }
}

.wrapper-dnd {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: -8px;
        top: -10px;
        width: calc(100% + 16px);
        height: calc(100% + 10px);
    }

    &.allow-container {
        &::before {
            background: #edfff8;

            .dark & {
                background: rgba(0, 210, 128,0.13);
            }
        }
    }
    &.not-allow-container {
        opacity: 0.6;

        &::before {

            .dark & {
                background: rgba(0, 210, 128,0.13);
            }
            
            background: #fce7e7;
        }
    }

}


