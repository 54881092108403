.envelope-calculation-settings {
    text-align: right;
    margin-bottom: 20px;
    height: 32px;
    line-height: 32px;
    position: absolute;
    top: -47px;
    right: 0;
    white-space: nowrap;
    background: #fff;
    z-index: 2;

    input {
        max-width: 140px;
        min-width: 140px;
    }
}

.equpment-list-photo {
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        max-width: 150%;
        max-height: 250%;
    }
}

.custom-alert-block {
    .alert {
        margin: 0;
        padding: 5px;

        .alert-icon {
            i {
                font-size: 1.4rem;
            }
        }
    }
}

.gateway-edit {
    .sliderWrapper {
        overflow: hidden;

        .slide-item {
            max-height: 230px;
            background-color: rgb(56, 56, 46);
            width: 100%;
        }

        .previews-photos {
            height: 230px;
        }
    }

    .network-item {
        .sliderPrevBtn {
            padding: 110px 7px 0;
        }

        .sliderNextBtn {
            padding: 110px 7px 0;
        }
    }
}

.add-asset-container {
    padding: 0 3rem 1rem 3rem;
}

.custom-scroll-dropdown-menu {
    height: auto;
    max-height: 200px;
}

.highcharts-button-custom {
    font-size: 12px;
    text-align: left;
    color: #494949;
    border-radius: 3px;
    padding: 5px 9px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
}

.form-control-custom {
    width: 100px;
    height: 28px;
    margin-right: 5px;
    padding: 0.4rem;
}

.highcharts-button-custom .zoom {
    transform: translate(0, 0) scaleY(-1);
}

.k-aside-trigger {
    position: relative;
    top: 2.6rem;
    box-sizing: border-box;
}

.btn-hide-right {
    position: absolute;
    top: 15px;
    right: 0;
    transition: left 0.2s;
    z-index: 2;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    transform: rotate(180deg);
}

.btn-hide-right.hide-right-absolute {
    right: 0px;
    background: #e3bb30;
    padding: 5px;
}

.hide-right {
    display: none;
}

.modebar-group {
    span {
        &:hover {
            opacity: 1;
        }
    }
}

.not-allowed {
    cursor: not-allowed !important;
}

.Toastify__toast--success {
    background: #1dc9b7 !important;
}

.Toastify__toast--warning {
    background: #ffb822 !important;
}

span {
    + .sign-error {
        margin-left: 5px;
    }
}

.default-levels-table {
    font-size: 12px;

    @media (min-width: 1023px) {
        width: 100%;
    }
    @media (min-width: 1780px) {
        font-size: 1rem;
    }

    .sticky-table-header {
        position: -webkit-sticky;
        position: sticky;
        top: 59px;
        z-index: 1;

        @media (min-width: 1025px) {
            top: 62px;
        }
    }

    td {
        &:nth-child(1) {
            @media (min-width: 1025px) {
                width: 190px;
            }

            @media (min-width: 1780px) {
                width: 240px;
            }
        }

        &:nth-child(2) {
            @media (min-width: 1366px) {
                width: 140px;
            }

            @media (min-width: 1780px) {
                width: auto;
            }
        }
    }

    .col-temp {
        width: 100%;
        max-width: 100%;

        @media (min-width: 1780px) {
            width: auto;
            max-width: 50%;
        }
    }

    .badge {
        width: 100%;
        max-width: 70px;

        @media (min-width: 1366px) {
            width: auto;
            max-width: inherit;
        }
    }
}

.axis-label-text {
    width: 100%;
    margin: 5px 0;
    position: relative;

    @media (min-width: 1680px) {
        width: auto;
        margin: 0;
    }

    &::before {
        content: "";
        position: absolute;
        right: 10px;
        top: 50%;
        width: calc(100% - 55px);
        height: 1px;
        background: #ccc;
    }
}

.adaptive-row-table-all-edit {
    @media (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    th {
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
}

.adaptive-row-equipment {
    @media (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    td {
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
}

.adaptive-row-inst-point {
    @media (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 10px);
        margin: 5px;
        font-size: 11px;
    }

    .inst-point-title {
        @media (max-width: 1024px) {
            width: 100% !important;
            padding: 0 40px;
        }
    }

    td {
        @media (max-width: 1024px) {
            width: 50%;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}

.hide-mobile {
    @media (max-width: 1024px) {
        display: none;
    }
}

.show-mobile {
    display: none;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;

    @media (max-width: 1024px) {
        display: block;
    }
}

.inst-point-title {
    @media (max-width: 1024px) {
        text-align: center;
    }
}

.btn-toogle-desktop {
    background: none !important;
    display: none;

    @media (max-width: 1024px) {
        display: inline-flex;
    }

    img {
        pointer-events: none;
    }
}

.rotateDeg {
    transform: rotate(180deg);
}

.sweet-alert {
    &.sweet-alert-subs {
        h2 {
            font-size: 1.475em !important;
        }
    }

    .sweet-alert-text {
        text-align: left;

        ul {
            padding-left: 2rem;
        }
    }
}

.overlay-modal .modal-input {
    padding: 0 26px 0 12px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-position: calc(100% - 12px) center !important;
}

.whats-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100001;
}

.open-overlay {
    z-index: 3;

    .block-body {
        z-index: 1;
    }
}

.button-close-whats {
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.unnumerated-list {
    padding-left: 15px;
}

.disabled-label {
    cursor: not-allowed;
    opacity: 0.5;
}
.flex-auto {
    flex: auto;
}
.trigger-settings {
    .point-sensor-name {
        min-width: 27%;
    }
    .hints {
        background-color: rgba(255, 229, 100, 0.3);
        border-left-color: #ffe564;
        border-left-width: 9px;
        border-left-style: solid;
        padding: 10px 10px 10px 25px;
        margin: 10px 0;
    }

    .edit-mode {
        .point-sensor-name {
            margin: 15px 5px;

            > div {
                display: inline-flex;
                margin-right: 15px;
            }
        }

        .actions {
            position: absolute;
            top: 25px;
            right: 10px;
        }

        .point-settings-form-container {
            padding: 15px;

            .trigger-label {
                border-bottom: 1px black solid;
                display: block;
                text-align: right;
                font-weight: bold;
                margin: 15px 0;

                > .alert-link {
                    float: left;
                    cursor: pointer;
                }
            }
        }
    }
}

.react-select__container {
    display: inline-block;
}

.react-select.w100 {
    .react-select__control {
        width: 100%;
    }
}

.is-invalid{
    .react-select__control {
        border-color: #ea3c3c !important;
    }
    .ai-editor.rdw-editor-main{
        border-color: #ea3c3c;
    }
    .invalid-feedback{
        display: block;
    }
}

.react-select.is-invalid {
    .react-select__control {
        border-color: #ea3c3c;
    }
    ~ {
        .invalid-feedback {
            display: block;
        }
    }
}

.react-select__control {
    font-size: 11px !important;
    height: 30px !important;
    min-height: 30px !important;
    min-width: 70px !important;
    .react-select__input{
        color: #fff;
    }
}

.react-select__menu {
    z-index: 99999 !important;
}

.react-select__menu-portal {
    z-index: 99999 !important;
}

.react-select__dropdown-indicator {
    padding: 2px !important;
}

.react-select__option {
    font-size: 11px !important;
    padding: 5px !important;
    color: #000;
    position: relative !important;
}

.react-select__control--is-disabled {
    background-color: #f0f0f0 !important;
    cursor: not-allowed;
}

.react-select__option--is-disabled {
    background-color: #f0f0f0 !important;
    color: #aaa;
    cursor: not-allowed;
}

.yellow-time-icon {
    width: 15px;
    right: -10px;
    position: absolute;
    top: -10px;
}

.green-border {
    border-left: 3px solid green;
}

.gray-border {
    border-left: 3px solid gray;
}

// .highcharts-label-box {
//    opacity: 0;
// }

.show-error {
    .invalid-feedback {
        display: block;
    }
}


.create-action-item-modal{
    .rdw-editor-toolbar{
        max-height: 40px;
        overflow: hidden;
        transition: 0.3s all ease;
    }
    .expand-editor-active{
        .rdw-editor-toolbar{
            max-height: 100px;
            overflow: visible;
        }
    }
    .rdw-image-modal{
        top: -200px;
        left: -90px;
    }
    .rdw-link-modal{
        top: -200px;
        left: -55px;
    }
    .rdw-image-modal-header{
        justify-content: center;
    }
    .rdw-image-modal-header .rdw-image-modal-header-option:nth-child(2) {
        display: none;
    }
    .rdw-image-modal-size{
        display: none;

    }
    .rdw-image-modal-btn-section{
        display: flex;
        flex-direction: row-reverse;
    }
    .arrow{
        position: absolute;
        font-size: 34px;
        top: 30px;
        right: 5px;
        height: 30px;
        width: 30px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s all ease;
        user-select: none;
        &:after{
            content: "";
            position: absolute;
            top: 50%;
            right: 25%;
            transform: translate(0, -25%);
            border: 6px solid transparent;
            border-top: 6px solid #000;
        }
    }
    .arrow-active{
        transform: rotate(180deg);
        top: 25px;
    }
}