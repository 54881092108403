.adapter-params-tab {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 3px;
    border-bottom: 1px solid #9E9E9E;
    cursor: pointer;
}

.adapter-params-tab.active {
    color: #449AFF;
    border-bottom: 1px solid #449AFF;
}

.adapter-form-container {
    padding: 10px;
    background: #f3f3f3;
}

.universal-adapter-modal .add-new-link,
.adapter-parameters-modal .add-new-link{
    color: #449AFF;
    text-decoration-line: underline;
    font-size: 12px;
    cursor: pointer;
    line-height: 1.5;
    display: inline-block;
    margin: 1rem 0;
    padding: 0 5px;
    font-weight: normal !important;
    letter-spacing: normal;
    text-transform: none;
}

.adapter-form-container .remove-row {
    font-size: 15px;
    cursor: pointer;
    bottom: 18px;
    position: absolute;
    color: #9E9E9E;
}

.universal-adapter-modal label {
    font-weight: 500;
}

.adapter-form-container .form-group {
    margin-bottom: 1rem;
}

.adapter-form-container .form-control[readonly] {
    background: white;
}

.adapter-params-form .invalid-feedback {
    bottom: 0;
}

.adapter-parameter-header {
    padding: 10px;
    border-top: 1px solid #f4f5f8;
    border-bottom: 1px solid #f4f5f8;
}

.adapter-parameter-header .title {
    font-weight: 500;
}

.parameters-body.custom-scroll {
    max-height: calc(100vh - 300px);
    height: auto;
}

.adapter-parameter-row {
    padding: 10px;
    border-bottom: 1px solid #f4f5f8;
}

.adapter-parameter-row:hover {
    background-color: #fafbfc;
}

.adapter-parameters-dropdown {
    display: flex;
    align-items: center;
}

.adapter-parameters-dropdown .params-item {
    padding: 5px;
}

.adapter-parameters-dropdown .params {
    border-right: 1px solid #449AFF;
    margin-right: 10px;
    padding: 0 5px;
}

.universal-adapter-table .dropdown-menu.show{
    width: max-content;
}
