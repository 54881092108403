.collapse-tree-button {
    position: absolute;
    left: -10px;
    top: 0;
    width: 21px;
    height: 21px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffb822;
    color: white;
    border-radius: 50px;
    cursor: pointer;

    i {
        vertical-align: middle;
        width: 10px;
        &.equipment-locations-tree-icon {
            &:before {
                content: "\f067";
            }
        }
    }

    &[aria-expanded="true"] {
        i.equipment-locations-tree-icon {
            &:before {
                content: "\f068";
            }
        }
        background: red;
    }

    &[data-toggle="disabled"] {
        background-color: #ccc;
    }
}

.equipment-tree-container {
    position: relative;
    padding-left: 25px;
    border-left: 1px solid #f3f3f3;
}