.blue-tooltip {
    color: #449aff;
}

.warning-tooltip {
    color: #ffb822;
}

.tooltip-xl {
    .tooltip-inner {
        max-width: 300px;
    }
}

.tooltip-xxl {
    .tooltip-inner {
        max-width: 400px;
    }
}
.tooltip-left {
    .tooltip-inner {
        text-align: left;
    }
}

.tooltip-inner {
    ul {
        margin-left: 10px;
    }
}