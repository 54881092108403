.tachometers {
    .table {
        thead {
            th {
                .title {
                    display: flex;
                    align-items: center;
                    span {
                        white-space: break-spaces;
                    }
                }
            }
        }
        td {
            &:not(:last-child) {
                a.link {
                    white-space: break-spaces;
                }
            }
            .dropdown {
                .link {
                    margin-left: 0;
                    text-align: left;
                }
            }
        }
    }
}