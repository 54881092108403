.user_guide-modal {
    .user_guide-list {
        list-style: none;
        .expand-title {
            min-height: 50px;
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .name {
                max-width: calc(100% - 40px);
                line-height: 18px;
                font-size: 14px;
            }
            &:hover {
                .name {
                    opacity: .6;
                }
            }
        }
    }
    .dropdown-icon {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
    }
    .list-lvl-2 {
        display: none;
        padding-left: 18px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        h4 {
            margin: 26px 0 16px;
            font-size: 13px;
        }
        ul {
            padding-left: 40px;
        }
        img {
            display: block;
            margin: 10px auto;
        }
    }
}