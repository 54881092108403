.highcharts-dropdowns {
  margin-bottom: 1rem;
}

.highcharts-dropdowns .dropdown-item {
  cursor: pointer;
}

.highcharts-dropdowns .dropdown-item.disabled {
  cursor: default;
}

.highcharts-dropdowns .dropdown {
  display: inline-block;
}

.highcharts-dropdowns .dropdown + .dropdown {
  margin-left: 10px;
}

.highcharts-tooltip > span {
  z-index: 1;
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid silver;
  border-radius: 3px;
  box-shadow: 1px 1px 2px #888;
  padding: 16px 8px 8px;
}

.highcharts-legend {
  .highcharts-legend-item {
    i.active {
      display: inline;
    }

    i.disable {
      display: none;
    }
  }

  .highcharts-legend-item.highcharts-legend-item-hidden {
    i.active {
      display: none;
    }

    i.disable {
      display: inline;
    }
  }
}

// dropdown charts

.chart-fft-frequency-types {
  .dropdown-item.disabled {
    color: #ccc;
  }

  .dropdown-item {
    &:disabled {
      color: #ccc;
    }
  }

  .dropdown-menu {
    > li {
      > a {
        &:hover {
          &:not(.active) {
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            color: #ccc;
          }
        }
      }
    }

    > .dropdown-item {
      &:hover {
        &:not(.active) {
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          color: #ccc;
        }
      }
    }
  }

  text-align: center;
  justify-content: center;
  padding-top: 5px;
}

.chart-create-alert {
  font-size: 1.5em;
  color: #f44336 !important;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 2;
  cursor: pointer;
  opacity: 0.6;
  line-height: 19px;

  &:hover {
    opacity: 1;
  }

  i {
    + {
      i {
        vertical-align: middle;
        font-size: 10px;
        margin-left: 2px;
      }
    }
  }
}

.chart-btns {
  text-align: right;

  .btn {
    max-width: 200px;
  }
}

.fill-seria {
  path {
    background-color: #fff;
  }
}

.chart-tooltip {
  padding-right: 20px;

  .movable {
    background: #f0f0f0;
    color: #505050;
    border: none;
    cursor: move;
    align-items: center;
    position: absolute;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-top: 0;
    right: 0;
    max-width: 24px;
    top: 0;
  }
}

.chart-block {
  background: #ffffff;
  padding: 20px;

  .btn-group-sm {
    margin-top: -4px;
    margin-right: 10px;
    display: inline-block;

    @media (max-width: 1440px) {
      margin-right: 5px;
    }

    .btn {
      margin: 7px 0 4px 0;
      padding: 5px 8px;
      font-size: 10px;
      line-height: 14px;
    }
  }

  + {
    .chart-block {
      margin-top: 1rem;
    }
  }

  .alert-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 2.4rem;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #31aee3;
    cursor: pointer;
    margin-right: 15px;
    white-space: nowrap;

    margin-top: 5px;> label {
      margin-right: 5px;
    }

    > i {
      margin-right: 5px;
    }

    &.black-color {
      color: black;
      text-decoration-line: none;
    }
  }

  .alert-link.disabled {
    color: #9e9e9e;
  }
}

.chart-title-text {
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #676767;
  margin-right: 1rem;
  margin-top: 8px;
}

.charts-list {
  flex: 1 1 100%;

  .modal-body {
    padding: 0;
    margin-bottom: 1rem;
  }
}

.charts-list-wrapper {
  display: flex;
}

.chart-label {
  max-width: 18rem;
  border-radius: 4px;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 5px solid #000;
  padding: 1rem;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
}

.chart-mark-label {
  max-width: 18rem;
  border-radius: 4px;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 5px solid #000;
  padding: 1rem;
  z-index: 1;
  max-width: 30rem;
  width: max-content;
}

.fault-frequency-label {
  max-width: 18rem;
  border-radius: 4px;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 5px solid #000;
  padding: 1rem;
  z-index: 1;
  max-width: 30rem;
  width: max-content;
}

.chart-mark-label-text {
  max-height: 250px;
  height: inherit;
  color: #4b4b4b;
  font-size: 0.9rem;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
  z-index: 2;

  * {
    white-space: normal;
  }
}

.fault-frequency-label-text {
  max-height: 250px;
  height: inherit;
  color: #4b4b4b;
  font-size: 0.9rem;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
  z-index: 2;

  * {
    white-space: normal;
  }
}

.chart-alert-label-text {
  * {
    white-space: normal;
  }

  color: #4b4b4b;
  font-size: 0.9rem;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
  z-index: 2;
}

.chart-alert-marker {
  .chart-alert-label-text {
    > span {
      display: block;
      line-height: 1.3rem;
    }

    > b {
      display: block;
      line-height: 1.3rem;
    }
  }
}

.chart-mark-block {
  .chart-mark-label-text {
    > span {
      display: block;
      line-height: 1.3rem;
    }

    > b {
      display: block;
      line-height: 1.3rem;
    }
  }
}

.fault-frequency-block {
  .fault-frequency-label-text {
    > span {
      display: block;
      line-height: 1.3rem;
    }

    > b {
      display: block;
      line-height: 1.3rem;
    }
  }
}

.chart-mark-wrapper {
  z-index: 320;
  border-radius: 3px;
  padding: 5px 7px 5px 8px;
  position: absolute;
  right: calc(100% - 8px);
}

.fault-frequency-wrapper {
  z-index: 320;
  border-radius: 3px;
  padding: 5px 7px 5px 10px;
  position: absolute;
  right: calc(100% - 8px);
}

.chart-mark-color {
  background: #46b67f;
  color: #fff;
}

.chart-mark-color.info {
  background: #e3bb31;
  color: #fff;
}

.chart-mark-color.primary {
  background: #5867dd;
  color: #fff;
}

.fault-frequency-color {
  background: #4696b6;
  color: #fff;
}

.ie {
  .k-aside-secondary {
    .k-aside-secondary__content {
      -ms-flex: none;
      flex: none;
      display: block;
      z-index: 1;
    }
  }

  select {
    background: #fff;
  }

  .chart-block {
    position: relative;
  }

  .modal-open {
    overflow: hidden !important;

    .chart-block {
      z-index: -1;
    }
  }
}

.chart-title-equipment {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #676767;
  margin-right: 1rem;
}

.chart-block-header {
  min-height: 30px;
  position: relative;
  z-index: 100;
}

.chart-block-title {
  float: left;
  line-height: 30px;
}

.simple-chart {
  width: 100%;
}

.fft-chart {
  width: 100%;
}

.fft-chart .highcharts-annotation .highcharts-annotation-shapes {
  pointer-events: none;
}

.modal-dialog {
  .stacked-waterfall-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .chart-block-fft-waterfall {
      max-width: calc(50% - 20px);
      margin: 0 10px;
    }

    .fft-chart {
      height: 250px;
    }
  }
}

.create-equipment-container {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  width: 100%;
  min-height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  width: 100%;
  min-height: 100%;
  padding: 27px;

  .title {
    color: #676767;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0.1rem;
  }

  .sub-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    color: #449aff;
    letter-spacing: 0.02rem;
    margin-top: 25px;
    margin-bottom: 19px;
    cursor: pointer;

    span {
      position: relative;
      padding-right: 15px;
      background: #fff;
      display: inline-block;

      .dark & {
        color: #fff !important;
      }
    }

    i.fa {
      position: relative;
      padding-left: 5px;
      background: #fff;
      display: inline-block;
      z-index: 2;
    }
  }

  .add-new-link {
    color: #449aff;
    text-decoration-line: underline;
    font-size: 12px;
    cursor: pointer;
    line-height: 14px;
    margin-bottom: 1rem;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }

    i.fa {
      margin-right: 5px;
    }
  }

  .btn.btn-primary {
    border-radius: 4px;
    background-color: #449aff;
  }

  .form-group {
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #4b4b4b;

      .dark & {
        color: #aaa;
      }
    }
  }
}

.chart-alert-wrapper {
  cursor: pointer;
  padding: 5px;
}

.open-left {
  position: absolute;
  left: -15px;
  margin: 0 20px;
  z-index: 9999;

  .chart-label-hover {
    border: 1px solid #fff;
    border-radius: 10px;
    opacity: 0.3;
    position: absolute;
    left: -11px;
    top: -1px;
  }
}

.open-bot {
  .chart-label-hover {
    bottom: -1px;
    top: unset !important;
  }

  bottom: 0;
}

.open-top {
  top: 0;
}

.open-right {
  position: absolute;
  right: -15px;
  margin: 0 20px;
  z-index: 9999;

  .chart-label-hover {
    border: 1px solid #fff;
    border-radius: 10px;
    opacity: 0.3;
    position: absolute;
    right: -6px;
    top: -1px;
  }
}

.chart-block-fft {
  .labels-block {
    position: absolute;
    top: 0.8rem;
    right: 5rem;
    z-index: 99;

    .badge {
      + {
        .badge {
          margin-left: 5px;
        }
      }
    }
  }
}

.chart-block-fft-waterfall {
  position: relative;
  padding: 0;
  width: 100%;
}

.chart-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #4b4b4b;
  text-transform: uppercase;
}

.chart-header {
  text-transform: none;
  color: #212529;
}

.highcharts-container {
  text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.chart-block-actions {
  .custom-scroll.dropdown-menu {
    overflow-y: scroll;
    height: 300px;
  }
}

.chart-mark-buttons {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  z-index: 100;
  left: -10px;
  top: 8px;

  .chart-mark-button {
    background-color: #46b67f;
    color: white;
    padding: 1px 5px;
    border-radius: 4px;
    display: inline-block;

    i {
      margin-right: 3px;
    }
  }

  .reset-chart-mark {
    background-color: #21201a;
    color: white;
    padding: 1px 10px;
    border-radius: 4px;
    opacity: 0.7;
  }
}

.highcharts-reset-zoom-custom {
  font-size: 10px;
  text-align: left;
  color: #494949;
  border-radius: 3px;
  padding: 4px 8px;
  border: none !important;
  margin-right: 10px;
  cursor: pointer;
  height: 24px;
  font-weight: 500;

  &_absolute {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 99;
  }

  .dark & {
    color: #ccc;
    border: 1px solid #fff;
    background: #555;
  }
}

.tooltip-text {
  text-align: left;
}

.scale-content {
  position: relative;
  z-index: 2;
}

.scale-wrapper {
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    left: -64px;
    top: -16px;
    width: calc(100% + 120px);
    height: calc(100% + 160px);
    z-index: 1;
  }
}

.btn-zoom-scale + div {
  position: relative;
  z-index: 1;
}

.chart-block-fft-waterfall .highcharts-title {
  text-transform: uppercase;
  color: #676767 !important;
  font-size: 12px !important;
}

.chart-block-fft-waterfall .highcharts-button .highcharts-reset-zoom .highcharts-button-normal {
  font-size: 10px;
}

.waterfall-body.modal-body {
  max-height: initial;
}

.save-wrapper-picker {
  padding-right: 11px;
}

.wrapper-dates-list {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 200px;
  max-height: 200px;
}

.chart-block .btn-group-sm {
  margin-top: 0 !important;
  vertical-align: top;
}

.chart-block .btn-group-sm .btn {
  margin: 0 !important;
}

.highcharts-plot-line-label {
  overflow: visible !important;
}