.nameplate-component {
    width: calc(100% + 60px);
    position: relative;
    left: -30px;
}

.nameplate-component-block {
    background: #f3f3f3;
    padding: 10px 30px;
    margin-bottom: 10px;

    .photo-block {
        min-width: 370px;
    }

    .form-control {
        text-overflow: ellipsis;
    }
}

.nameplate-component-block.top-block {
    display: flex;
    align-items: center;
    background: inherit;
    justify-content: space-between;
}

.nameplate-component-block:last-child {
    margin-bottom: 0;
}

.title-nameplate-component {
    font-size: 1rem;
    font-weight: 400;
}

.nameplate-component .add-new-link,
.nameplate-template-modal .add-new-link {
    margin-bottom: 0 !important;
    margin-left: 10px;
    color: #449aff;
    cursor: pointer;
    text-decoration: underline;
}

.nameplate-parameters-modal .add-new-link,
.nameplate-modal .add-new-link,
.nameplate-component-block .add-new-link {
    color: #449aff;
    text-decoration-line: underline;
    font-size: 12px;
    cursor: pointer;
    line-height: 1.5;
    display: inline-block;
    margin: 1rem 0;
    padding: 0 5px;
    font-weight: normal !important;
    letter-spacing: normal;
    text-transform: none;
}

.nameplate-component .add-new-link i.fa,
.nameplate-modal .add-new-link i.fa,
.nameplate-parameters-modal .add-new-link i.fa,
.nameplate-component-block .add-new-link i.fa {
    margin-right: 5px;
}

.nameplate-template-table .template-row {
    border-top: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
}

.nameplate-template-table .template-row .remove {
    right: -25px;
    top: 15px;
}

.nameplate-template-table .template-row.new {
    align-items: inherit;
}

.nameplate-template-table .template-row:last-child {
    border-bottom: 1px solid #d9d9d9;
}

.nameplate-template-table .template-col {
    width: 50%;
    padding: 10px;
}

.nameplate-template-table .template-col:last-child {
    border-left: 1px solid #d9d9d9;
}

.nameplate-template-modal .remove-param {
    margin-left: 20px;
    font-size: 16px;
    cursor: pointer;
    color: #ffcd05;
}

.nameplate-parameters-modal .parameters-body.custom-scroll {
    max-height: calc(100vh - 300px);
    height: auto;
}

.nameplate-parameters-modal .parameter-row {
    padding: 10px;
    border-bottom: 1px solid #f4f5f8;
}

.nameplate-parameters-modal .parameter-header {
    padding: 10px;
    border-top: 1px solid #f4f5f8;
    border-bottom: 1px solid #f4f5f8;
}

.nameplate-parameters-modal .modal-body {
    min-height: 300px;
}

.nameplate-parameters-modal .parameter-header .title {
    font-weight: 500;
}

.nameplates-templates-table .k-timeline .k-timeline__item:before {
    display: none;
}

.nameplates-templates-table .custom-popover {
    min-width: auto;
}

.nameplates-templates-table .popover {
    max-width: none;
}

.nameplate-component-block .is-invalid {
    position: relative;
    border: 1px solid #ea3c3c;
}

.nameplate-component-block .is-invalid .invalid-feedback {
    display: block;
}

.template-col .invalid-feedback {
    bottom: -5px;
}

.template-col .parameter-select-wrapper .invalid-feedback {
    bottom: -15px;
}

.nameplate-component-block .buttons-row {
    padding: 10px 20px;
}

.k-aside-secondary__content-body.nameplate-form-wrapper .form-group {
    margin-bottom: 0;
}