
.rst__rowLabel {
    .rst__rowTitle {
        font-weight: normal;
    }
}
.rst__nodeContent {
    .rst__expandButton {
        border: none;
        box-shadow: 0 0 0 3px #ffb822;
        background: #ffb822;
        &:after {
            position: absolute;
            height: 100%;
            width: 100%;
            text-align: center;
            top: 0;
            left: 0;
            color: #fff;
            font-size: 18px;
            line-height: 17px;
            content: '+';
        }
        &:hover {
            &:not(:active) {
                &:after {
                    line-height: 22px;
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 3px #ffb822;
        }

        .sort-facility-mode & {
            display: none;
        }
    }
    .rst__collapseButton {
        border: none;
        box-shadow: 0 0 0 3px #f44336;
        background: #f44336;
        &:after {
            position: absolute;
            height: 100%;
            width: 100%;
            text-align: center;
            top: 0;
            left: 0;
            color: #fff;
            font-size: 18px;
            line-height: 17px;
            content: '-';
        }
        &:hover {
            &:not(:active) {
                &:after {
                    line-height: 22px;
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 3px #f44336;
        }
    }
    .rst__lineChildren {
        &::after {
            background-color: #ebedf2;
        }
    }
}
.rst__lineBlock.rst__lineHalfHorizontalRight {
    &::before {
        background-color: #ebedf2;
    }
}
.rst__lineBlock.rst__lineFullVertical {
    &::after {
        background-color: #ebedf2;
    }
}
.rst__lineBlock.rst__lineHalfVerticalTop {
    &::after {
        background-color: #ebedf2;
    }
}
.rst__lineBlock.rst__lineHalfVerticalBottom {
    &::after {
        background-color: #ebedf2;
    }
}

.k-sortable-row {
    border: 3px solid #f4f5f8;
    .rst__moveHandle {
        border: none;
        box-shadow: none;
    }
    .rst__loadingHandle {
        border: none;
        box-shadow: none;
    }
    .rst__rowContents {
        border: none;
        box-shadow: none;
    }
}

.rst__row.k-section__content.k-section__content--border {
    > .rst__rowContents {
        border: 0;
    }
}