.enabled-auth-info {
     background-color: #69c46d;
     color: white;
     padding: 5px;
     margin-bottom: 10px;
}

.disabled-auth-info {
     background-color: lightgrey;
     padding: 5px;
     margin-bottom: 10px;
}