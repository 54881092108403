.add-action-item {
    .ai-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #4b4b4b;
        max-width: 86%;
        order: 0;
        flex-grow: 0;
        margin: 0 0 5px;
    }
    .back-button {
        font-size: 20px;
        cursor: pointer;
        opacity: 0.6;
    }
    .k-portlet {
        .k-portlet__body {
            padding: 25px 50px;
        }
    }
    .ai-description {
        line-height: 23px;
    }
    .k-aside-secondary__content-body {
        select {
            background-position: 98% center !important;
        }
    }
}

.view-action-item {
    .ai-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #4b4b4b;
        max-width: 86%;
        order: 0;
        flex-grow: 0;
        margin: 0 0 5px;
    }
    .ai-description {
        img {
            max-width: 100% !important;
        }
    }
    .ai-title-second {
        font-size: 15px;
        font-weight: bold;
        display: inline-block;
        justify-content: center;
        padding: 10px 0;
    }
    .ai-title-second.action {
        cursor: pointer;
    }
    .comments-block {
        hr {
            margin: 0;
        }
    }
    .comment.status {
        text-align: center;
        .date {
            color: #6a6a6a;
            font-size: 12px;
            line-height: 14px;
        }
        .status {
            color: #6a6a6a;
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
        }
        .description {
            color: #6a6a6a;
            font-size: 12px;
            line-height: 14px;
        }
    }
    .comment {
        padding: 10px;
        position: relative;
        border-bottom: 1px solid #ececec;
        .delete-comment {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            color: #7f7f7f;
            &:hover {
                color: #000;
                opacity: .75;
            }
        }
        &:not(.status) {
            &:hover {
                background: #f4f4f4;
            }
        }
        .comment-header {
            display: flex;
        }
        .user-name {
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
        }
        .comment-date {
            font-size: 12px;
            line-height: 14px;
            color: #8f8f8f;
        }
        .comment-info {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    .sensor-block {
        border: 2px solid green;
        padding: 10px;
        width: 49%;
        margin-right: 2%;
        border-radius: 5px;
        background: #fbfdfc;
        position: relative;
        margin-bottom: 17px;
        &:nth-child(2n) {
            margin-right: 0;
        }
        > div {
            margin-bottom: 5px;
        }
        .sensor-position {
            background: green;
            color: white;
            border-radius: 5px;
            padding: 5px;
            width: 25px;
            height: 25px;
            display: flex;
            position: absolute;
            right: 2px;
            top: 2px;
            > span {
                line-height: 17px;
                margin: auto;
                display: block;
            }
        }
    }
    .action-item-line {
        .k-badge.k-badge--inline {
            font-size: 11px;
            height: 21px !important;
            padding: 0.75rem 1rem;
        }
    }
    .rdw-image-modal {
        position: fixed;
        top: 20%;
        left: 32%;
    }
    .rdw-link-modal {
        position: fixed;
        top: 20%;
        left: 32%;
        height: 230px;
    }
    label {
        font-weight: bold;
        font-size: 13px;
    }
}

.ai-title-second {
    .fa {
        color: #9e9e9e;
    }
}

.ai-editor {
    border: 1px solid #c4c4c4;
}

.rdw-editor-toolbar.ai-editor-toolbar {
    border: 1px solid #c4c4c4;
}

.ai-editor.rdw-editor-main {
    min-height: 70px;
    padding: 0 20px;
}

.action-buttons {
    .btn {
        padding: 10px 0;
        min-width: 150px;
    }
}

.action-item-body.custom-scroll {
    max-height: calc(100vh - 19rem);
}

.action-item-footer {
    padding: 20px 0 0;
}

.action-item-head {
    padding: 15px 5px 15px 25px;

    .close {
        font-size: 34px;
        font-weight: 400;
        line-height: 0.5;
        padding: 0.45rem 1rem 0;
    }
    border-bottom: 1px solid #f3f3f3;
}

.action-item-list {
    .action-item-footer {
        justify-content: start;
    }
    .sticky-table-header {
        th {
            top: -1px;
            background: #fafafa;
        }
    }
    table {
        .title {
            > span {
                white-space: nowrap;
            }
        }
    }
}

.cancel-filter-btn {
    display: flex;
    margin: auto;
    margin-bottom: 2px;
}

.action-item-search {
    display: flex;
    margin: auto;
    margin-bottom: 0;
}

.btn-view-action-item {
    position: relative;
    span {
        background: #b44848;
        position: absolute;
        top: -7px;
        right: -7px;
        border-radius: 10px;
        height: 16px;
        width: 16px;
        font-size: 12px;
        font-weight: 600;
    }
}

.action-item-body.ai-table {
    padding: 0;
}
.action-item-list.loading {
    min-height: calc(100vh - 12.8rem);
    position: relative;
    min-height: calc(100vh - 12.8rem);
    position: relative;
    .loader {
        position: absolute;
        position: absolute;
    }
}

.action-item-modal {
    .loader {
        position: absolute;
    }
    width: calc(100% - 20rem);
    max-width: 750px;
    color: #4b4b4b;
    .action-item-footer {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .action-item-body {
        padding: 20px;
    }
    .rdw-link-modal-buttonsection {
        display: flex;
        > button {
            font-size: 14px;
            line-height: 1;
            font-weight: 500;
            padding: 12px 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
        }
        button {
            &:first-child {
                background: transparent;
                border: 1px solid #449aff;
                color: #449aff;
                &:disabled {
                    background: transparent;
                    border: 1px solid #f1f1f1;
                    color: #f1f1f1;
                    &:hover {
                        border: 1px solid #f1f1f1;
                    }
                }
                &:hover {
                    border: 1px solid #449aff;
                    box-shadow: none;
                }
            }
            &:last-child {
                background: #449aff;
                color: white;
                border: none;
                &:hover {
                    border: none;
                    box-shadow: none;
                    background: #59a5ff;
                }
            }
        }
    }
    .rdw-image-modal-btn-section {
        display: flex;
        > button {
            font-size: 14px;
            line-height: 1;
            font-weight: 500;
            padding: 12px 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
        }
        button {
            &:first-child {
                background: transparent;
                border: 1px solid #449aff;
                color: #449aff;
                &:disabled {
                    background: transparent;
                    border: 1px solid #f1f1f1;
                    color: #f1f1f1;
                    &:hover {
                        border: 1px solid #f1f1f1;
                    }
                }
                &:hover {
                    border: 1px solid #449aff;
                    box-shadow: none;
                }
            }
            &:last-child {
                background: #449aff;
                color: white;
                border: none;
                &:hover {
                    border: none;
                    box-shadow: none;
                    background: #59a5ff;
                }
            }
        }
    }
    .v2-btn {
        min-width: 250px;
    }
}

.action-item-line {
    align-items: center;
}

.action-item-head.action-item-line {
    padding: 20px 5px 8px 22px;
}

.item-custom {
    margin-left: 1px;
}

.ai-info-description {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #4b4b4b;
}

.ai-create-date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #878787;
    margin-left: 10px;
}

.ai-title-toggle {
    font-weight: bold;
    font-size: 16px !important;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.ai-info-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4b4b4b;
}

.report-title {
    i {
        color: #449aff;
    }
}

.report-info {
    > div {
        margin-top: 0.5rem;
    }
}

.comment {
    .toggle-report {
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

.comment-block {
    position: relative;
}

.validation-control.is-invalid {
    ~  {
        .invalid-feedback {
            display: block;
        }
    }
    .ai-editor {
        border: 1px solid #ea3c3c;
    }
}

.completion-form {
    > div {
        margin-bottom: 15px;
    }
}

.archive-urgency {
    border: 1px solid grey;
    background: transparent;
    color: gray;
}

.title.snooze-title {
    font-weight: bold;
    font-size: 16px !important;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #4b4b4b;
}

.close.close-snooze {
    &:before {
        display: none;
    }
}

.actions-right {
    padding-right: 2px;
    button {
        padding: 0 8px;
    }
    .close {
        padding-top: 0;
    }
}

.action-item-actions {
    .btn {
        font-weight: 500;
        padding: 0.3rem 0.7rem;
        background: #57acde !important;
    }
}

.ai-table {
    .table {
        td {
            padding: 1rem 0.75rem;
        }
        th {
            color: #4b4b4b;
        }
    }
}

.ai-info-block {
    > div {
        margin-bottom: 15px;
    }
    strong {
        color: #4b4b4b;
        margin-right: 10px;
    }
}

.snooze-modal {
    .profile-modal-bottom {
        justify-content: space-between;
        .v2-btn {
            width: 49%;
        }
    }
    label {
        font-weight: 900;
    }
    .form-group {
        > div {
            margin-bottom: 10px;
        }
    }
    textarea {
        min-height: 150px;
    }
}

.completion {
    .ai-title {
        margin: 0;
    }
    .action-item-body {
        padding: 5px 20px 20px;
    }
    .action-item-body.custom-scroll {
        max-height: calc(100vh - 16.5rem);
    }
}

.req-label-size {
    font-weight: bold;
    font-size: 14px !important;
    line-height: 16px;
    color: #4b4b4b;
}
