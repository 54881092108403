.tree-facility-icon {
    position: absolute;
    display: inline-block;
    color: white;
    width: 44px;
    background: #d9d9d9;
    height: 36px;
    padding: 5px 7px;
    top: 0;
    left: 0;
}
.tree-facility-name {
    margin-left: 45px;
}