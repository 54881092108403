.table-scroll-wrapp {
    width: 100%;
    overflow-x: auto;

    .wifi-group-icon {
        width: 15px;
        height: 15px;
    }
}

.table-scroll-wrapp.tachometers {
    overflow: inherit;
}

.table {
    th {
        vertical-align: middle;
        white-space: nowrap;
    }
    td {
        vertical-align: middle;
    }
    thead {
        th {
            i {
                margin-left: 5px;
            }
        }
    }
}

.tl-fixed.table {
    thead {
        th {
            vertical-align: middle;
        }
    }
}

.tl-fixed {
    th {
        &:last-child {
            text-align: right;
        }
    }
    td {
        &:last-child {
            text-align: right;
        }
    }
    tr {
        > td {
            white-space: break-word;
        }
    }
}

.table-buttons-th {
    line-height: 29px;
    text-align: right;
}

.equipment-list-table {
    th {
        line-height: 29px;
    }
}

.alerts-cell {
    min-width: 110px;
}

.table-scroll {
    overflow-x: auto;
    max-width: 100%;
}

.default-levels-table {
    td,
    th {
        position: relative;
        vertical-align: top;
    }
    button {
        &:disabled {
            cursor: not-allowed;
        }
    }

    .action-icon-tbl {
        position: absolute;
        top: 8px;
        right: 17px;

        @media (min-width: 1024px) {
            right: 5px;
        }
    }

    .adaptive-row-inst-point {
        .action-icon-tbl {
            right: 12px;

            @media (min-width: 1024px) {
                right: 5px;
            }
        }

    }
}

.bearing-table {
    thead {
        background-color: map-get($theme-colors, info-row);
    }
}
