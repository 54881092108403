.bearing-item {
    > .dropdown-item {
        outline: none !important;
        display: flex;
        flex-grow: 1;
        padding: 0.7rem 1.2rem;
        transition: all 0.3s;

        &:hover {
            &:not(.active) {
                transition: all 0.3s;
                color: #5c4de5;
                > i {
                    color: #5c4de5;
                }
            }
        }

        &.active-bearing {
            transition: all 0.3s;
            color: #5c4de5;
            > i {
                color: #5c4de5;
            }
        }

        > i {
            margin-right: 0.75rem;
            color: #b3b1c2;
            &:before {
                line-height: 0;
                vertical-align: middle;
            }
        }
    }
}

.bearing-dropdown {

    button[disabled] {
        cursor: not-allowed;
    }
}

.fault-frequency-dropdown {

    button[disabled] {
        cursor: not-allowed;
        color: #b3b1c2;
    }
}

.bearing-dropdown-item {
    padding: 0.7rem 1.6rem !important;
    font-size: 1rem !important;
    line-height: 1rem;
    .fa {
        font-size: 1rem !important;
        line-height: 1rem;
        position: relative;
        top: 1px;
    }
    span {
        margin: 0 5px;
    }
    span.label {
        padding: 3px 5px;
        margin: -3px 0;
        color: #fff;
        text-transform: uppercase;
        line-height: inherit;
    }
}