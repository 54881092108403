.bg-sz-cover {
    background-size: cover !important;
}

.feedback-dropzone-item {
    margin-bottom: 15px;
    height: 45px;
    width: 100%;
    padding: 10px 0 10px 0;
}

.feedback-files-block {
    margin-top: 20px;
}

.feedback-dropzone-item .dropzone-thumb-img-wrapper i{
    font-size: 25px;
    color: #449aff;
}
.feedback-file-name {
    margin-left: 10px;
}

.sweet-alert-refactor {
    width: 850px !important;
}

.upload-error {
    width: 100%;
    margin-top: 0.25rem;
    color: #EA3C3C;
}

.dropzone-custom.error {
    border-color: #EA3C3C;;
}
