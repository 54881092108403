.pager {
    display: inline-block;

    &-wrapper {
        display: block;
        text-align: center;
    }

    &-nav {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
    }

    &-item {
        display: inline-block;
        margin-right: 5px;

        &:last-child{
            margin-right: 0;
        }
    }

    &-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.25rem;
        min-width: 2.25rem;
        padding: 0.5rem;
        border-radius: 3px;
        position: relative;
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 500;
        color: #464457;
        transition: color .3s, background-color .3s ease;

        .dark & {
            color: #777 !important;
        }

        &--first {
            background: #ebe9f2;
            .dark & {
                background: #e4d9c2;
            }
        }

        &--last {
            background: #ebe9f2;
            .dark & {
                background: #e4d9c2;
            }
        }

        &--prev {
            background: #ebe9f2;
            .dark & {
                background: #e4d9c2;
            }
        }

        &--next {
            background: #ebe9f2;
            .dark & {
                background: #e4d9c2;
            }
        }

        &--more {
            font-weight: 600;
        }

        &--number {
            color: #464457;
        }

        &--active, &:hover {
            background: #ffd958;
            color: #fff !important;

            .dark & {
                background: #d8b74b;
                color: #333 !important;
            }
        }

        &--disabled {
            opacity: .3;
            pointer-events: none;

            .dark & {
                color: #dedbf8 !important;
                background: rgba(102,101,101,.692);
            }
        }
    }

    &-info {
        margin-top: 5px;
    }

    &-detail {
        font-size: 1rem;
    }
}