//form styles
.form {
    $this: &;

    &--user {
        #{$this}-group {
            margin-bottom: 1rem;
        }
    }

    &-control {
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: .02rem;
        color: #9e9e9e;
        background-color: #fff;
        height: 32px;
        padding: .4rem 1.25rem;

        &-inline {
            display: inline-block;
            vertical-align: top;
            max-width: 20%;
            margin: 0 10px;
        }

        &-inline__b {
            max-width: 40%;
        }

        &-sm {
            height: calc(2.35rem + 2px);
            display: inline-block;
            width: auto;
            padding: 0.45rem 1rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.2rem;
        }

        &-select {
            &.is-invalid {
                ~  {
                    .invalid-feedback {
                        display: block;
                    }
                }
            }

            &--lg {
                min-width: 240px;
            }
        }

        &:disabled {
            background: #dedede;
            +  {
                .input-group-append {
                    > span {
                        background: #dedede;
                    }
                }
            }
        }

        &[readonly] {
            background: #dedede;
        }
        &[type="number"] {
            padding-right: 0px;
        }

        &.modal-input {
            background-color: #ffffff;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 4px;
            height: 32px;
            padding: 0 0 0 12px;
        }
    }

    &-label {
        font-size: .9rem;
        line-height: 1.1rem;
        font-weight: 700;
        color: #4b4b4b;
        margin-bottom: .4rem;
        white-space: nowrap;

        .dark & {
            color: #aaa;
        }
    }

    &-radio {
        margin-bottom: 0;
        display: inline-block;
        vertical-align: middle;
        padding-left: 30px;
        font-size: .9rem;
        line-height: 1.7rem;
        font-weight: 700;
        position: relative;
        cursor: pointer;

        &--normal {
            font-weight: 400;
            font-size: 13px;
        }

        > input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked {
                ~ span {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-left: -5px;
                        margin-top: -5px;
                        width: 10px;
                        height: 10px;
                        border-radius: 100% !important;
                        background: map-get($theme-colors, primary);
                    }
                }
            }
        }

        > span {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 50% !important;
            background: #e3e3e6;

            .dark & {
                background: hsla(0, 0%, 58.8%, .3);
            }
        }
    }

    &-group {
        position: relative;

        &--inline {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 0;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            .form-label,
            label {
                margin-bottom: 0;
            }

            > div {
                line-height: 14px;
            }
        }

        .form-control-notice {
            position: absolute;
            bottom: -14px;
            left: 0;
            text-align: right;
            padding-right: 5px;
            width: 100%;
            margin-top: 0.25rem;
            font-size: 80%;
            color: map-get($theme-colors, secondary);
        }
    }

    &-checkbox {
        padding-left: 30px;
        line-height: 1.7rem;
        font-size: 12px;
        line-height: 20px;
        color: #4b4b4b;
        vertical-align: middle;
        cursor: pointer;
        position: relative;
        min-height: 18px;
        margin-bottom: 0;

        .email-events-list.ai-setting-block & {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        .dark & {
            color: #aaa;
        }

        input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            left: 0;

            &:checked {
                ~ span {
                    background: #4b4b4b;
                    border-color: #4b4b4b;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-left: -2px;
                        margin-top: -7px;
                        width: 5px;
                        height: 10px;
                        border-width: 0 2px 2px 0 !important;
                        border: solid #fff;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        > span {
            border: 2px solid #939393;
            background: none;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 18px;
            width: 18px;

            .dark & {
                border-color: rgba(150, 150, 150, .3);
            }
        }

        &.form-checkbox-dash {
            input {
                &:checked {
                    ~ span {
                        &:after {
                            border-width: 0 2px 0 0 !important;
                            transform: rotate(90deg) !important;
                        }
                    }
                }
            }
        }

        &.modal-checkbox {
            > input[type=checkbox] {
                ~  {
                    span {
                        background: #c4c4c4;
                        border: none;
                        background: #e3e3e6;
                    }
                }
                &:checked {
                    ~  {
                        span {
                            background: #31aee3;
                            border: none;
                        }
                    }
                }
            }
            > span {
                &:after {
                    border: solid #ffffff;
                }
            }
        }

        .autocomplete {
            > div {
                > .item {
                    padding: 0.4rem 1.1rem;
                    font-size: 12px;
                    cursor: pointer;
                }
                > .item.item-highlighted {
                    opacity: 0.7;
                }
            }
        }

        &--update {
            span {
                top: 10px !important;
            }
        }

        &--filter {
            span {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &--alerts {
            font-size: 14px;

            input {
                &:checked ~ span {
                    background: #449aff;
                    border-color: #449aff;
                }

                ~ span {
                    border-radius: 2px;
                    border-width: 1px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &-checkbox-list {
        .form-checkbox {
            display: block;
            margin-bottom: 10px;
        }
    }

    &-list {
        &--column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            #{$this}-radio {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            #{$this}-checkbox {
                + #{$this}-checkbox {
                    margin-top: 7px;
                }
            }
        }

        &--row {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .form-label {
                margin-right: 5px;
            }

            + .block-pagination {
                margin-left: 0;
            }
        }
    }

    &-title-group {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        position: relative;
        color: #449aff;
        letter-spacing: .02rem;
        margin-top: 30px;
        margin-bottom: 15px;

        &:first-child {
            margin-top: 0;
        }
    }
}

//select
select {
    &.form-control {
        position: relative;
        appearance: none;
        background-image: url('../../pic/icon-arrow-down.svg') !important;
        background-repeat: no-repeat !important;
        background-position: calc(100% - 15px) center !important;
        background-color: #fff;
        padding-right: 2.175rem;
    }

    &.select-without-arrow {
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

//helper request forms
.request-form-control {
    height: 30px !important;
    line-height: 26px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0;
    padding-bottom: 0;
}

//input group
.input-group {
    &-text {
        background: #fff;
        border-color: #dedede;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;

        i {
            color: #808080;
        }
    }

    input {
        background: #fff;
        color: #808080;
        border-color: #dedede;
    }

    .btn {
        i {
            color: #ffffff;
        }
    }

    > .rdt {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
    }

    > .react-datepicker-wrapper {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
    }
}

//invalid message
.invalid-feedback {
    position: absolute;

    .step-body & {
        bottom: auto;
        top: 100%;
        background: #fff;
        z-index: 1;

        .dark & {
            background: #35342a;
        }
    }
}
.invalid-feedback.show {
    display: block;
}
.custom-validation.is-invalid {
    ~  {
        .invalid-feedback {
            display: block;
        }
    }
}
.equipment-modal .invalid-feedback{
    position: unset !important;
}

//helpers checkbox
.black-checkbox {
    > span {
        &:after {
            border: solid #fff;
        }
    }
}

.white-checkbox {
    > span {
        &:after {
            border: solid #fff;
        }
    }
}

.blue-checkbox {
    input {
        ~  {
            span {
                border: 1px solid #969696;
                border-radius: 2px;
            }
        }

        &:checked {
            ~  {
                span {
                    background: #449aff;
                    border: 1px solid #449aff;
                }
            }
        }
    }
    > span {
        &:after {
            border: solid #fff;
        }
    }
}

.checkbox-drop {
    padding: 15px 10px 15px 5px;
    margin-left: -5px;
    cursor: pointer;

    &:hover {
        border-radius: 5px;
        background-color: #f3f3f3;
    }

    .form-checkbox {
        span {
            border-width: 1px;
            top: 50% !important;
        }
    }

    .btn {
        color: #000;

        &:hover {
            opacity: 0.8;
        }
    }

    input:checked {
        ~ span {
            border-width: 1px;
            border-color: #939393 !important;
            background: #38382e !important;

            &::after {
                margin: 0;
                padding: 0;
                content: "-";
                width: auto;
                height: auto;
                border-width: 0;
                font-weight: 700;
                color: #fff;
                font-size: 24px;
                border: none;
                margin: 0;
                padding: 0;
                transform: translate(-50%, -50%);
            }
        }
    }
}

// limit select
.limit-select-block {
    min-width: 80px;
}

// for next slider valid and styles
input {
    &:invalid {
        box-shadow: none;
    }
    &:focus {
        +  {
            .slider {
                box-shadow: 0 0 1px #2196f3;
            }
        }
    }
    +  {
        .slider {
            border: 1px solid #9e9e9e;
            &:before {
                border: 1px solid #9e9e9e;
                background: #9e9e9e;
            }
        }
    }
    &:checked {
        +  {
            .slider {
                border: 1px solid #2196f3;
                &:before {
                    transform: translateX(1rem);
                    background: #2196f3;
                    border: 1px solid #2196f3;
                }
            }
        }
    }
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 0px transparent inset !important;
        -webkit-text-fill-color: #9e9e9e !important;
        &:hover {
            -webkit-box-shadow: 0 0 0px 0px transparent inset !important;
            -webkit-text-fill-color: #000 !important;
        }
        &:focus {
            -webkit-box-shadow: 0 0 0px 0px transparent inset !important;
            -webkit-text-fill-color: #000 !important;
        }
        &:active {
            -webkit-box-shadow: 0 0 0px 0px transparent inset !important;
            -webkit-text-fill-color: #000 !important;
        }
    }
}

.row-fft {
    .form-control {
        margin: 0;
        margin-right: 10px;
    }

    .form-radio {
        padding-right: 2px;
    }

    label {
        width: 20px;
    }
}

@-moz-document url-prefix() {
    .form-control {
        &[type="number"] {
            padding-right: 0 !important;
        }
    }
}

.border-confirm-block {
    border: 2px solid #C4C4C4;
    border-radius: 5px;
    padding: 4px;
    margin: 10px 5px 5px 5px;

    .text-error {
        fontWeight: normal;
        color: #d56a6a
    }
    .form-checkbox > span{
        border-radius: 4px;

    }
    .form-checkbox input:checked ~ span {
        background: #79B7FF;
        border-color: #939393;
    }
}

.installation-point-form {
    .invalid-feedback {
        position: inherit;
        bottom: 0;
    }
}

.routes-search-field {
    width: 150px;
}