.icon-parent {
    font-size: 16px;
}

.on-demand-alert-damage {
    margin-bottom: 0;
    padding-left: 5px;
    white-space: nowrap;
}

.manage-alerts {
    margin-left: 45px;
}


