.envelope-calculation-label {
    margin-bottom: 20px;
    height: 32px;
    line-height: 32px;
    top: 0;
    left: 100px;
    white-space: nowrap;
    z-index: 2;
}
.show-band-trend-label {
    text-align: right;
    margin-bottom: 20px;
    height: 32px;
    line-height: 32px;
    top: -47px;
    right: 0;
    white-space: nowrap;
    background: #fff;
    z-index: 2;
}

.manage-alerts-scroll {
    max-height: 600px;
    padding-left: 0;
}

.manage-alerts-modal {
    .add-new-link {
        color: #449aff;
        font-size: 12px;
        cursor: pointer;
        line-height: 14px;
        text-align: center;
        margin-bottom: 1rem;
        padding: 8px;
        border: 2px dashed #c4c4c4;
        display: block;
        &:hover {
            text-decoration: none;
        }
        i.fa {
            margin-right: 5px;
        }
    }

    .secondary-text i {
        color: #449aff;
    }
}

.alert-level-block {
    padding: 10px;
    background: #f1f1f1;
    border-radius: 5px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;

    &-big {
        border: 1px solid transparent;
        padding: 14px;
        line-height: 1.3;

        .row.mt-2 {
            align-items: center;
        }

        .input-group-append > .input-group-text {
            padding: 0.45rem 0.5rem !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            line-height: 18px !important;
        }

        .form-control:disabled, .form-control:disabled + .input-group-append > span, .form-control[readonly] {
            pointer-events: none;
        }

        hr {
            margin: 10px 0;
        }

        select {
            padding-right: 28px !important;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden !important;
        }

        .error-msg {
            padding: 2px 5px;
            font-size: 11px;
            z-index: 2;
        }
    }

    + .alert-level-block {
        margin-top: 8px;
    }

    + .add-new-link-manage {
        margin-top: 8px;
    }

    .form-radio {
        margin-bottom: 0;
    }
}

.alert-level-block.current {
    background: transparentize(#e3bb31, 0.9);
    border: 1px solid #e3bb31;
}

.add-new-link-manage {
    color: #fff;
    border: none;
    background-color: #449aff;
    color: #fff;
    border-radius: 4px;
    padding: 7px 8px;
    display: block;
    text-align: center;
    cursor: pointer;

    i {
        margin-right: 8px;
    }

    &.disabled {
        opacity: 50%;
    }
}

.list-labels-alerts {
    margin: 6px -4px 0 -4px;
}

.label-alert-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    border-radius: 4px;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    margin: 0 4px;
}

.label-alert-item button {
    background: transparent;
    border: none;
    color: #fff;
    padding: 0;
    cursor: pointer;
}

.label-alert-item button:hover {
    opacity: 0.7;
}

.label-alert-item span {
    line-height: inherit;
}

.label-alert-item-caution {
    background-color: #e3bb31;
}

.label-alert-item-warning {
    background-color: #b44646;
}

.title-sensor-include {
    font-weight: 400;

    span {
        margin-right: 10px;
    }
}

.form-group-alerts {
    > label {
        font-size: 14px;
    }

    .form-checkbox > span {
        border-width: 1px;
        width: 16px;
        height: 16px;
    }

    .form-checkbox input {
        ~ span {
            border-radius: 2px;
        }

        &:checked ~ span {
            background: #449aff;
            border-color: #449aff;
        }
    }
}

.alert-level-block .level-label {
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 6px 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 0;
}

.alert-level-block .caution-level {
    background-color: #e3bb31;
}
.alert-level-block .warning-level {
    background-color: #b44646;
}

.alert-group-block {
    cursor: pointer;
    margin-bottom: 5px;
    padding: 10px;
    background: transparent;
}

.alert-group-block.current {
    cursor: default;
}

.alert-level-block.current {
    background: rgba(227, 187, 49, 0.1);
    border: 1px solid #e3bb31;
}

.chart-block-sticky {
    position: sticky;
    top: 0;
}