.index-icon-block {
    float: left;
    padding: 5px;
    color: #fff;
    margin-right: 10px;
    border: 1px solid #fff;
    border-radius: 6px;
    font-size: 12px;
    vertical-align: text-bottom;
}

.view-only {
    pointer-events: none;
}