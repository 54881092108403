.speed-settings-form {
    .sub-title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        position: relative;
        color: #449aff;
        letter-spacing: 0.02rem;
        margin-top: 25px;
        margin-bottom: 19px;
        cursor: pointer;
        span {
            position: relative;
            padding-right: 15px;
            background: #fff;
            display: inline-block;
        }
        i.fa {
            position: relative;
            padding-left: 5px;
            background: #fff;
            display: inline-block;
            z-index: 2;
        }
    }
    .add-new-link {
        color: #449aff;
        text-decoration-line: underline;
        font-size: 12px;
        cursor: pointer;
        line-height: 14px;
        margin-bottom: 1rem;
        display: inline-block;
        &:hover {
            text-decoration: none;
        }
        i.fa {
            margin-right: 5px;
        }
    }
    .form-label {
        font-size: 12px;
        font-weight: 400;
        margin-top: 35px;
    }
}
