.fft-peaks-modal {
    .btn-circle {
        i {
            font-size: 1.2rem !important;
        }
    }
    .modal-header {
        overflow: unset;
    }
    .modal-title {
        .dropdown {
            display: inline-block;
        }
    }
}