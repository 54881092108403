@import "../../../../assets/scss/config/variables";

.left-side {
  background: hsl(51, 12%, 12%);
  width: 17rem;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100vh;
  box-shadow: 5px 0px 8px rgba(0, 0, 0, 0.24);
  transition: all 0.3s ease;

  hr {
    opacity: 0.2;
    border: 1px solid #747474;
  }
}

.logo-wrapper {
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  background: #21201a;
  display: block;

  .logo {
    height: 3rem;
    line-height: 3rem;

    img {
      width: 80%;
    }
  }
}

.logo {
  display: inline-block;
  vertical-align: middle;

  img {
    display: inline-block;
    vertical-align: top;
    max-height: 100%;
  }

  .logo-small {
    display: none;
  }
}

.menu {
  padding: 1.8rem 1.1rem 3.8rem 2.7rem;
  box-sizing: border-box;
  max-height: calc(100vh - 214px);
  overflow-y: auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #b2b2b2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888686;
    border-radius: 4px;
  }

  ul {
    list-style: none;
  }

  img,
  span:not(.menu-item) {
    display: inline-block;
    vertical-align: middle;
  }

  &-item {
    cursor: pointer;
    line-height: 1.5rem;
    display: block;
    position: relative;
  }

  &-icon {
    float: left;
    margin-right: 0.4rem;
    line-height: 1.5rem;
    width: 1.2rem;
    height: 1.2rem;

    img {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-count {
    height: 1.5rem;
    min-width: 1.5rem;
    text-align: center;
    border-radius: 1.5rem;
    padding: 0 0.4rem;
    line-height: 1.5rem;

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 0.9rem;
      font-weight: bold;
    }
  }

  &-dropdown {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
  }

  &-name {
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #bfbebe;
    transition: all 0.2s ease;

    &.color-yellow-brand {
      color: map-get($theme-colors, yellow-brand) !important;
    }
  }

  &-count,
  &-dropdown {
    float: right;
    margin-left: 0.8rem;

    table & {
      float: none;
      margin-left: 0;
    }
  }

  &-trigger-wrap {
    display: flex;
    justify-content: space-between;
    padding: 1.1rem 1rem 1.1rem 1.7rem;
    box-sizing: border-box;
    text-align: right;
    flex-wrap: wrap;
    background: #0e0e0e;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
  }

  &-lvl-1 {
    > li {
      margin-bottom: 3rem;
    }
  }

  &-lvl-2 {
    display: none;
    padding-left: 1.2rem;
    margin-top: 1.5rem;

    li {
      margin-bottom: 1.5rem;
    }

    .menu-icon {
      img + img {
        display: none;
      }
    }
  }

  &-item.current &-name,
  &-item:hover &-name {
    color: #fff;
  }

  &.customer-name {
    padding: 0 1.75rem 0 0.1rem;
  }

  &.menu-help {
    padding: 1.8rem 1.1rem 0 2.1rem;
  }

  span {
    &.menu-name-text {
      overflow: hidden;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 38px;
    }
  }
}

.left-user-link {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 0.1rem 0 2.7rem;
  color: #bfbebe;
  font-size: 1.1rem;
  line-height: 1.5rem;
  width: 97%;
  height: 4rem;
  background: #21201a;

  .menu-item.fake-link {
    color: #333;
  }
}

.user-link {
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 1;

  span {
    padding-left: 30px;
    display: block;
  }

  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: -2px;

    ~ img {
      right: 0;
      left: auto;
      transform: translate(0, -50%) scaleY(-1);
      margin-top: 0;
    }
  }
}

.user-dropdown {
  display: none;
  position: absolute;
  bottom: 4.5rem;
  left: 2.7rem;
  width: calc(100% - 3.8rem);
  padding: 0.5rem 2rem;
  background: #f0f0f0;
  box-sizing: border-box;
  border-radius: 0.5rem;

  a {
    display: block;
    color: #333;
    font-size: 1.1rem;
    margin: 1rem 0;
  }

  i {
    margin-right: 0.5rem;
  }

  &[style="display: block;"] {
    + .user-link {
      img {
        ~ img {
          transform: translate(0, -50%) scaleY(1);
        }
      }
    }
  }
}

.customer-name {
  text-align: left;
}

.link-list {
  border-top: 2px solid rgba(116, 116, 116, 0.2);
  padding: 1.155rem 0.8rem 0 2rem;
  overflow-x: hidden;
}

.hide-link {
  display: none;
}

.btn-menu-trigger {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5rem;
  min-width: 17px;
  cursor: pointer;
  transition: all 0.2s ease;

  .hide-side & {
    top: 0;
  }

  &:hover {
    color: #000;
  }
}

.customer-logo-wrapper {
  margin-top: 5px;
  text-align: left;
  order: 1;
  width: 100%;
  overflow: hidden;

  .customer-logo-container {
    width: 120px;
    overflow: hidden;
    max-height: 38px;
    display: flex;
    height: 38px;
  }

  img {
    display: block;
    object-fit: contain;
    height: 100%;
    max-height: 38px;

    .hide-side & {
      width: 100%;
    }
  }

  ~ .customer-name ul {
    margin-bottom: 0;
  }

  ~ .btn-menu-trigger {
    position: absolute;
    // top: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }

  .hide-side & {
    text-align: center;
  }
}

.help-menu-item {
  position: relative;
  padding-bottom: 10px;

  &:after {
    position: absolute;
    content: "";
    bottom: -10px;
    left: -25px;
    width: calc(100% + 40px);
    border-bottom: 2px solid #747474;
    opacity: 0.2;
  }
}

/* toggle aside */

.hide-side {
  .left-side {
    width: 5rem;
  }

  .logo-wrapper {
    background: #e3bb31;
    width: 100%;

    .logo {
      img.logo-small {
        width: 100%;
        max-height: 80%;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .menu-trigger-wrap {
    text-align: center;
    justify-content: center;
    padding: 1.1rem 1rem;
    margin-bottom: 0;
    align-items: center;
  }

  .customer-logo-wrapper {
    margin-top: 10px;

    .customer-logo-container {
      width: 100%;
    }

    ~ .btn-menu-trigger {
      top: 0;
      position: relative;
      right: auto;
      transform: none;
    }
  }

  .menu {
    padding-right: 0.8rem;
    text-align: center;
    padding-left: 1.1rem;
    padding-top: 1.1rem;

    &.whats-new-menu-popover {
      padding: 1.8rem 1.1rem 0 1.1rem;
    }
  }

  .menu-name:not(.menu-item) {
    display: none;
  }

  .menu-icon {
    float: none;
    display: inline-block;
    margin-right: 0;
  }

  .menu-count {
    position: absolute;
    right: 0;
    top: -5px;
    margin-left: 0;
    line-height: 1.2rem;
    height: 1.2rem;
    padding: 0 0.2rem;
    min-width: 1.2rem;
  }

  .menu-dropdown {
    position: absolute;
    right: -10px;
    top: 0;
  }

  .logo-main {
    display: none;
  }

  .logo-small {
    display: block;
  }

  .menu-lvl-1 > li {
    margin-bottom: 2rem;

    &.menu-item-has-children {
      margin: 0;

      > .menu-item {
        display: none;
      }

      .menu-lvl-2 {
        display: block;
      }
    }
  }

  .menu-lvl-2 {
    padding-left: 0;

    .menu-icon {
      img {
        display: block;

        &:nth-child(1) {
          display: none;
        }
      }
    }

    li {
      margin-bottom: 2rem;
    }
  }

  .app-main {
    padding-left: 6rem;
  }

  .left-user-link {
    padding: 0 2rem 0 2rem;
    bottom: 0;
    height: 100px;

    .user-link {
      display: none;
    }

    .user-dropdown {
      display: block !important;
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      background: none;
      padding: 0;
      bottom: 0;

      span {
        display: none;
      }

      a {
        margin: 0.75rem 0;
      }

      a + a {
        margin-top: 2rem;
      }

      i {
        color: #e3bb31;
        font-size: 1rem;
      }

      .dark & {
        background-color: transparent !important;
      }
    }
  }

  .customer-name {
    display: none;
  }

  .help-menu-item {
    &::after {
      bottom: 20px;
    }
  }
}

.menu-trigger-wrap.with-logo {
  flex-wrap: wrap;
}
