.btn-group-sm {
  > .btn.btn-icon {
    margin-left: 0.25rem;
    height: 1.75rem;
    width: 1.75rem;
    color: #b0b1b0;
  }

  > .btn {
    border-radius: 4px;
  }

  .frequency-types-link {
    margin-right: 0;
  }
}

.btn-fa-icon {
  i {
    padding-right: 0 !important;
  }
}

// ai button

.v2-btn-size {
  font-weight: 500;
  font-size: 1.075rem !important;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #449aff;
}

.snooze-button {
  i {
    font-size: 18px !important;
    color: #7f7f7f;
  }

  &:hover {
    img {
      color: #000;
      opacity: 0.75;
    }
  }
}

// dropzone btn add image

.add-image-block {
  position: relative;
  box-sizing: border-box;
  border: 2px solid #ccc;

  .btn-add-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: #31aee3;

    i {
      color: #fff;
    }
  }
}

.btn-primary-out {
  background: inherit;
  border: 1px solid #449aff !important;
  color: #449aff;

  &:hover {
    background: inherit;
    border: 1px solid #449aff !important;
    color: #449aff;
  }
}

.btn-secondary__custom {
  background: #9e9e9e;
  border-radius: 4px;
  color: white;
}

.btn-primary__custom {
  background: #31aee3;
  border-radius: 4px;
  color: white;
}

.info-plate-button {
  border: 1px solid #c4c4c4;
  height: 32px;
  font-size: 17px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  color: #449aff;
  border-radius: 4px;
  background: #f3f3f3;
}

button.link {
  background: none;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  line-height: 20px;

  i {
    margin-right: 3px;
  }

  span {
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

a.link {
  background: none;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  line-height: 20px;

  i {
    margin-right: 3px;
  }

  span {
    text-decoration: underline;
  }
}

.btn-group {
  button.link {
    &:first-child {
      margin-left: 0;
    }
  }

  a.link {
    &:first-child {
      margin-left: 0;
    }
  }
}

.v2-btn {
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  padding: 12px 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 5px;
    max-height: 14px;
  }
}

.v2-btn.blue-border {
  background: transparent;
  border: 1px solid #449aff;
  color: #449aff;

  &:hover {
    background: #ecf5ff;
    color: #449aff;
  }
}

.v2-btn.grey-border {
  background: transparent;
  border: 1px solid #a4a4ad;
  color: #a4a4ad;

  &:hover {
    border-color: transparentize($color: #a4a4ad, $amount: 0.2);
    color: transparentize($color: #a4a4ad, $amount: 0.2);
  }
}

.v2-btn.blue {
  background: #449aff;
  color: white;

  &:hover {
    background: #59a5ff;
  }
}

.right-dropdown-btn-notes {
  i {
    color: #31aee3;
  }

  position: absolute;
  top: 0.4em;
  right: 0;
}

a.intervals-settings {
  &:not([href]) {
    &:not([tabindex]) {
      color: #31aee3;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.add-installation-point-btn {
  margin-left: 10px;
  color: rgb(68, 154, 255);
  font-size: 1rem;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  letter-spacing: 0;
  text-decoration: underline;
  text-transform: initial;
  font-weight: normal;

  i {
    font-size: 1rem;
    margin: 0 5px;
  }
}

.btn {
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 1.075rem;

  &:hover {
    color: #fff;
  }

  &.btn-icon {
    i {
      pointer-events: none;
    }

    &.btn-sm {
      margin-left: 0.25rem;
      height: 1.75rem;
      width: 1.75rem;
    }

    &.edit-block-btn {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.btn-custom {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 4px;
  }

  &.btn-default {
    background: #f0f0f0;
    color: #505050;
    border: none;

    &:hover {
      background: #ddd;
      color: #505050;
      border: none;
    }
  }

  &.btn-info {
    background: #449aff;
    color: #fff;

    &:hover {
      background: #3280dd;
      color: #fff;
    }

    &:active {
      background: #71b2ff;
      color: #fff;
    }
  }

  &-sm {
    padding: 0.575rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  + .btn-remove {
    margin-right: 10px;
  }
}

.date-container {
  white-space: nowrap;
}
