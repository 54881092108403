.custom-preview {
    width: 20%;
    flex: none;
}

.dz-details {
    text-align: center;
}

.drobzone-action-btns {
    text-align: center;
    margin-top: 5px;
    .btn {
        margin: 0 5px;
    }
}

.dz-preview.dz-image-preview.dz-processing.dz-success.dz-complete.custom-preview {
    pointer-events: visible;
    cursor: default;
}

.dropzone {
    .dz-preview.dz-image-preview {
        .btn-add-image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 3.8rem;
            height: 3.8rem;
            .fa-plus {
                font-size: 2.2rem;
            }
            &:focus {
                color: #fff;
                border-color: inherit !important;
                background-color: #9e9e9e !important;
            }
        }
    }
}

.dropzone-thumb-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    justify-content: space-evenly;
}
.dropzone-thumb-item {
    width: 30%;
    height: 150px;
    position: relative;
    margin-bottom: 50px;
    &:hover {
        .dropzone-thumb-remove-wrapper {
            display: block;
        }
    }
    .btn {
        width: 100%;
    }
}
.dropzone-thumb-remove-wrapper {
    display: none;
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    opacity: 0.5;
}
.dropzone-thumb-remove {
    cursor: pointer;
    font-size: 24px;
    color: #c4c4c4;
    position: absolute;
    right: 5px;
    top: 5px;
}
.dropzone-thumb-undo {
    cursor: pointer;
    font-size: 24px;
    color: #c4c4c4;
    position: absolute;
    left: 5px;
    bottom: 0;
}
.dropzone-thumb-redo {
    cursor: pointer;
    font-size: 24px;
    color: #c4c4c4;
    position: absolute;
    right: 5px;
    bottom: 0;
}
.dropzone-thumb-img-wrapper {
    height: 100%;
    overflow: hidden;
}
.dropzone-thumb-img {
    height: 100%;
}

.img-container {
    .dropzone.dropzone-primary.dropzone-custom {
        border: 1px solid #eaeaea;
    }
}

.dropzone-icon {
    color: #449aff;
    font-size: 40px;
    display: block;
}
.note-file {
    align-items: center;
    .dropzone-icon {
        font-size: 20px;
        cursor: pointer;
    }
}
.note-file-button {
    &:focus {
        outline: none;
    }
    margin-right: 5px;
}
.note-file-button.download {
    .dropzone-icon {
        cursor: inherit;
        color: #82c91e;
    }
}
.note-file-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ea3c3c;
}
.note-file-remove {
    margin-left: 5px;
    cursor: pointer;
}

.assign-photo-main-wrapper {
    text-align: center;
    width: 100%;
    position: relative;
}
.assign-photo-main-block {
    .assign-photo-item {
        width: 100%;
        height: 600px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
    img {
        display: block;
        max-width: 600px;
        max-height: 600px;
    }
}
.assign-photo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.assign-photo-list-wrapper {
    .active-image {
        border: 2px solid #449aff;
        box-sizing: border-box;
    }
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
    max-height: 180px;
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
    }
    .assign-photo-list-item {
        width: 64px;
        height: 64px;
        display: inline-block;
        position: relative;
        margin-right: 6px;
        cursor: pointer;
        overflow: hidden;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            max-width: 150%;
            max-height: 150%;
        }
        span {
            position: absolute;
            color: #fff;
            top: 2px;
            right: 2px;
            height: 17px;
            width: 17px;
            background: #449aff;
            border-radius: 3px;
            text-align: center;
            line-height: 17px;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        }
    }
    .btn {
        padding: 0;
        height: 32px;
        width: 120px;
        font-size: 14px;
        line-height: 16px;
        background-color: #449aff;
    }
    .custom-popover {
        z-index: 10000;
    }
}

[data-rmiz-wrap="visible"], [data-rmiz-wrap="hidden"] {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
}