.reporting-item {
    min-width: 180px;
    padding: 0 10px;
    font-size: 11px;

    @media screen and (max-width: 1024px) {
        .reporting-item {
            min-width: 0;
        }
    }
}
.scan-channel-btn {
    margin-bottom: 10px;
}