.help-page {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000000;
    background: white;
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding: 2rem 2.2rem 1.4rem;

    .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.1em;
        color: #4b4b4b;
        text-transform: uppercase;
        padding: 0 0 1.4rem;
    }
}