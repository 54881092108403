.k-aside-secondary {
    .k-aside-secondary__nav {
        top: 50px !important;
        top: 5rem !important;
    }
    .k-aside-secondary__content {
        top: 50px !important;
        z-index: 91;
        top: 5rem !important;
    }
    .k-aside-secondary__close {
        top: 1.35rem;
        top: 0.4rem;
        right: 0;
        i {
            color: #31aee3;
        }
    }

    > .aside-content {
        padding-bottom: 70px;
    }

    margin-left: 1rem;
    float: right;
    top: 5rem;
    right: 0;
    width: 19.2rem;
}

.k-aside-secondary__close {
    margin: 0 !important;
}

.aside-content-item {
    display: none;
    .k-checkbox {
        > span {
            &:after {
                border-color: #fff;
            }
        }
    }
}
.aside-content-item.current {
    display: block;
}

.aside-content {
    .k-checkbox {
        display: block;
        > span {
            background: #d9d9d9;
            border-color: #d9d9d9;
        }
    }
    .sensors-list {
        .k-checkbox {
            > span {
                background: none;
            }
        }
    }
}

.center-row {
    width: 100%;
    display: flex;
    justify-items: center;
    img {
        margin-right: 5px;
    }
}

.sortable-list-horizontal {
    padding: 0 !important;
}

.sortable-list-horizontal-item {
    display: block;
    margin-left: -4px;
    list-style: none;
    box-sizing: border-box;
    padding: 2px 16px 2px 5px;
    transition: border 0.2s ease;
    position: relative;
    border: 2px dashed transparent;

    label {
        margin-bottom: 0 !important;
        display: block;

        span {
            cursor: pointer;
        }
    }
    .sort-icon {
        display: none;
    }
    &:hover {
        border: 2px dashed #8e95a0;

        .sort-icon {
            display: block;
            position: absolute;
            top: 4px;
            right: 10px;
            color: #9e9e9e;
        }
    }

    .ip-online-label {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

.sensor-point-type {
    position: absolute;
    left: 0;
    top: 85%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
}