.sensors-filter {
    color: #449aff;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 0.2s ease;
    text-decoration: underline;
    margin-left: 5px;
    &:hover {
        opacity: 0.8;
    }
}

.sensors-list {
    .checkbox-all_sensors {
        font-size: 14px;
        line-height: 16px;
    }
}