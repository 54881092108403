//light theme colors

$theme-colors: (
    "default": #dddddd,
    "primary": #449AFF,
    "secondary": #9e9e9e,
    "success": #4aa261,
    "info": #17a2b8,
    "warning": #ffb822,
    "danger": #d9534f,
    "gray": #636363,
    "gray-dark": #6a6a6a,
    "gray-light": #f3f1ec,
    "white-c": #ffffff,
    "black-c": #000000,
    "yellow-brand": #e3bb31,
    "blue-light": #31aee3,
    "turquoise": #1dc9b7,
    "black-gray": #212529
);

$theme-colors-text: (
    "success": #4aa261,
    "warning": #ffb822,
    "danger": #d9534f,
    "info": #000000
);

$theme-colors-hover: (
    "default": #9e9e9e,
    "primary": #3280dd,
    "secondary": #505050,
    "success": #69c46d,
    "info": #17a2b8,
    "warning": #fbaa00,
    "danger": #f32c1e
);

$theme-colors-bg: (
    "primary": #449AFF,
    "secondary": #9e9e9e,
    "success": #69c46d,
    "info": #17a2b8,
    "warning": #ffb822,
    "danger": #f32c1e,
    "gray-light": #f3f1ec,
    "white": #fff,
    "info-row": #f2f2f2,
    "border-colors": #333333
);

//dark theme colors

$theme-colors-dark: (
    "primary": #949ccc,
    "secondary": #9e9e9e,
    "success": #34a15e,
    "info": #387fad,
    "warning": #ca9801,
    "danger": rgba(248, 80, 80, 0.8),
    "primary-btn": #2765ac,
    "dark-blue": #949ccc,
    "light-gray": #cccccc
);

$theme-colors-dark-hover: (
    "default": #9e9e9e,
    "primary": #3280dd,
    "secondary": #505050,
    "success": #69c46d,
    "info": #17a2b8,
    "warning": #ffb822,
    "danger": #f32c1e,
    "white-hover": #edeef5
);

$theme-colors-dark-bg: (
    "default": #413f38,
    "primary": #2765ac,
    "secondary": #9e9e9e,
    "success": #169b8e,
    "info": #387fad,
    "warning": #ca9801,
    "danger": rgba(248, 80, 80, 0.8),

    "table-color": #505049,
    "white-opacity": rgba(189, 189, 189, 0.3),
    "form-fields": #36342a,
    "border-colors": #333333
);

//other
$theme-colors-lvl: (
    "working": #4aa261,
    "caution": #ffb822,
    "warning": #d9534f
);

$cursors:(
    "pointer": pointer,
    "default": default,
    "move": move,
    "ew-resize": ew-resize
);

$border-color: #e5e5e5;
