.badge {
    color: map-get($theme-colors, white-c);
    font-size: 0.8rem;

    .dark .tachometers & {
        color: map-get($theme-colors, white-c) !important;
    }

    &-disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    &-service {
        padding: 0.25em 0.5em;
        font-weight: 700;
    }

    &-square {
        width: 1.5rem;
        height: 1.5rem;
    }

    &-circle {
        border-radius: 100%;
    }

    &-large {
        border-radius: 1.4rem;
        height: 1.4rem;
        min-width: 2.5rem;
        margin-right: 0.4rem;
        color: #fff;
        font-size: 0.8rem;
        font-weight: normal;
        line-height: 1.4rem;
        padding: 0 0.5rem;

        span {
            display: inline-block;
            vertical-align: middle;
            line-height: 1.2rem;
        }
    }

    &-pill {
        border-radius: 12px;
        padding: 7px 10px;
    }

    &-pill-items {
        min-width: 68px;
        font-weight: 500;
        color: #fff;

        .dark & {
            color: #000;
        }
    }

    &-icon {
        font-size: 70%;
        width: 22px;
        height: 22px;
        padding: 6px;

        i {
            margin-right: 0 !important;
        }
    }

    &-md {
        line-height: 24px;
        width: 24px;
        height: 24px;
        padding: 0;
    }

    //-lg for dot
    &-dot {
        border-radius: 50%;
    }
    &-lg {
        line-height: 8px;
        min-height: 8px;
        min-width: 8px;
        height: 8px;
        width: 8px;
        padding: 0;
    }
}
