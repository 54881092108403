.block {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    margin-bottom: 30px;
    border-radius: 4px;

    &:last-child {
        margin-bottom: 0;
    }

    .dark & {
        box-shadow: 0 0 13px 0 rgba(106, 99, 114, 0.05);
        background-color: #36342a !important;
    }

    &-title {
        align-items: baseline;
        color: #464457;

        small {
            font-weight: 300;
            padding-left: 0.5rem;
            font-size: 0.9rem;
            color: #5b5871;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        padding: 25px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        position: relative;

        @media (max-width: 768px) {
            padding: 25px 10px;
        }

        &--no-paddings {
            padding: 0;
        }
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 25px;
        border-bottom: 1px solid #eeeef4;
        min-height: 60px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        @media (max-width: 768px) {
            padding: 0 10px;
        }

        &-sticky {
            position: sticky;
            top: 64px;
            background: #ffffff;
            z-index: 2;
            padding-top: 10px;
            padding-bottom: 15px;

            .dark & {
                background: #35342a;
            }
        }

        &--set-defaults {
            @media (max-width: 768px) {
                flex-wrap: wrap;
                padding-top: 10px;
            }

            .block-label {
                + .block-toolbar {
                    @media (max-width: 768px) {
                        margin-left: 0;
                        margin-top: 10px;
                        padding-bottom: 10px;
                        width: 100%;
                    }

                    .block-toolbar-wrapper {
                        @media (max-width: 768px) {
                            width: 100%;
                        }
                    }
                }
            }

        }

        .dark & {
            border-bottom-color: hsla(0, 0%, 39.2%, .9);
        }
    }

    &-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        + .block-toolbar {
            margin-left: 15px;
        }
    }

    &-pagination {
        display: flex;
        margin: 0 15px;
        white-space: nowrap;
        align-items: center;

        > div {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-toolbar {
        display: flex;
        align-items: center;
        align-content: flex-end;
    }
}

.sub-title {
    &--blue {
        span {
            color: map-get($theme-colors, primary) !important;
            font-weight: 700 !important;
        }
    }
}