.subheader {
    $this: &;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: .4rem;
    position: relative;
    margin-bottom: 1.5rem;

    &::after {
        position: absolute;
        content: "";
        width: 100%;
        top: 50%;
        height: 1px;
        background: #c4c4c4;
    }

    &--no-after {
        &::after {
            display: none;
        }

        #{$this}-title {
            color: #aaa;
        }
    }

    &-title {
        position: relative;
        z-index: 2;
        float: left;
        padding-right: 1.5rem;
        color: #676767;
        font-weight: 700;
        text-transform: uppercase;
        font-size: .9rem;
        line-height: 3.1rem;
        letter-spacing: 2px;
        background: #f3f3f3;

        &--axis {
            background: #fff;

            .dark & {
                background-color: map-get($theme-colors-dark-bg, default) !important;
            }
        }
    }

    &-controls {
        position: relative;
        z-index: 1;
        float: right;
        padding-left: 1.5rem;
        line-height: 3.1rem;
        background: #f3f3f3;
    }
}
