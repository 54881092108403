//colors
@each $color, $value in $theme-colors {
    .color-#{$color} {
        color: $value;
    }
}

//bg
@each $bg-color, $value in $theme-colors-bg {
    .bg-#{$bg-color} {
        background-color: $value;
    }
}

//bg dark
@each $bg-color, $value in $theme-colors-dark-bg {
    .bg-#{$bg-color} {
        .dark & {
            background-color: $value;
            color: map-get($theme-colors, white-c);
        }
    }
}

//btn
@each $btn-color, $value in $theme-colors-bg {
    .btn-#{$btn-color} {
        background-color: $value;

        &:hover {
            background-color: lighten($value, 10%);
        }

        &:active, &:focus {
            background-color: darken($value, 10%);
        }
    }
}

//colors
@each $color, $value in $theme-colors {
    .link-#{$color} {
        color: $value;

        &:hover {
            color: lighten($value, 10%);
        }
    }
}

//btn dark
@each $btn-color, $value in $theme-colors-dark-bg {
    .btn-#{$btn-color} {
        .dark & {
            background-color: $value;
        }
    }
}

//text colors
@each $text-color, $value in $theme-colors-text {
    .text-#{$text-color} {
        color: $value;
    }
}

//color-lvl
@each $color-lvl, $value in $theme-colors-lvl {
    .color-level-#{$color-lvl} {
        color: $value;
    }
}

//cursors
@each $cursor, $value in $cursors {
    .cursor-#{$cursor} {
        cursor: $value;
    }
}

.default-cursor {
    &:hover {
        cursor: default;
    }
}

//badge
@each $badge, $value in $theme-colors-bg {
    .badge-#{$badge} {
        background-color: $value;
    }
}

//badge dark
@each $badge, $value in $theme-colors-dark-bg {
    .badge-#{$badge} {
        .dark & {
            background-color: $value;
        }
    }
}

//float clear
.clearfix {
    &:after {
        content: '';
        display: block;
        clear: both;
        height: 0;
    }
}

//table helpers
.bg-gray-block {
    background-color: #e5e5e5;
    position: relative;
}

.tr-gray {
    background: #f6f6f6;

    .dark & {
        background: #22211b;
    }
}

//text styles utilities
.text-underline {
    text-decoration: underline;
}

//floats
.pull {
    &-right-label {
        float: right;
        text-align: right;
    }

    &-left-label {
        float: left;
        text-align: left;
    }
}

//types
.disabled {
    cursor: not-allowed !important;
    opacity: 0.8;
    >span {
        cursor: not-allowed !important;
        opacity: 0.8;
        color: #9e9e9e !important;
    }
}

//text-ellipsis
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-padding {
    padding: 0 !important;
}

.pt-60 {
    padding-top: 60% !important;
}

.pr-30 {
    padding-right: 30px !important;

    @media (min-width: 1780px) {
        padding-right: 35px !important;
    }
}

.mh-250 {
    min-height: 250px;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    &::before {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
        margin-right: .25em;
    }
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
        margin-left: .25em;
    }
}

.alert-link {
    .switch {
        pointer-events: none;
    }
}

.in-blue-circle {
    background-color: #31aee3;
    border-radius: 50%;
    padding: 5px;
    font-size: 8px;
    color: #fff;
}

.switch {
    position: relative;
    display: inline-block;
    width: 2.2rem;
    height: 1.2rem;
    margin: 0;
    vertical-align: middle;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: .4s;
    border: 1px solid #2196f3;

    &:before {
        position: absolute;
        content: "";
        height: 0.8rem;
        width: 0.8rem;
        left: 0.12rem;
        bottom: 0.12rem;
        transition: .4s;
    }

    &.round {
        border-radius: 34px;
        &:before {
            border-radius: 50%;
        }
    }
}

.units-sub {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.alarm-line {
    .custom-popover {
        z-index: 1060;
    }
}

.custom-popover {
    min-width: 280px;
    z-index: 5;
}

.emails-popover {
    display: inline-block;
    min-width: 45px;
    +  {
        div {
            > .fade.show {
                > .custom-popover {
                    min-width: 335px !important;
                }
            }
        }
    }
}

.triggered-popover {
    min-width: 280px;
    z-index: 5;
}

.active-bearing-input {
    width: 75px;
}

.sticky-custom-modal-header {
    position: sticky;
    top: -15px;
    background-color: #fff !important;
    z-index: 99;
}

.sticky-wrapper {
    position: relative;
}

.fa-rotate-315 {
    transform: rotate(315deg);
}

.table-image-icon {
    width: 20px;
}

.table-image-icon.mail-icon {
    margin-right: 3px;
    svg {
        width: 20px;
        height: 20px;
    }
}

.controll-arrow {
    display: block;
    width: 20px;
    height: 20px;
    border-top: 1px solid black;
}

.arrow-prev {
    border-left: 1px solid black;
    transform: rotate(-45deg);
}

.arrow-next {
    border-right: 1px solid black;
    transform: rotate(45deg);
}

.border-left-md-warning {
    border-left: 3px solid #e3bb31;
}

.dashed-board {
    border: 2px dashed #dddddd;
    padding-left: 20px !important;
}

.envelope-disabled {
    opacity: 0.5;
    label {
        cursor: no-drop;
    }
}

.flex-box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
}

.block-visibility {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 1rem;
    margin: 0;

    +  {
        .block-visibility {
            margin-left: 0.8rem;
        }
    }
}

.small-error-msg {
    font-size: 10px;
}

.pe-none {
    pointer-events: none;
}