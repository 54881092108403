.dark {
    .point-info {
        background: #38382e !important;
    }
}

.inst-point-table {
    .point-info {
        background: #FAFAFA;
        border: 1px solid rgba(208, 214, 229, 0.3);
        border-radius: 5px;
    }

    .action-btn {
        width: 14px;
        cursor: pointer;
    }

    .form-control {
        height: 40px;
    }

    .react-select__control {
        height: 40px !important;
    }

    .form-control-select {
        > div {
            height: 40px !important;
        }
    }

    label {
        display: none;
    }

    .wua-info {
        label {
            display: block;
            text-align: left !important;
        }
    }

    .alert-link {
        right: 5px !important;
        top: 10px !important;
        cursor: pointer;

        > span {
            display: none;
        }

        label {
            display: inline-block;
        }
    }

    .invalid-feedback {
        top: 40px;
        z-index: 200;
    }
}

.inst-points-form {
    .axis-block {
        min-width: 170px;
    }
}

.adapter-parameters-modal {
    .modal-body {
        min-height: 215px;
    }
}

.edit-installation-point-modal {
    &.modal-lg {
        max-width: 900px;
    }
}

.switch-block {
    font-size: large;
}
