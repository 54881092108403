.search-field {
    position: relative;
    padding: 0;
    width: 100%;

    &-input {
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        padding-left: 15px !important;
        padding-right: 45px !important;
    }

    &-submit {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);

        i {
            display: block;
            color: #747474;
            font-size: 1rem;

            .block-toolbar-wrapper & {
                font-size: 1.4rem;
            }
        }
    }

    &-clear {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        .block-toolbar-wrapper & {
            right: 30px;
        }

        i {
            display: block;
            color: #b3b1c2;
            font-size: 1rem;

            .block-toolbar-wrapper & {
                font-size: 1.4rem;
            }
        }
    }

    .block-toolbar-wrapper & {
        min-width: 135px;
    }
}

.custom-search-block {
    position: relative;
    > i {
        position: absolute;
        left: 5px;
        top: 50%;
        color: #31aee3;
        transform: translateY(-50%);
    }
    > input {
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        padding-left: 30px;
    }
    i {
        font-size: 1.2rem;
    }
}