.installation-point-block {
    width: 24%;
    padding: 10px;
    border-radius: 4px;
    border-left: 5px solid;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    margin: 5px;
}

.inst-point-title {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #6a6a6a;
    font-weight: bold;
}