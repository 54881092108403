.ai-setting-block {
    border: 1px solid #eeeef4;
    border-radius: 6px;
    padding: 10px;
}

.facility-option {
    padding: 5px;
    cursor: pointer;
    &:hover {
        background: #eeeef4;
    }
}

.facility-option.active {
    background: #449aff;
}