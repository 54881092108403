.user-profile {
    &-content {
        padding: 25px;
        border-bottom: 1px solid #ebedf2;
    }

    &-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 1px solid #ebedf2;
    }

    &-img {
        position: relative;
        width: 10rem;
        height: 10rem;
        margin-right: 2rem;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0px 0px 20px 0px rgba(103, 92, 139, 0.05);
        }
    }

    &-description {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    &-name {
        font-size: 1.5rem;
        font-weight: bold;
    }

    &-contacts {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding-left: 2rem;

        &-item {
            display: inline-flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                font-size: 1.5rem;
            }
        }

        &-icon {
            margin-right: 1.5rem;
        }
    }

    &-nav {
        padding: 0 25px;
    }

    &-form {
        .form-label {
            font-size: 13px;
            font-weight: 400;
            text-align: right;
            padding-top: 0.8rem;
        }
    }

    &-label {
        min-width: 90px;
        align-items: center;
        text-align: left;
        padding-top: 0.5rem;
    }

    &-avatar {
        position: relative;
        display: inline-block;
    }

    &-holder {
        border: 3px solid #ffffff;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
    }

    &-upload {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        top: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;

        &:hover {
            background-color: #5c4de5;

            i {
                color: #fff;
            }
        }

        i {
            color: #5c4de5;
            font-size: 0.9rem;
        }

        input {
            width: 0 !important;
            height: 0 !important;
            overflow: hidden;
            opacity: 0;
        }
    }

    &-logo {
        position: relative;
        display: inline-block;
    }

    &-holder-logo {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        border: 3px solid #ffffff;
        width: 200px;
        height: 110px;
        background-size: 100px;
        background-repeat: no-repeat;
        background-color: #e7e8e9;
        background-position: center center;
    }

    &-remove-logo {
        cursor: pointer;

        &:hover {
            i {
                color: #5c4de5 !important;
            }
        }
    }

    &-upload-logo {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -12px;
        top: -12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;

        i {
            color: #5c4de5 !important;
        }

        &:hover {
            background-color: #5c4de5;
            color: #fff;

            i {
                color: #fff !important;
            }
        }

        input {
            width: 0 !important;
            height: 0 !important;
            overflow: hidden;
            opacity: 0;
        }
    }
}

.wrapper-logo {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 130px;
    padding-left: 2.5rem;
    border-left: 1px solid #ebedf2;
}

.user-profile-form {
    .form-radio {
        font-size: 1rem;
        font-weight: normal;
    }
}
