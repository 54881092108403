.axis-block-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: map-get($theme-colors, secondary);

    &--network {
        width: 100%;
        font-weight: 500;
        color: map-get($theme-colors, black-gray);
    }
}

.axis-block {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3px;
    border-bottom: 1px solid map-get($theme-colors, secondary);

    &.active {
        color: map-get($theme-colors, primary);
        border-bottom: 3px solid map-get($theme-colors, primary);
    }
}

.axis-dropdown-item {
    cursor: default;
    font-size: 1rem !important;
    line-height: 2rem;

    .fa {
        cursor: pointer;
        font-size: 1rem !important;
        line-height: 2rem;
    }

    i {
        padding-right: 8px;
        border-right: 2px solid rgba(235, 237, 242, 0.89) !important;
    }

    span.label {
        cursor: pointer;
        padding: 3px 5px;
        margin: 2px 0;
        color: #fff;
        text-transform: uppercase;
        line-height: 1rem;
    }
}

.axis-block-count {
    float: right;
    margin-left: 0.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
    text-align: center;
    border-radius: 1.2rem;
    padding: 0 0.4rem;
    line-height: 1.2rem;

    span {
        display: inline-block;
        vertical-align: middle;
        font-size: 0.7rem;
        font-weight: bold;
    }
}
