.custom-card{
    padding: 15px;
}

.ellipsis-text {
    width: 100%;
    -ms-text-overflow: ellipsis !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.ellipsis-text mark {
    padding: 0;
}