.sliderWrapper.modalSlider {
    height: calc(100vh - 240px);
    .modalSliderContainer {
        height: 100%;
    }
    .sliderPrevBtn {
        display: flex;
        align-items: center;
        padding: 0 7px;
    }
    .sliderNextBtn {
        display: flex;
        align-items: center;
        padding: 0 7px;
    }
    .previews-photos {
        display: block;
        height: 100%;
        margin: 0 auto;
    }
}