.user-icon {
    position: relative;
    height: 38px;
    width: 38px;
    text-align: center;
    line-height: 38px;
    display: flex;
    align-self: center;
    border-radius: 38px;
    overflow: hidden;
    img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        margin-left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}