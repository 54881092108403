.aside-bottom-settings {
    position: fixed;
    bottom: 0;
    display: flex;
    background: white;
    right: 0;
    z-index: 100000;
    padding: 2px 10px 0;
    align-items: center;
    font-size: 0.9rem;
    color: #4b4b4b;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

    .circle-container {
        width: 60px;
        position: relative;

        .circle {
            color: white;
            position: absolute;
            background: #0d8aee;
            width: 60px;
            height: 60px;
            border-radius: 60px;
            align-items: center;
            top: -33px;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
    }

    .date-picker-container {
        width: auto;
        margin-left: 10px;
        padding-right: 5px;
    }

    .date-picker {
        margin-bottom: 0;
    }

    .separator {
        border-left: 1px solid #9e9e9e;
        margin: 0 5px;
    }

    .setting-label {
        color: #9e9e9e;
    }

    .sortable-list-horizontal {
        align-items: center;
    }

    .sortable-list-horizontal-item {
        padding: 2px 8px 2px 2px;
    }

    .form-checkbox {
        padding-left: 22px;
        line-height: 16px;
    }
}