.timeline {
    $this: &;

    &-item {
        position: relative;
        margin-bottom: 1rem;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 1.25rem;
            background: #b1b1b1;
            width: 1px;
            height: calc(100% - 6px);
        }
        &:last-child {
            #{$this}__info {
                padding-bottom: 0;
            }
        }
        &__section {
            display: flex;
            align-items: center;
        }
        &__datetime {
            font-size: 0.9rem;
            line-height: 1.1rem;
            color: #9e9e9e;
            margin-left: 1.8rem;

            &--line {
            }
        }
        &__text {
            font-weight: 500;
            font-size: 1.1rem;
            line-height: 1.3rem;
            color: #4b4b4b;
            position: relative;
            margin-top: 1rem;
            margin-bottom: 1rem;
            text-decoration: none;
            margin-left: 4.4rem;
            display: block;

            .dark & {
                color: #aaa6c5;
            }

            &:after {
                position: absolute;
                left: 1.1rem;
                top: 50%;
                margin-top: -2px;
                height: 5px;
                width: 5px;
                border-radius: 5px;
                background: #c4c4c4;
                content: '';
                left: -3.3rem;
            }
        }
        &__info {
            position: relative;
            padding: 0 0 0 4.4rem;

            &:after {
                content: '';
                position: absolute;
                left: 1.1rem;
                top: 50%;
                margin-top: -2px;
                height: 5px;
                width: 5px;
                border-radius: 5px;
                background: #c4c4c4;
            }
        }
        &__border {
            background-color: #fff;
            border-top: 2px;
            border-bottom: 2px;
            position: relative;

            .dark & {
                background: #413f38;
            }
        }
        &__icon {
            height: 2.5rem;
            width: 2.5rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        &--brand {
            #{$this}-item__text {
                font-weight: 500;
                margin-top: 5px;
                color: #5d5b6f;
                font-size: 1rem;

                .dark & {
                    color: #aaa6c5;
                }
            }

            #{$this}-item__icon {
                background: none;
                color: #b1b1b1;

                i {
                    font-size: 1.7rem;
                }
            }
        }
        &--custom {
            #{$this}-item__text {
                font-weight: 400;
                margin-top: 5px;
                color: #5d5b6f;
                font-size: 1rem;
            }

            #{$this}-item__icon {
                height: 2.5rem;
                width: 2.5rem;

                i {
                    font-size: 1.7rem;
                }
            }
        }
    }
}

.k-aside-secondary__content-body {
    .text-center {
        font-size: 1.1rem;
        line-height: 1.2rem;
        color: #868686;
        font-weight: normal;
        img {
            display: block;
            margin: 0 auto 0.5rem;
        }
    }
    .form-group {
        margin-bottom: 1rem;
    }
    label {
        &:not(.k-checkbox) {
            font-size: 0.9rem;
            line-height: 1.1rem;
            font-weight: bold;
            color: #4b4b4b;
            margin-bottom: 0.4rem;
            padding: 0;
        }
    }
    input {
        background: #fff;
        border: 1px solid #c4c4c4 !important;
        border-radius: 4px;
    }
    select {
        background: #fff;
        border: 1px solid #c4c4c4 !important;
        border-radius: 4px;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        background-image: url('../../../pic/icon-arrow-down.svg') !important;
        background-repeat: no-repeat !important;
        background-position: 93% center !important;
        background-color: #fff;
    }

    button.dropdown-toggle {
        background: #fff;
        border: 1px solid #c4c4c4 !important;
        border-radius: 4px;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        background-image: url('../../../pic/icon-arrow-down.svg') !important;
        background-repeat: no-repeat !important;
        background-position: 93% center !important;
        background-color: #fff;
        .bs-caret {
            display: none;
        }
    }
    .form-control-select {
        input {
            border: none !important;
            border: 0px !important;
        }
    }
    .bootstrap-select {
        > .dropdown-toggle {
            &:after {
                display: none;
            }
        }
    }
}

.k-aside-secondary__content-head {
    position: relative;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #4b4b4b;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .btn.btn-icon.edit-block-btn {
        display: block;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 1.5rem;
        background: #31aee3;
        i {
            color: #fff;
            font-size: 0.6rem;
        }
    }
    .btn.btn-danger.btn-icon.edit-block-btn {
        color: #fff;
        background-color: rgb(217, 83, 79);
        border-color: rgb(217, 83, 79);
    }
    .btn.btn-warning.btn-icon.edit-block-btn {
        color: #fff;
        background-color: rgb(227, 187, 49);
        border-color: rgb(227, 187, 49);
    }
    button {
        height: 1.6rem;
        width: 1.6rem;
        color: #fff;
        background: #31aee3;
        padding: 0;
        float: right;
        text-align: center;
    }
    .k-aside-secondary__title {
        display: inline-block;
        button {
            float: none;
            background: none;
            color: #b0b1b0;
        }
    }
    button.btn.btn-sm {
        i {
            padding-right: 0;
            font-size: 0.9rem;
        }
    }
}

.request-row {
    padding: 5px 0;
    border-bottom: 1px solid #ececec;
    transition: all .2s ease;

    &:hover {
        background: #f6f6f6;
    }
}

.demand {
    &-title {
        position: relative;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    &-history {
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: .1em;
        color: #4b4b4b;
        text-transform: uppercase;
        padding: 1rem 0;

        .dark & {
            color: map-get($theme-colors-dark, light-gray);
        }
    }
    &-body {
        .alert-text {
            font-size: 1.1rem;
            line-height: 1.2rem;
            color: #868686;
            font-weight: 400;
        }
    }
    &-scroll {
        max-height: 270px;
        height: auto !important;

        &.history {
            max-height: 180px;
        }
    }
}
