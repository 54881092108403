.interval-block {
    padding: 10px 5px;
    border-top: 1px solid #d9d9d9;
    position: relative;

    .sensor-id-title {
        opacity: 0.6;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.2;
    }

    .other-inst-points {
        padding: 10px;
        background: #e3bb31;
    }

    .sensor-id-title {
        font-weight: bold;
        color: inherit !important;
    }

    .sensor-id-title {
        opacity: 1;
        display: inline-block;
    }
}

.installation-point-settings-list {
    .card {
        margin-bottom: 10px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
    }

    margin-top: 10px;

    .card-title {
        span {
            font-size: 14px;
            color: #4b4b4b;
        }

        a {
            cursor: pointer;

            i.fa {
                color: #9e9e9e;
            }
        }
    }
}

.point-settings-form-container {
    .settings-title {
        font-style: normal;
        font-size: 14px;
        color: #9e9e9e;
        margin-bottom: 7px;
        padding-left: 5px;
    }

    .form-group {
        label {
            color: #4b4b4b;
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
            letter-spacing: 0.02rem;
            padding-left: 5px;
        }

        input {
            background-color: #ffffff;
            box-sizing: border-box;
            border-radius: 4px;
            color: #4b4b4b;
            font-size: 12px;
        }

        select {
            background-color: #ffffff;
            box-sizing: border-box;
            border-radius: 4px;
            color: #4b4b4b;
            font-size: 12px;
            line-height: 14px;
            // padding: 0.3rem 0.7rem;
            height: 32px;
        }

        .form-control {
            &:disabled {
                background: #dedede;
            }
        }

        .form-control[readonly] {
            background: #dedede;
        }
    }

    .axis-divider {
        color: #4b4b4b;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.01rem;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        z-index: 2;
        text-transform: uppercase;

        b {
            padding: 0 1rem;
            background: #fff;
            position: relative;
            z-index: 2;
        }

        &:after {
            position: absolute;
            content: "";
            width: 100%;
            top: 50%;
            left: 0;
            height: 1px;
            background-color: #d9d9d9;
            z-index: 1;
        }
    }
}

.settings-preview-list {
    .settings-preview-item {
        margin-top: 3px;
        margin-bottom: 3px;

        .title {
            font-size: 1.05rem;
            margin-bottom: 0.5rem;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.2;
            color: inherit;
            text-transform: uppercase;
        }

        &.horizontal {
            div {
                margin-right: 30px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            .interval-block-settings-actions {
                margin-left: auto;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }

        &.point-preview {
            width: 30%;
        }

        &.intervals-preview {
            width: 20%;
        }
        &.fft-preview {
            width: 35%;
            @media screen and (max-width: 1440px) {
                .title {
                    white-space: normal !important;
                }
            }
        }
        &.no-data-preview {
            width: 80%;
        }
        &.triggers-preview {
            width: 10%;
        }
    }

    .group-title {
        font-size: 14px;
        color: #4b4b4b;
        line-height: 16px;
        font-weight: 500;
        margin-top: 3px;
        margin-bottom: 3px;
    }
}

.interval-content {
    .separator {
        border-left: 1px solid #9e9e9e;
        margin: 0 5px;
    }
}

.interval-page {
    .interval-block-title {
        .settings-preview-list {
            margin: 0 1rem;
        }
    }

    .interval-block {
        padding: 10px;
    }

    .point-name {
        max-width: 240px;
    }
}

.interval-notice {
    &.single-line {
        white-space: normal;
    }

    &.breaking-line {
        white-space: pre-line;
    }
}

.input-range__label--value {
    display: none;
}